$container-width--default: 1440px;
$container-width--xl: 1200px;
$container-width--wide: 100%;
$container-height--wide: 100%;
$container-width--narrow: 780px;

$parallax-height--default: 300px;

$navbar-height--desktop: 80px;
$navbar-height--mobile: 70px;
