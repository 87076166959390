/*=============================================
=            Navbar            =
=============================================*/

.navbar {
    position: relative;
    background-color: $bg--dark-blue;
    color: $text--white;
    padding: 20px 0;
    max-height: $navbar-height--desktop;
    height: $navbar-height--desktop;

    @include media-breakpoint-down(xl) {
        padding: 20px 0;
        background-color: $bg--white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        max-height: $navbar-height--mobile;
        height: $navbar-height--mobile;
        position: fixed;
        top: 0;
        left: 0;
        width: $container-width--wide;
        z-index: 99;
    }

    &__inner {
        margin: 0 auto;
        padding: 0 60px;
        max-width: $container-width--default;
        width: $container-width--wide;

        @include media-breakpoint-down(xl) {
            transform: none;
        }

        @include media-breakpoint-down(lg) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 20px;
        }
    }

    &__logo {
        display: block;
        max-height: 24px;
        width: auto;

        @include media-breakpoint-down(xl) {
            display: none;
        }

        &-mobile {
            display: block;
            max-height: 30px;
            transform: translateY(0);

            @include media-breakpoint-up(xl) {
                display: none;
            }
        }

        &-container {
            min-width: fit-content;
            margin-right: auto;
        }
    }

    &__links {
        gap: 30px;

        @include media-breakpoint-down(desktop) {
            gap: 15px;
        }

        @include media-breakpoint-down(xl) {
            display: none;
        }

        &-item {
            height: $container-height--wide;
            min-width: fit-content;

            &:last-of-type {
                position: relative;
                padding-right: 30px;

                @include media-breakpoint-down(desktop) {
                    padding-right: 15px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: -8px;
                    height: 38px;
                    width: 1px;
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

    &__login {
        font-weight: $font-weight--bold;
        line-height: 133.33%;
    }

    &__sticky-button {
        position: fixed;
        bottom: 30px;
        left: 30px;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}

/*=====  End of Navbar  ======*/
