/*===================================================================
=            Footer            =
=====================================================================*/

.footer {
  background-color: $bg--dark-blue;
  color: $text--white;
  padding: 90px 0;

  @include media-breakpoint-down(sm) {
    padding: 90px 0 120px 0;
  }

  &__inner {
    padding: 0 120px;

    @include media-breakpoint-down(md) {
      padding: 0 30px;
    }
  }

  &__top {
    width: $container-width--wide;
    padding-block-end: 30px;
    margin-block-end: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    gap: 60px;

    @include media-breakpoint-down(sm) {
      gap: 0;
    }
  }

  &__bottom {
    opacity: 0.8;

    @include BodyTextTiny;

    @include media-breakpoint-down(sm) {
      opacity: 1;
    }
  }

  &__left {
    gap: 30px;

    @include media-breakpoint-down(sm) {
      width: $container-width--wide;
      padding-block-end: 30px;
      margin-block-end: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  &__right {
    gap: 60px;
    margin-block-start: 23px;


    @include media-breakpoint-down(sm) {
      width: $container-width--wide;
      flex-direction: column;
      gap: 20px;
      margin-block-start: 0px;
    }
  }

  &__logo {
    width: 173px;
    max-width: 173px;
  }

  &__caption {
    @include HeadingH4Mobile;
  }

  &__column {
    gap: 20px;

    &:nth-child(3){
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &-mobile {
      width: $container-width--wide;

      @include media-breakpoint-up(sm) {
        display: none;
      }

      .footer__item {
        width: fit-content;
      }
    }
  }

  &__item {
    @include media-breakpoint-down(sm) {
      width: $container-width--wide;

      .link {
        width: $container-width--wide;
        justify-content: flex-start;
      }
    }
  }

  &__socials {
    gap: 30px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &-mobile {
      gap: 30px;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  &__social {
    font-size: 20px;
    
    .zmdi {
      height: 20px;
      width: 20px;
    }

    &:hover, &:focus {
      color: $black;
    }
  }

  &__mobile {
    gap: 30px;
    margin-block-end: 30px;
    width: $container-width--wide;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

/*=====  End of Footer  ======*/