// /*=============================================
// =            Upload Document            =
// =============================================*/

.individual-upload {
    display: flex;
    gap: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $bg--white;
    height: 100%;
    width: 100%;
    overflow: visible;

    @include media-breakpoint-down(xl) {
        gap: 60px;
    }

    &__wrapper {
        display: none;
        visibility: hidden;

        &--active {
            display: block;
            visibility: visible;
        }
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__left {
        flex: 0.78;
        gap: 20px;
        padding: 40px 0 80px 80px;
        margin-left: calc((100vw - $container-width--default) / 2);
        max-width: calc(560px + 80px);
        width: calc(100% + 80px);
        height: fit-content;
        min-height: 820px;
        max-width: 640px;

        @include media-breakpoint-up(wide) {
            flex: 1;
        }

        @include media-breakpoint-down(desktop) {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
            padding-right: 80px;
            margin: auto;
            max-width: none;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
        }
    }

    &__right {
        flex: 1;
        position: relative;
        background-color: $bg--light-blue;
        transform-style: preserve-3d;
        perspective: 1000px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__back {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        aspect-ratio: 1/1;
    }

    &__title {
        @include HeadingH2Desktop;

        @include media-breakpoint-down(md) {
            @include HeadingH1Mobile;
        }
    }

    &__text {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;
    }

    &__validations {
        padding: 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        gap: 10px;
        width: 100%;
    }

    &__images {
        gap: 20px;
    }

    &__image {
        border: 1px solid $red;

        &-container {
            position: relative;
            background-color: rgba(0, 0, 0, 0.1);

            .zmdi {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                border-radius: 50%;
                color: $red;

                &::after {
                    content: '';
                    background-color: $bg--white;
                    height: 11px;
                    width: 11px;
                    position: absolute;
                    top: 2.5px;
                    left: 1.5px;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    z-index: $below;
                }
            }

            &:first-child {
                .zmdi {
                    color: $green;
                }

                .individual-upload__image {
                    border-color: $green;
                }
            }
        }
    }

    &__points {
        gap: 10px;
    }

    &__point {
        gap: 5px;

        &-icon {
            height: 20px;
            width: 20px;
        }
    }

    &__item {
        width: 100%;
        gap: 20px;

        &-title {
            @include HeadingH5Mobile;
        }
    }

    &__upload {
        border: 1px dashed $light-blue;
        background-color: rgba(0, 166, 195, 0.05);
        padding: 20px 50px;
        border-radius: 5px;
        width: 100%;
        position: relative;
        min-height: 106.5px;

        @include transition;

        &--active {
            background-color: rgba(0, 166, 195, 0.2);
        }

        &-main {
            gap: 10px;
            display: none;

            &--active {
                display: flex;
            }
        }

        &-buttons {
            gap: 10px;
            width: 100%;

            @include media-breakpoint-up(md) {
                justify-content: center;
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        &-text {
            color: rgba(0, 0, 0, 0.8);

            @include BodyTextSm;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &-info {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextTiny;
        }

        &-camera {
            min-width: 200px;
        }

        &-button {
            position: relative;
            display: inline-block;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        &-label {
            cursor: pointer;
            background-color: $bg--dark-blue;
            color: $text--white;
            border-radius: 20px;
            padding: 10px 15px;
            width: inherit;
            min-width: 200px;
            gap: 10px;

            @include transition;

            @include media-breakpoint-down(xs) {
                min-width: auto;
                width: 100%;
            }
        }

        &-label:hover {
            background-color: $bg--black;
        }

        &-placeholder {
            @include Button;
        }

        &-input:focus + &-label {
            outline: 1px solid $black;
            background-color: $bg--black;
        }

        &-form {
            width: 100%;
            gap: 20px
        }
    }

    &__capture-button {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__button {
        min-width: 200px;
    }

    &__buttons {
        width: 100%;
    }

    input[type='file'] {
        color: transparent;
        position: absolute;
        z-index: -1;
    }

    &__submit {
        width: 100%;

        &:disabled {
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 1;
        }
    }

    &__preview {
        display: none;
        gap: 10px;

        &--active {
            display: flex;
        }

        &-image {
            height: 60px;
            width: 60px;
            min-width: 60px;
            object-fit: cover;
            display: none;

            &--active {
                display: block;
            }
        }

        &-pdf {
            height: 60px;
            width: 60px;
            min-width: 60px;
            display: none;

            &--active {
                display: block;
            }
        }

        &-name,
        &-size {
            @include BodyTextSm;
        }

        &-size {
            color: rgba(0, 0, 0, 0.5);
        }

        &-file {
            width: 100%;
        }

        &-left {
            flex: 1;
            gap: 5px;
            margin-bottom: 5px;
        }

        &-right {
            gap: 20px;
            margin-left: auto;

            .zmdi {
                font-size: 16px;

                @include transition;
            }
        }

        &-delete {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.5);

            &:hover,
            &:focus {
                .zmdi {
                    color: $text--black;
                }
            }
        }
    }

    &__progress {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        height: 4px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &-inner {
            width: 0;
            height: 100%;
            background-color: #3a6df0;
            transition: width 0.3s ease;

            &--complete {
                background-color: $bg--green;
            }
        }
    }

    &__status {
        &-invalid {
            color: rgba(0, 0, 0, 0.5);
            display: none;

            &--active {
                display: inline-block;
            }
        }

        &-valid {
            color: $green;
            display: none;

            &--active {
                display: inline-block;
            }
        }
    }
}

// /*=====  End of Upload Document  ======*/
