.recipient-detail {
    width: 540px;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-block: 40px;
    padding-inline: 40px 80px;
    margin-inline-start: auto;
    background-color: $bg--white;
    gap: 30px;
    transform: translateX(540px);
    overflow-y: auto;

    @include transitionSlow;

    @include media-breakpoint-down(md) {
        gap: 20px;
        padding: 20px 0;
        height: auto;
        width: 100%;
        margin-inline-start: 0;
        margin-block-start: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        transform: translateX(0) translateY(100%);
    }

    .link {
        width: 100%;

        @include media-breakpoint-down(md) {
            width: calc(100% - 40px);
            margin: 0 auto;
        }
    }

    &__overlay {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 9999999999;
        cursor: default;
        visibility: hidden;
        opacity: 0;
        overflow-y: auto;

        @include transitionSlow;

        @include media-breakpoint-down(xl) {
            height: calc(100vh - $navbar-height--mobile);
        }

        &--active {
            visibility: visible;
            opacity: 1;
            animation: backgroundFade 0.2s ease-in-out forwards;

            .recipient-detail {
                transform: translateX(0px);

                @include media-breakpoint-down(md) {
                    transform: translateY(0px);
                }
            }
        }

        @keyframes backgroundFade {
            0% {
                background-color: rgba(0, 0, 0, 0);
            }
            100% {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__inner {
        overflow: hidden;
        width: 100%;
        gap: 30px;

        @include media-breakpoint-up(md) {
            min-height: 770px;
        }
    }

    &__header {
        width: 100%;
        padding-block-end: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(md) {
            width: calc(100% - 40px);
            margin-inline: auto;
            padding-block-end: 20px;
        }
    }

    &__title {
        @include HeadingH4Desktop;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }
    }

    &__close {
        height: 40px;
        width: 40px;

        @include media-breakpoint-down(md) {
            height: 20px;
            width: 20px;
            background-color: transparent;
            color: $text--dark-blue;

            &:hover,
            &:focus {
                background-color: transparent;
                color: $text--black;
            }
        }
    }

    &__user {
        width: 100%;
        gap: 10px;

        @include media-breakpoint-down(md) {
            padding-inline: 20px;
        }

        &-left {
            gap: 10px;
        }

        &-title {
            @include HeadingH4Desktop;

            @include media-breakpoint-down(md) {
                @include HeadingH4Mobile;
            }
        }

        &-text {
            color: rgba(0, 0, 0, 0.5);

            @include BodyText;
        }
    }

    &__avatar {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &-placeholder {
            object-fit: contain;
            padding: 16px;
        }

        &-container {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            height: 60px;
            width: 60px;
            min-height: 60px;
            min-width: 60px;
            background-color: $bg--white;
        }
    }

    &__cards {
        width: 100%;
        gap: 18px;

        @include media-breakpoint-down(md) {
            gap: 10px;
            padding-inline: 20px;
        }
    }

    &__card {
        max-width: 200px;
        max-height: 126px;
        border-radius: 5px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            max-height: 78px;
            max-width: 123px;
            align-items: flex-start;
        }

        &:nth-child(2) {
            max-height: none;
            background-color: rgba(0, 166, 195, 0.05);
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            min-width: 202px;
            padding: 18px 10px;

            @include media-breakpoint-down(md) {
                max-width: none;
                width: 100%;
                padding: 11.5px 10px;
                gap: 10px;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &-top {
            @include media-breakpoint-down(md) {
                flex-direction: row;
            }
        }
    }

    &__location {
        padding-block: 5px 20px;

        @include BodyText;

        @include media-breakpoint-down(md) {
            align-items: center;
            padding-block: 0;
            margin-inline-start: 10px;
        }
    }

    &__pill {
        padding: 2px 10px;
        border-radius: 15px;
        border: 1px solid;

        @include BodyTextSm;

        &-gold {
            border-color: $gold;
            color: $text--gold;
        }

        &-royal-blue {
            border-color: $dark-blue;
            color: $text--dark-blue;
        }

        &-sky-blue {
            border-color: $light-blue;
            color: $text--light-blue;
        }
    }

    &__text {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &-mobile {
            @include BodyTextSm;
        }
    }

    &__payments {
        position: relative;
        width: 100%;
        max-height: 298px;
        padding-inline-end: 30px;
        gap: 10px;
        overflow-y: auto;
        overflow-x: hidden;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &::after {
            content: '';
            position: sticky;
            bottom: -1px;
            left: 0;
            height: 40px;
            min-height: 40px;
            width: 100%;
            pointer-events: none;
            background-image: linear-gradient(
                to top,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0.9),
                rgba(255, 255, 255, 0)
            );
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
        }
    }

    &__payment {
        width: 100%;
        background-color: rgba(0, 166, 195, 0.05);
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 13.5px 15px;
    }

    &__amount {
        overflow-wrap: anywhere;

        @include HeadingH5Mobile;
    }

    &__status {
        height: 100%;
        gap: 5px;

        @include BodyText;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }

        .zmdi {
            font-size: 16px;
        }

        &-verified {
            color: $text--green;
        }

        &-pending {
            color: $text--light-blue;
        }

        &-unverified {
            color: $text--red;
        }
    }

    &__date {
        color: rgba(0, 0, 0, 0.5);

        @include BodyText;

        &-mobile {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextSm;
        }
    }

    &__mobile {
        padding-inline: 20px;
        width: 100%;
        gap: 10px;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__carousel {
        width: $container-width--wide;
        margin-inline-start: 20px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            display: none;
        }

        &-range {
            height: 2px;
            width: 94px !important;
            max-width: 94px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            cursor: pointer;
            overflow: hidden;

            @include transition;

            &:focus {
                border-color: $white;
            }
        }
    }

    &__item {
        background-color: rgba(0, 166, 195, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px 15px;
        gap: 5px;
        overflow: hidden;

        .recipient-detail__status {
            @include BodyTextTiny;
        }
    }
}
