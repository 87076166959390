.key-facts {
    position: relative;
    gap: 120px;

    &__left {
        gap: 20px;
        flex: 0.8;

        &-line {
            width: 100%;
            height: 1px;
            background-color: $light-blue;
        }

        &-title {
            @include HeadingH2Desktop;
        }

        .rte {
            @include BodyText;
        }
    }

    &__right {
        flex: 1;
        gap: 30px;

        &-title {
            @include BodyTextBold;
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    &__card {
        padding: 15px;
        background-color: $dark-blue;
        color: $white;
        border-radius: 5px;
        gap: 10px;

        &-title {
            @include HeadingH1Mobile;
            position: relative;

            &::before,
            &::after {
                content: "";
                flex-grow: 1;
                height: 1px;
                margin: 0 10px;
                background-color: $white;
                opacity: .5;
            }
        }

        &-content {
            @include BodyText;
        }

        &-title,
        &-content {
            text-align: center;
        }
    }
}

@include media-breakpoint-down (xl) {
    .key-facts {
        gap: 60px;
    }
}

@include media-breakpoint-down (lg) {
    .key-facts {
        flex-direction: column;
        gap: 30px;

        &__left {
            &-title {
                @include HeadingH2Mobile;
            }
        }

        &__cards {
            grid-template-columns: repeat(1, 1fr);
        }

        &__right {
            gap: 10px;
        }
    }
}