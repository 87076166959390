/*=============================================
=            Dashboard            =
=============================================*/

.dashboard {
    display: flex;
    position: relative;
    min-height: 850px;
    max-height: 850px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        max-height: none;
        min-height: auto;
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        object-fit: cover;
    }

    &__title {
        width: 100%;
        padding: 0 80px;

        @include HeadingH3Desktop;

        @include media-breakpoint-down(lg) {
            padding: 0 30px 0 40px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__right {
        flex: 0.83;
        padding: 40px 0 50px 0;
        margin-right: calc((100vw - $container-width--default) / 2);
        max-width: 650px;
        overflow: hidden;

        @include media-breakpoint-down(desktop) {
            margin-right: auto;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
        }

        @include media-breakpoint-down(md) {
            max-width: none;
            padding-block: 20px;
        }

        &--hidden {
            display: none;
        }
    }

    &__left {
        flex: 1;
        position: relative;
        background-image: url('/assets/img/img/dashboard-image.png');
        background-color: $bg--light-blue;
        background-position: right center;
        background-size: auto;
        background-repeat: no-repeat;
        min-width: 400px;
        overflow: hidden;
        z-index: 1;

        @include media-breakpoint-down(md) {
            min-width: auto;
        }

        @include media-breakpoint-down(sm) {
            background-size: 94%;
            background-position-y: 102px;
            background-position-x: 31px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: $below;
            background-image: linear-gradient(to bottom,
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0.4),
                    rgba(255, 255, 255, 0.3),
                    rgba(255, 255, 255, 0.1),
                    rgba(255, 255, 255, 0));
        }

        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            padding-block-start: 40px;
            z-index: 1;
            max-width: 790px;
            width: 100%;
            gap: 30px;

            @include media-breakpoint-down(md) {
                position: static;
                padding-block: 20px;
            }
        }
    }

    &__payments {
        width: 100%;
        gap: 30px;

        @include media-breakpoint-down(md) {
            gap: 20px;
        }

        &-header {
            width: 100%;
            padding: 0 80px;
            gap: 10px;

            @include media-breakpoint-down(lg) {
                padding: 0 30px 0 40px;
            }

            @include media-breakpoint-down(md) {
                justify-content: center;
                text-align: center;
            }
        }

        &-title {
            @include BodyText;

            @include media-breakpoint-down(md) {
                min-height: 20px;

                @include BodyTextSm;
            }
        }

        &-link {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 30px;
        max-height: 602px;
        padding-inline: 80px 40px;
        margin-inline-end: 40px;
        overflow-y: auto;

        @include media-breakpoint-down(lg) {
            padding-inline: 40px 15px;
            margin-inline-end: 15px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }
    }

    &__links {
        gap: 30px;
        overflow: hidden;
        overflow-x: auto;
        padding-block-end: 20px;
        margin-block-end: 20px;
        min-height: 62px;
        width: 100%;
        max-width: 540px;
        cursor: grab;

        &:hover {
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.5);
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-block-end: 0;
            gap: 0;
            min-height: 0;
            max-width: none;
        }

        &::-webkit-scrollbar {
            height: 4px;

            @include media-breakpoint-down(md) {
                height: 0;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }

        &-container {
            overflow: hidden;
            padding-inline-end: 80px;
            padding-inline-start: 30px;
            margin-block-start: 30px;
            width: 100%;

            @include media-breakpoint-down(md) {
                padding-inline-end: 0;
                margin-block-start: 0;
            }
        }

        &-title {
            padding-inline-start: 30px;
            padding-inline-end: 80px;

            @include BodyText;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-list {
            gap: 5.5px;
            min-height: 42px;
            margin-inline-start: 1px;

            @include media-breakpoint-down(md) {
                padding-inline-end: 20px;
            }
        }

        .link.dashboard__links-link {
            gap: 9.5px;
            margin-inline-start: 0.5px;
            width: max-content;
            user-select: none;
            -moz-user-select: -moz-none;
        }

        &-icon {
            width: auto;
            margin-inline-start: 8px;

            @include transition;
        }
    }

    &__contacts {
        position: relative;
        width: 100%;
        padding-inline-start: 40px;

        @include media-breakpoint-down(md) {
            padding-inline: 0;
            padding-block-end: 20px;
            margin-block-end: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10000px;
            transform: translateX(-30px);
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .dashboard__carousel {
            padding-inline: 20px 0;

            &-inner {
                padding-inline: 0;
            }
        }

        &-new {
            width: 100%;
        }

        &-header {
            width: 100%;
            gap: 10px;
            padding-inline-end: 80px;
            padding-block: 40px 30px;
            max-width: 640px;

            @include media-breakpoint-down(lg) {
                padding-inline-end: 59px;
            }

            @include media-breakpoint-down(md) {
                padding-inline: 20px;
                padding-block: 20px;

                .link {
                    display: none;
                }
            }
        }

        &-title {
            @include BodyText;

            @include media-breakpoint-down(md) {
                max-width: 70%;
            }
        }

        &-list {
            position: relative;
            gap: 10px;
            padding-inline-end: 40px;
            margin-inline-end: 40px;
            max-height: 512px;
            overflow-y: auto;

            @include media-breakpoint-down(lg) {
                padding-inline-end: 15px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &::after {
                content: '';
                position: sticky;
                bottom: 0;
                left: 0;
                height: 100px;
                min-height: 100px;
                width: 100%;
                pointer-events: none;
                background-image: linear-gradient(to top,
                        rgba(255, 255, 255, 0.8),
                        rgba(255, 255, 255, 0.5),
                        rgba(255, 255, 255, 0));
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background: $black;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &__mobile-link {
        width: calc(100% - 40px);
        margin-inline: auto;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    // Glide Carousels
    &__carousel {
        @include media-breakpoint-up(md) {
            display: none;
        }

        &-inner {
            padding: 0 30px;
            overflow: hidden;
        }
    }

    &__range {
        width: 94px !important;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.1);
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        &-container {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .glide__bullets {
        gap: 10px;
        margin-block-start: 27px;
    }

    .glide__bullet {
        height: 10px;
        width: 10px;
        background-color: $bg--white;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.3;

        @include transition;

        &--active {
            opacity: 1;
        }
    }
}

/*=====  End of Dashboard  ======*/