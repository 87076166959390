/*=============================================
=            Menu Mobile Platform            =
=============================================*/

.menu-mobile-platform {
    &__icon-container {
        z-index: 99999999;
        padding: 8px 0px 17px 8px;
        position: relative;
        cursor: pointer;

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    &__icon {
        width: 20px;
        height: 7px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 1s ease-in-out;
        -moz-transition: 1s ease-in-out;
        -o-transition: 1s ease-in-out;
        transition: 1s ease-in-out;
        cursor: pointer;
        z-index: 9999999;
        height: 100%;

        &-bar {
            display: block;
            position: absolute;
            height: 1px;
            width: 100%;
            background: $bg--white;
            border-radius: 9px;
            border-radius: 12px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
        }

        &-bar:nth-child(1) {
            top: 0px;
            right: 0;
            left: auto;
            -webkit-transform-origin: right center;
            -moz-transform-origin: right center;
            -o-transform-origin: right center;
            transform-origin: right center;
        }

        &-bar:nth-child(2) {
            top: 8px;
            left: 0;
            left: auto;
            -webkit-transform-origin: right center;
            -moz-transform-origin: right center;
            -o-transform-origin: right center;
            transform-origin: right center;
            width: 80%;
        }

        &--open .menu-mobile-platform__icon-bar:nth-child(1) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: -2px;
            left: auto;
            right: auto;
            width: 20px;
        }

        &--open .menu-mobile-platform__icon-bar:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 12.5px;
            left: auto;
            width: 20px;
        }
    }

    &__overlay {
        position: fixed;
        top: 70px;
        right: 0;
        width: 100%;
        height: calc($container-width--wide - 71px);
        z-index: 102;
        opacity: 0;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.1);
        color: $text--dark-blue;

        @include transitionSlow;

        @include media-breakpoint-up(xl) {
            display: none;
        }

        &--active {
            opacity: 1;
            visibility: visible;
        }
    }

    &__inner {
        z-index: 101;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $bg--dark-blue;
        color: $text--white;
        height: calc($container-height--wide + 1px);
        width: 50%;
        gap: 15px;
        padding-block-end: 60px;
        overflow-y: auto;

        @include media-breakpoint-down(md) {
            width: 70%;
        }

        @include media-breakpoint-down(sm) {
            width: $container-width--wide;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }
    }

    &__links {
        gap: 15px;
        padding-inline: 30px;
        margin-block-start: 113px;
    }

    &__link {
        @include HeadingH4Desktop;

        &-item {
            padding-block-end: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
        }
    }

    &__middle {
        padding-block-start: 20px;
        gap: 20px;
        margin-block-end: auto;

        &-title {
            @include HeadingH4Mobile;
        }
    }

    &__bottom {
        width: 100%;
        padding-inline: 30px;
        margin-block-start: 113px;
    }

    &__header {
        padding-inline: 30px;
        padding-block: 7px;
    }

    &__payment {
        &-link {
            margin-inline: 30px;
            width: fit-content;
        }
    }

    &__carousel {
        color: $text--black;
        padding-inline: 30px 0;

        &-inner {
            overflow: hidden;
        }

        .dashboard-contact__info-text {
            @include BodyTextSm;
        }

        .dashboard-contact__pill {
            border-radius: 10px;

            @include BodyTextTiny;
        }

        .dashboard-contact__icon {
            color: $text--dark-blue;
        }

        .dashboard-contact__image-container {
            height: 40px;
            min-height: 40px;
            width: 40px;
            min-width: 40px;
        }

        .dashboard-contact__image-placeholder {
            padding: 12px;
        }
    }
}

/*=====  End of Menu Mobile Platform  ======*/
