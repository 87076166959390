/*===================================================================
=            Document Category            =
=====================================================================*/

.document-type {
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  cursor: pointer;

  @include transition;

  &:hover, &:focus {
    border-color: $light-blue;

    .individual-auth__card-icon {
      background-color: $bg--black
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--active {
    border-color: $light-blue;
  }

  &--error {
    border-color: $bg--red;
  }

  &__left {
    gap: 10px;
  }

  &__title {
    @include HeadingH5Desktop;
  }

  &__text, &__status {
    @include BodyTextSm;
  }

  &__text {
    display: list-item;
    margin-inline-start: 20px;

    &::marker {
      font-size: 9px;
    }
  }

  &__status {
    color: rgba(0, 0, 0, 0.30);
    gap: 10px;

    &--error {
      color: $text--red;
    }

    &--success {
      color: $text--green;
    }

    &--pending {
      color: $text--light-blue;;
    }
  }

  &__icon {
    height: 40px;
    width: 40px;
    background-color: $bg--dark-blue;
    color: $text--white;
    border-radius: 50%;

    @include transition;

    .zmdi {
      font-size: 15px;
    }
  }
}

/*=====  End of Document Category  ======*/