/*===================================================================
=            App Download            =
=====================================================================*/

.app-download {
  padding: 90px 0;

  @include media-breakpoint-down(lg) {
    padding: 40px 0;
  }

  &__wrapper {
    position: relative;
  }

  &__left,
  &__right {
    flex: 1;
  }

  &__inner {
    gap: 120px;

    @include media-breakpoint-down(lg) {
      gap: 40px;
      flex-direction: column;
    }
  }

  &__left {
    box-sizing: border-box;
    gap: 30px;

    &-top {
      gap: 20px;
    }
  }

  &__right {
    position: relative;
    min-height: 480px;
    border-radius: 10px;
    overflow: hidden;
    min-width: 600px;

    @include media-breakpoint-down(desktop) {
      min-width: 400px;
    }

    @include media-breakpoint-down(lg) {
      display: grid;
    }

    @include media-breakpoint-down(sm) {
      height: 487px;
      min-height: 487px;
      max-height: 487px;
      min-width: $container-width--wide;
    }
  }

  &__icon {
    width: 63px;
    height: 63px; 
  }

  &__image {
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: contain;
    vertical-align: middle;
  }

  &__title {

    @include HeadingH2Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH2Mobile;
    }
  }

  &__stores {
    gap: 20px;
  }

  &__store {
    &-icon {
      height: $container-height--wide;
      width: $container-width--wide;
      max-width: 160px;
      max-height: 50px;
      object-fit: contain;

      @include media-breakpoint-down(sm) {
        max-width: 140px;
      }
    }
  }
}

/*=====  End of App Download  ======*/