/*===================================================================
=            Media With Text            =
=====================================================================*/

.media-with-text {
  padding: 90px 0;

  &__wrapper {
    position: relative;
  }

  @include media-breakpoint-down(lg) {
    padding: 60px 0;
  }

  &__left,
  &__right {
    flex: 1;
  }

  &__inner {
    gap: 120px;

    &.flex--row-reverse {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &__left {
    box-sizing: border-box;
    gap: 30px;
  }

  &__right {
    position: relative;
    min-height: auto;
    border-radius: 10px;
    overflow: hidden;
    min-width: 600px;

    @include media-breakpoint-down(desktop) {
      min-width: 400px;
      display: grid;
    }

    @include media-breakpoint-down(sm) {
      height: 240px;
      min-height: 240px;
      min-width: $container-width--wide;
    }
  }

  &__video {
    width: $container-width--wide;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      height: $container-height--wide;
    }
  }

  &__image {
    height: auto;
    width: $container-width--wide;
    object-fit: contain;
    vertical-align: middle;
    border-radius: 10px;
    margin: auto;
  }

  &__title {
    @include HeadingH3Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH2Mobile;
    }
  }
}

/*=====  End of Media With Text  ======*/
