/*=============================================
=            News Grid            =
=============================================*/

.news-grid {
  position: relative;
  overflow: hidden;

  &__inner {
    gap: 30px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding-block-end: 92px;
    }
  }

  &__header {
    width: $container-width--wide;
    gap: 20px;

    &-left {
      gap: 20px;

      @include media-breakpoint-down(sm) {
        gap: 30px;
      }
    }
  }

  &__text {
    @include BodyText;
  }

  &__heading {
    @include HeadingH2Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH2Mobile;
    }
  }

  &__grid {
    display: grid;
    width: $container-width--wide;
    grid-template-columns: repeat(auto-fill, minmax(269px, 1fr));
    gap: 15px;

    @include transition;
    
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__carousel {
    width: $container-width--wide;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    &-range {
      height: 2px;
      width: 300px !important;
      max-width: 300px;
      background: rgba(0, 0, 0, 0.10);
      border-radius: 8px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      @include transition;

      &:focus {
        border-color: $white;
      }

      &-container {
        position: absolute;
        top: auto;
        bottom: 53px;
        left: 120px;
        width: 100%;

        @include media-breakpoint-down(md) {
          left: 30px;
        }
      }
    }
  }

  &__card {
    &-top {
      height: 240px;
      min-height: 240px;
      max-height: 240px;
      border-radius: 10px;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        height: 180px;
        min-height: 180px;
        max-height: 180px;
      }
    }

    &-bottom {
      height: $container-height--wide;
      gap: 10px;
    }

    &-footer {
      width: $container-width--wide;
      gap: 20px;

      @include media-breakpoint-down(sm) {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }

    &-image {
      height: $container-height--wide;
      width: $container-width--wide;
      object-fit: cover;
    }

    &-title {
      margin-block-start: 20px;
      width: $container-width--wide;
  
      @include HeadingH5Desktop;

      @include media-breakpoint-down(sm) {
        max-width: 230px;

        @include HeadingH5Mobile;
      }
    }

    &-read {
      color: rgba(0, 0, 0, 0.30);

      @include BodyText;

      @include media-breakpoint-down(sm) {
        @include BodyTextSm;
      }
    }
  }
}

/*=====  End of News Grid  ======*/
