.account-slider {
    width: 540px;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-block: 40px;
    padding-inline: 40px;
    margin-inline-start: auto;
    background-color: $bg--white;
    gap: 30px;
    transform: translateX(540px);
    overflow-y: auto;

    @include transitionSlow;

    @include media-breakpoint-down(md) {
        gap: 20px;
        padding: 20px;
        height: auto;
        width: 100%;
        margin-inline-start: 0;
        margin-block-start: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        transform: translateX(0) translateY(100%);
    }

    &__overlay {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 9999999999;
        cursor: default;
        visibility: hidden;
        opacity: 0;
        overflow-y: auto;

        @include transitionSlow;

        @include media-breakpoint-down(xl) {
            height: calc(100vh - $navbar-height--mobile);
        }

        &--active {
            visibility: visible;
            opacity: 1;
            animation: backgroundFade 0.2s ease-in-out forwards;

            .account-slider {
                transform: translateX(0px);

                @include media-breakpoint-down(md) {
                    transform: translateY(0px);
                }
            }
        }

        @keyframes backgroundFade {
            0% {
                background-color: rgba(0, 0, 0, 0);
            }
            100% {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__inner {
        overflow: hidden;
        width: 100%;
        gap: 30px;

        @include media-breakpoint-up(md) {
            padding-inline-end: 40px;
            overflow-y: auto;
        }
    }

    &__header {
        width: 100%;
        padding-block-end: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(md) {
            margin-inline: auto;
            padding-block-end: 20px;
        }
    }

    &__title {
        @include HeadingH4Desktop;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }
    }

    &__close.account-slider__close {
        height: 40px;
        width: 40px;

        @include media-breakpoint-down(md) {
            height: 20px;
            width: 20px;
            background-color: transparent;
            color: $text--dark-blue;

            &:hover,
            &:focus {
                background-color: transparent;
                color: $text--black;
            }
        }
    }

    &__form, &__inputs, &__button {
        width: 100%;
    }

    &__inputs {
        gap: 30px;
        overflow-y: auto;
    }

    &__form {
        gap: 30px;

        input[type="text"], input[type="password"] {
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            outline-color: $black;
            width: $container-width--wide;
            padding: 10px 35px 10px 20px;
            background-color: $bg--white;
            max-width: none !important;
            margin: 0 !important;
            
            @include BodyText;
        
            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    
        .zmdi {
            &:hover {
                opacity: 0.7;
            }
    
            &-eye {
                display: none;
        
                &--active {
                display: block;
                }
            }
    
            &-eye-off {
                display: none;
        
                &--active {
                display: block;
                }
            }
        }
    }
    
    &__password {
        &-icon {
            position: absolute;
            bottom: 50%;
            right: 20px;
            z-index: 1;
            transform: translateY(50%);
            cursor: pointer;

            @include transition;
        }
    }

    &__box {
        position: relative;
    }

    &__field {
        gap: 5px;

        &-password {
            padding-block-end: 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    
    &__label {
        @include Label;
    }
    
    &__validation {
        color: $text--red;
        display: none;

        @include BodyTextSm;

        &--active {
            display: block;
        }
    }
}
