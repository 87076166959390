/*=============================================
=            Payments Component            =
=============================================*/

.payments {
    display: flex;
    padding-inline-start: 80px;
    position: relative;

    @include media-breakpoint-down(lg) {
        padding-inline-start: 40px;
    }

    @include media-breakpoint-down(md) {
        padding-inline-start: 0;
    }

    &__divider {
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__title {
        margin: auto;

        @include HeadingH4Desktop;

        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            min-height: 40px;
            padding-inline: 60px;
            text-align: center;

            @include BodyText;
        }
    }

    &__top {
        position: relative;
        padding: 30px 80px;

        @include media-breakpoint-down(lg) {
            padding-block: 20px;
            padding-inline: 20px;
        }
    }

    &__back {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        display: none;

        &--active {
            display: flex;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__subtitle {
        padding-inline-end: 80px;

        @include BodyText;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__left {
        flex: 0.89;
        gap: 20px;
        padding: 40px 0;
        min-height: 750px;

        @include media-breakpoint-down(lg) {
            flex: 0.8;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
            padding-block-start: 0;
        }
    }

    &__right {
        flex: 1;
        position: relative;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        padding-block: 30px;
        background-color: $bg--white;

        @include media-breakpoint-down(lg) {
            display: none;
            padding-block: 10px 0;
            overflow-y: auto;

            &--active {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background: $black;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &__form,
    &__inputs {
        width: $container-width--wide;
        gap: 20px;
    }

    &__inputs {
        .payments__label {
            @include media-breakpoint-down(lg) {
                width: 50%;
                min-width: 300px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                min-width: auto;
            }
        }
    }

    &__form {
        padding-inline-end: 80px;

        @include media-breakpoint-down(lg) {
            padding-inline-end: 60px;
        }

        @include media-breakpoint-down(lg) {
            padding-inline: 20px;
        }

        input[type='text'] {
            border-radius: 25px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            outline-color: $black;
            width: $container-width--wide;
            padding-block: 10px;
            padding-inline: 20px 41px;
            background-color: $bg--white;
            background-image: url('/assets/img/img/search-icon.svg');
            background-repeat: no-repeat;
            background-size: 12px;
            background-position-x: calc(100% - 16px);
            background-position-y: 13px;
            height: 40px;

            @include Input;

            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }

        [type='date'] {
            position: relative;
            background: #fff url(/assets/img/img/date-icon.svg) no-repeat;
            background-position-x: calc($container-width--wide - 15px);
            background-position-y: 50%;
            border-radius: 25px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            padding: 10px 20px;
            height: 40px;
            width: 100%;
            margin-block-start: 5px;
            cursor: pointer;

            @include Input;

            @include media-breakpoint-down(lg) {
                width: auto;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        [type='date']::-webkit-inner-spin-button {
            display: none;
        }

        [type='date']::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
        }
    }

    &__button {
        .zmdi {
            transform: translateY(1px);
        }

        &--active {
            background-color: $bg--dark-blue;
            color: $text--white;
        }
    }

    &__field {
        width: 100%;
        gap: 5px;

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }
    }

    &__label {
        width: 100%;

        @include Label;
    }

    &__dates {
        display: none;
        width: 100%;
        gap: 20px;

        @include media-breakpoint-down(lg) {
            gap: 5px;
        }

        &--active {
            display: flex;
        }

        .payments__label {
            @include media-breakpoint-down(lg) {
                width: auto;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    &__list {
        position: relative;
        max-height: 567px;
        min-height: 567px;
        overflow-y: auto;
        padding-inline-end: 40px;
        margin-inline-end: 40px;

        @include media-breakpoint-down(lg) {
            padding-inline-end: 30px;
            margin-inline-end: 30px;
        }

        @include media-breakpoint-down(md) {
            padding-inline-end: 0px;
            margin-inline-end: 0px;
        }

        &::after {
            content: '';
            position: sticky;
            bottom: -1px;
            left: 0;
            height: 100px;
            min-height: 100px;
            width: 100%;
            pointer-events: none;
            background-image: linear-gradient(
                to top,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }
    }

    &__item {
        position: relative;
        width: 100%;
        padding: 15px 20px;
        background-color: rgba(0, 166, 195, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        gap: 10px;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
            padding-inline-end: 30px;
        }

        .zmdi-chevron-right {
            @include media-breakpoint-down(sm) {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
        }

        @include transition;

        &:hover,
        &:focus {
            border-color: rgba(0, 166, 195, 0.05);
            background-color: $bg--light-blue;
            color: $text--white;

            .payments__item-date {
                color: $text--white;
            }
        }

        &--active {
            border-color: rgba(0, 166, 195, 0.05);
            background-color: $bg--light-blue;
            color: $text--white;

            .payments__item-date {
                color: $text--white;
            }
        }

        &--hidden {
            display: none;
        }

        &-caption {
            margin-block-start: 20px;
            width: 100%;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-block-end: 20px;

            @include BodyTextSm;
        }

        &-title {
            max-width: 130px;
            min-width: 130px;
            margin-inline-end: 30px;

            @include media-breakpoint-down(md) {
                margin-inline-end: 15px;
            }

            @include media-breakpoint-down(sm) {
                max-width: 100%;
                margin-inline-end: 0;
            }
        }

        &-title,
        &-amount {
            @include BodyText;
        }

        &-info {
            margin-inline-end: auto;
            gap: 10px;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }
        }

        &-status {
            .zmdi {
                height: 16px;
                width: 16px;
            }

            .zmdi-check-circle {
                color: $dark-blue;
            }

            .zmdi-close-circle {
                color: $red;
            }
        }

        &-dash {
            height: 4px;
            width: 30px;
            border-radius: 2px;
            overflow: hidden;

            @include media-breakpoint-down(lg) {
                width: 16px;
            }

            &-inner {
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, $bg--dark-blue var(--itemProgress), rgba(0, 0, 0, 0.1) var(--itemProgress));
            }
        }

        &-date {
            min-width: max-content;
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextSm;
        }
    }

    &__details {
        display: none;
        width: 100%;

        @include media-breakpoint-down(lg) {
            height: 100%;
            outline: none;
        }

        &--active {
            display: flex;
        }
    }

    &__header {
        padding-inline: 30px 80px;
        padding-block-end: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        gap: 10px;

        @include media-breakpoint-down(lg) {
            padding-inline-end: 30px;
        }

        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }

    &__buttons {
        gap: 10px;

        .link {
            min-width: 137.5px;
            padding-inline: 20px;
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__transaction {
        padding-inline: 30px 80px;
        padding-block: 20px;
        background-color: rgba(0, 166, 195, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        gap: 20px;

        @include media-breakpoint-down(lg) {
            padding-inline-end: 30px;
        }
    }

    &__amounts {
        width: 100%;
    }

    &__amount {
        flex: 1;
    }

    &__value {
        @include HeadingH3Desktop;

        @include media-breakpoint-down(md) {
            @include HeadingH3Mobile;
        }
    }

    &__currency {
        @include BodyTextSm;
    }

    &__text {
        @include BodyTextSm;
    }

    &__caption {
        color: rgba(0, 0, 0, 0.5);

        @include BodyTextTiny;
    }

    &__info {
        width: 100%;
        padding-inline: 30px 80px;
        padding-block: 20px;
        gap: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(lg) {
            padding-inline-end: 30px;
        }

        &-title {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextSm;
        }

        &-text {
            @include BodyText;
        }
    }

    &__content {
        width: 100%;
        padding-inline: 30px 80px;
        padding-block: 20px;
        gap: 10px;

        @include media-breakpoint-down(lg) {
            padding-inline-end: 30px;
        }
    }

    &__row {
        width: 100%;
        gap: 20px;

        @include BodyTextSm;
    }

    &__mobile {
        &-buttons {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 166, 195, 0.05);
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-block: 20px 60px;
            padding-inline: 20px;
            gap: 10px;

            @include media-breakpoint-up(lg) {
                display: none;
            }

            .button {
                min-width: 137.5px;
                padding-inline: 20px;

                @include media-breakpoint-down(sm) {
                    min-width: max-content;
                    flex: 1;
                }
            }
        }
    }

    &__loader {
        margin: auto;
        display: none;

        &--active {
            display: flex;
        }
    }

    &__step {
        gap: 15px;
        padding: 20px 80px 20px 40px;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        
        @include media-breakpoint-down(lg) {
            padding: 20px 30px;
        }
    
        &-progress-bar {
            width: 100%; 
            height: 6px; 
            border-radius: 3px;
            overflow: hidden;
        }
        
        &-progress {
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, $bg--light-blue var(--progress), rgba(0, 0, 0, 0.1) var(--progress));

            &--failed {
                background: $bg--red;
            }

            &--complete {
                background: $bg--bright-green;
            }
        }

        &-title {
            @include HeadingH5Mobile;
        }

        &-subtitle {
            @include BodyTextSm;
        }

        &-text {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextTiny;
        }
    }
}

/*=====  End of Payments Component  ======*/
