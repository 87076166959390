/*=============================================
=            New Recipient Component            =
=============================================*/

.new-recipient {
    gap: 60px;
    padding-block: 80px 111px;

    @include media-breakpoint-down(md) {
        padding-block: 20px 163px;
        gap: 20px;
    }

    &__wrapper {
        hr {
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    &__error {
        display: flex;
        color: $text--red;

        @include BodyText;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }
    }

    &__header {
        position: relative;
        width: $container-width--wide;
        max-width: $container-width--default;
        margin: 0 auto;
        padding: 30px 80px;
        opacity: 0.8;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }
    }

    &__heading {
        @include HeadingH4Desktop;

        @include media-breakpoint-down(md) {
            @include BodyText;
        }
    }

    &__top {
        gap: 10px;
        padding-inline: 120px;
        width: $container-width--wide;
        max-width: $container-width--default;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding-inline: 20px;
            align-items: flex-start;
            padding-block-end: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &__middle,
    &__bottom {
        gap: 20px;
        padding-inline: 120px;
        width: $container-width--wide;
        max-width: $container-width--default;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding-inline: 20px;
        }
    }

    &__middle {
        &:nth-child(2) {
            @include media-breakpoint-down(md) {
                padding-block-end: 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }

    &__bottom {
        @include media-breakpoint-down(md) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-block: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            background-color: $bg--white;
        }
    }

    &__title {
        text-align: center;
        opacity: 0.8;

        @include HeadingH5Desktop;

        @include media-breakpoint-down(md) {
            text-align: start;
        }
    }

    &__subtitle {
        text-align: center;
        opacity: 0.8;

        @include BodyText;

        @include media-breakpoint-down(md) {
            text-align: start;
        }
    }

    &__text {
        text-align: center;
        opacity: 0.8;

        @include HeadingH5Mobile;
    }

    &__form {
        gap: 16px;
    }

    &__input {
        background-color: rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        height: 80px;
        width: 60px;
        text-align: center;

        @include transition;
        @include HeadingH2Desktop;

        @include media-breakpoint-down(md) {
            height: 60px;
            width: 40px;

            @include HeadingH2Mobile;
        }

        &--active {
            border-color: $bright-green;
        }

        &--error {
            border-color: $red;
        }
    }

    &__status {
        display: none;
        gap: 5px;

        @include BodyTextSm;

        &--active {
            display: flex;
        }

        &--valid {
            color: $text--green;
        }

        &--invalid {
            color: $text--red;
        }
    }

    &__button {
        min-width: 320px;

        @include media-breakpoint-down(sm) {
            min-width: auto;
            width: 100%;
        }

        &:disabled {
            filter: grayscale(1);
        }
    }

    &__loader {
        display: none;

        &--active {
            display: block;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

/*=====  End of New Recipient Component  ======*/
