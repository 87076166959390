.cards {
    gap: 30px;
    position: relative;

    &__title {
        .rte {
            text-align: center;
        }
    }
    
    &__container {
        gap: 20px;
    }

    &__card {
        flex: 1;
        padding: 40px;
        background-color: $dark-blue;
        color: $white;
        gap: 20px;
        border-radius: 10px;
        min-width: 280px;
        width: 100%;

        &-title {
            @include BodyTextBold;
        }

        &-icon {
            width: 80px;
            height: 80px;
        }

        &-content {
            @include BodyText;
            text-align: center;
        }

        .link {
            margin-top: auto;
        }
    }
}

@include media-breakpoint-down (md) {
    .cards {
        &__title {
            .rte {
                text-align: left;
            }
        }

        &__container {
            flex-direction: column;
        }

        &__card {
            gap: 10px;
            padding: 20px 10px;

            &-icon {
                width: 64px;
                height: 64px;
            }
        }
    }
}