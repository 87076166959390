/*=============================================
=            Media Carousel            =
=============================================*/

.media-carousel {
  max-width: $container-width--default;
  margin: auto;
  position: relative;
  overflow: hidden;

  &__wrapper {
    &.background--blue {
      .media-carousel {
        &__carousel {
          &-track {
            &::after {
              background: linear-gradient(to right, #e5f6f9 0%,rgba(255,255,255,0) 100%); 
            }
    
            &::before {
              background: linear-gradient(to left, #e5f6f9 0%,rgba(255,255,255,0) 100%); 
            }
          }
        }
      }
    }
  }

  &__inner {
    gap: 120px;
    position: relative;
    overflow: hidden;
    width: $container-width--wide;
    padding: 90px 0;

    @include media-breakpoint-down(xl) {
      gap: 60px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    @include media-breakpoint-down(md) {
      padding: 60px 0;
    }
  }

  &__content {
    flex: 0.846;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      padding-right: 120px;
    }

    @include media-breakpoint-down(md) {
      padding-right: 30px;
    }

    &.container--padding-right {
      @include media-breakpoint-down(lg) {
        padding-left: 120px;
      }
  
      @include media-breakpoint-down(md) {
        padding-left: 30px;
      }
    }
  }

  &__carousel {
    flex: 1;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      width: $container-width--wide;
      padding-left: 120px;
    }

    @include media-breakpoint-down(md) {
      padding-left: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    &.container--padding-left {
      @include media-breakpoint-down(lg) {
        width: $container-width--wide;
        padding-right: 120px;
      }
  
      @include media-breakpoint-down(md) {
        padding-right: 30px;
      }
  
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    &-slides {
      min-height: 320px;

      @include media-breakpoint-down(sm) {
        min-height: 260px;
      }
    }

    &-inner {
      position: relative;
      overflow: hidden;
      padding: 0 20px;
    }

    &-track {
      position: relative;

      &::after {
        content: "";
        width: 20%;
        height: $container-height--wide;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 0;
        transform: translateX(-20px);
        background: linear-gradient(to right, #ffffff 0px, #ffffff 20px, rgba(255,255,255,0) 100%); 

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &::before {
        content: "";
        width: 20%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(20px);
        background: linear-gradient(to left, #ffffff 0, #ffffff 20px, rgba(255,255,255,0) 100%); 
        z-index: $above;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  &__heading {
    @include HeadingH2Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH2Mobile;
    }
  }

  &__card {
    overflow: hidden;
    border-radius: 10px;
    height: 190px;
    width: $container-width--wide;

    @include transition;

    &-image {
      height: $container-height--wide;
      width: $container-width--wide;
      object-fit: cover;
    }
  }

  &__title {
    flex: 1;
    align-items: center;
    margin-block-start: 30px;

    @include HeadingH2Mobile;
  }

  &__arrow {
    background-color: $bg--dark-blue;
    color: $text--white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    cursor: pointer;

    @include transition;

    .zmdi {
      font-size: $font-size--sm;
    }

    &:hover,
    &:focus {
        color: $text--white;
        background-color: $bg--black;
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  .glide__slide--clone {
    height: 190px;
  }

  .glide__slide--active {
    height: 320px;

    @include media-breakpoint-down(sm) {
      height: 260px;
    }
  }
}

/*=====  End of Media Carousel  ======*/
