.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;

  &.active {
    display: flex;
  }

  .phone-input.readonly {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  &-button {
    margin-block-start: 10px;
    width: 100%;
  }

  &-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $bg--white;
    width: 80%;
    max-width: 540px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &-header {
    text-align: center;
    @include HeadingH4Desktop;

    @include media-breakpoint-down(md) {
      @include HeadingH4Mobile;
    }
  }

  &-body {
    gap: 10px;
    @include BodyText;

    @include media-breakpoint-down(md) {
      @include BodyTextSm;
    }

    &--section {
      @include media-breakpoint-down(md) {
        flex-direction: column;
        gap: 10px;
        margin-block-end: 5px;
      }
    }
  }

  .close {
    color: $bg--black;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0
    }

    to {
      opacity: 1
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0
    }

    to {
      opacity: 1
    }
  }
}

.iti__tel {
  &-input {
    width: 100%; 
    border: 1px solid #ccc;
    padding-block: 10px;

    .selected-flag {
      height: 40px;
    }

    .flag-container {
      height: 40px;
    }

    .iti__flag {
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      height: 40px;
      border: 1px solid #ccc;
      padding: 0 10px;
    }
  }
}
