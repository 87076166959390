html,
body {
    font-family: $font-family--base;
    font-weight: 400;
    overflow-x: hidden;
    color: $text--black;
}

body {
    @include media-breakpoint-down(xl) {
        margin-top: 70px;
    }
}

.container {
    width: $container-width--wide;
    max-width: $container-width--default;
    margin: 0 auto;
    padding: 0 120px;

    @include media-breakpoint-down(md) {
        padding: 0 30px;
    }

    &--padding-right {
        padding-left: 0;
    }

    &--padding-left {
        padding-right: 0;
    }

    &--margin-lg {
        padding-top: 90px;
        padding-bottom: 90px;

        @include media-breakpoint-down(md) {
            padding: 60px 30px;
        }
    }

    &--margin-md {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.hidden {
    display: none;
    visibility: hidden;
}

.horizontal-ruler {
    &--auto {
        width: 100%;
        height: 2px;
        background-color: #E5E5E5;
    }

    &--full {
        bottom: 0;
        position: absolute;
        left: 0;
        width: 100vw;
        left: calc(-50vw + 50%);
        height: 2px;
        background-color: #E5E5E5;
    }
}

    input[type="text"], input[type="password"], select {
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        outline-color: $black;
        width: $container-width--wide;
        padding: 10px 20px;
        background-color: $bg--white;
        height: 40px;
        
        @include Input;

        &::placeholder {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    select {
        -webkit-appearance: none;
        background-image: url('/assets/img/img/caret-down-black-icon.svg');
        background-position-x: calc($container-width--wide - 15px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        background-size: 8px;
        z-index: $above;
        color: rgba(0, 0, 0, 0.5);
        height: auto;
        cursor: pointer;
    } 

    option
    {
        color: $text--black;
    }

    option:first-child
    {
        color: rgba(0, 0, 0, 0.5);
    }

    input[type='range']::-webkit-slider-thumb {
        width: 6px;
        width: var(--thumb-size);
        height: 2px;
        opacity: 1;
        background: $bg--black;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }
    
    input[type='range']::-moz-range-thumb {
        width: 6px;
        width: var(--thumb-size);
        height: 2px;
        opacity: 1;
        background: $bg--black;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }