/*===================================================================
=            Social Share            =
=====================================================================*/

.social-share {
  background-color: $bg--dark-blue;
  color: $text--white;
  padding: 30px 240px;
  gap: 30px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 10px;
    padding: 30px;
  }

  &__socials {
    gap: 30px;
  }

  &__social {
    font-size: 20px;
    
    .zmdi {
      height: 20px;
      width: 20px;
    }

    &:hover, &:focus {
      color: $text--black;
    }
  }

  &__title {
    @include BodyText;
  }
}

/*=====  End of Social Share  ======*/