/*=============================================
=            Register Component            =
=============================================*/

.register {
  display: flex;
  gap: 80px;
  position: relative;

  @include media-breakpoint-down(xl) {
    gap: 60px;
  }

  &__title {
      @include HeadingH2Desktop;

    @include media-breakpoint-down(md) {
      @include HeadingH1Mobile;
    }
  }

  &__content {
    color: rgba(0, 0, 0, 0.8);

    @include BodyText;
  }

  &__left, &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.78;
    gap: 20px;
    padding: 80px 0 80px 80px;
    margin-left: calc((100vw - $container-width--default) / 2);
    width: calc(100% + 80px);
    max-width: 640px;

    @include media-breakpoint-up(wide) {
      flex: 1;
    }

    @include media-breakpoint-down(desktop) {
      margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
      flex: 1;
      padding-right: 80px;
      margin: auto;
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 30px 30px 120px;
    }
  }

  &__right {
    flex: 1;
    position: relative;
    background-color: $bg--light-blue;
    min-height: 820px;
    transform-style: preserve-3d;
    perspective: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__cards {
    gap: 20px;
  }

  &__card {
    flex: 1;
    gap: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    min-height: 229px;
    min-width: 150px;
    max-width: 270px;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(sm) {
      max-width: none;
    }

    &:hover,
    &:focus {
      border-color: $light-blue;
    }

    &--active {
      border-color: $light-blue;

      .register__tick {
        background-color: $bg--light-blue;
        border-color: $light-blue;

        .zmdi {
          display: block;
        }
      }
    }

    &-icon {
      height: 48px;
      width: 48px;
    }

    &-title {
      text-align: center;

      @include HeadingH5Desktop;

      @include media-breakpoint-down(sm) {
        @include HeadingH5Mobile;
      }
    }

    &-text {
      text-align: center;

      @include BodyTextSm;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.12px;
      }
    }
  }

  &__tick {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    overflow: hidden;

    @include transition;

    .zmdi {
      color: $text--white;
      display: none;
    }
  }

  &__button {
    width: 100%;
  }

  &__bottom {
    width: $container-width--wide;
    gap: 20px;
    padding-block-start: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__caption {
    color: rgba(0, 0, 0, 0.5);

    @include BodyText;
  }

  &__pattern {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    translate: 0 0 50px;
  }
}

/*=====  End of Register Component  ======*/