/*=============================================
=            Organisation Details Not Found Component            =
=============================================*/

.org-not-found {
  display: flex;
  gap: 80px;
  position: relative;

  @include media-breakpoint-down(xl) {
    gap: 60px;
  }

  &__title {
    @include HeadingH2Desktop; 

    @include media-breakpoint-down(md) {
      @include HeadingH1Mobile; 
    }
  }

  &__content {
    color: rgba(0, 0, 0, 0.80);

    @include BodyText;
  }

  &__left, &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.78;
    gap: 20px;
    padding: 80px 0 80px 80px;
    margin-inline-start: calc((100vw - $container-width--default) / 2);
    max-width: calc(560px + 80px);
    width: calc(100% + 80px);
    max-width: 640px;
    min-height: 657px;

    @include media-breakpoint-up(wide) {
      flex: 1;
    }

    @include media-breakpoint-down(desktop) {
      margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
      flex: 1;
      padding-right: 80px;
      margin: auto;
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 49px 30px;
      min-height: auto;
    }
  }
  
  &__right {
    flex: 1;
    position: relative;
    background-color: $bg--light-blue;
    min-height: 820px;
    transform-style: preserve-3d;
    perspective: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      display: none
    }
  }

  &__inner {
    gap: 20px;
    margin: auto 0;
  }

  &__bottom {
    width: $container-width--wide;
    gap: 20px;
  }

  &__button {
    width: 100%;
  }

  &__pattern {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    translate: 0 0 50px;
  } 

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    z-index: 1;
    perspective: 10px;
    object-fit: cover;
    translate: 0 0 50px;

    @include transition;

    @include media-breakpoint-up(wide) {
      width: auto;
    }
  }

  &__text {
    color: $text--red;

    @include BodyText;

    @include media-breakpoint-down(md) {
      @include BodyTextSm;
    }

    .zmdi {
      margin-inline-end: 10px;
    }
  }
}

/*=====  End of Organisation Details Not Found Component  ======*/