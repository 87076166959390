/*=============================================
=            Individual Sign In Details            =
=============================================*/

.sign-in-details {
    display: flex;
    gap: 80px;
    position: relative;

    @include media-breakpoint-down(xl) {
        gap: 60px;
    }

    &__title {
        @include HeadingH3Desktop;

        @include media-breakpoint-down(md) {
            @include HeadingH1Mobile;
        }
    }

    &__content {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__left {
        flex: 0.78;
        gap: 20px;
        padding: 40px 0 80px 80px;
        margin-left: calc((100vw - $container-width--default) / 2);
        max-width: calc(560px + 80px);
        width: calc(100% + 80px);
        max-width: 640px;

        @include media-breakpoint-up(wide) {
            flex: 1;
        }

        @include media-breakpoint-down(desktop) {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
            padding-right: 80px;
            margin: auto;
            max-width: none;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
        }
    }

    &__right {
        flex: 1;
        position: relative;
        background-color: $bg--light-blue;
        min-height: 820px;
        transform-style: preserve-3d;
        perspective: 1000px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__back.link {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        aspect-ratio: 1/1;
        padding: 0;
        gap: 0;
    }

    &__form,
    &__inputs,
    &__bottom {
        width: $container-width--wide;
        gap: 20px;
    }

    &__form {
        input[type='text'],
        input[type='password'] {
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            outline-color: $black;
            width: $container-width--wide;
            padding: 10px 35px 10px 20px;
            background-color: $bg--white;
            max-width: none !important;
            margin: 0 !important;

            @include BodyText;

            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }

        .zmdi {
            &:hover {
                opacity: 0.7;
            }

            &-eye {
                display: none;

                &--active {
                    display: block;
                }
            }

            &-eye-off {
                display: none;

                &--active {
                    display: block;
                }
            }
        }
    }

    &__password {
        &-icon {
            position: absolute;
            bottom: 50%;
            right: 20px;
            z-index: 1;
            transform: translateY(50%);
            cursor: pointer;

            @include transition;
        }
    }

    &__box {
        position: relative;
    }

    &__button {
        width: 100%;
    }

    &__field {
        gap: 5px;
    }

    &__label {
        @include Label;
    }

    &__validation {
        color: $text--red;
        display: none;

        @include BodyTextSm;

        &--active {
            display: block;
        }
    }

    &__criterias {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px;
        gap: 10px;
        width: 100%;

        &-text {
            @include BodyTextSm;
        }
    }

    &__list {
        gap: 10px;
    }

    &__item {
        gap: 5px;
        height: 100%;

        &--active {
            .sign-in-details__item-icon {
                border-color: $dark-blue;
                color: $text--dark-blue;

                .zmdi {
                    opacity: 1;
                    color: $text--dark-blue;
                }
            }

            .rte {
                color: rgba(0, 0, 0, 0.3);
            }
        }

        .rte {
            height: 100%;

            @include transition;
        }

        &-icon {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            border: 1px solid $black;
            font-size: 12px;

            .zmdi {
                opacity: 0;

                @include transition;
            }
        }
    }

    &__bottom {
        padding-block-start: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__caption {
        color: rgba(0, 0, 0, 0.5);

        @include BodyText;
    }

    &__pattern {
        position: absolute;
        top: 0;
        left: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        object-fit: cover;
        translate: 0 0 50px;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        z-index: 1;
        perspective: 10px;
        object-fit: cover;
        translate: 0 0 50px;

        @include transition;
    }

    &-button {
        padding: 10px;
        background-color: $dark-blue;
        color: $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        width: fit-content;
        border: 1px solid transparent;
        width: 38px;
        @include transition;

        &:hover {
            background-color: $white;
            color: $dark-blue;
            border: 1px solid $dark-blue;
        }
    }
}

/*=====  End of Individual Sign In Details  ======*/
