/*=============================================
=            Platform Navbar            =
=============================================*/

.navbar-platform {
    position: relative;
    background-color: $bg--dark-blue;
    color: $text--white;
    padding: 20px 0;
    max-height: $navbar-height--desktop;
    height: $navbar-height--desktop;

    @include media-breakpoint-down(xl) {
        padding: 15px 0;
        max-height: $navbar-height--mobile;
        height: $navbar-height--mobile;
        position: fixed;
        top: 0;
        left: 0;
        width: $container-width--wide;
        z-index: 99;
    }

    &__inner {
        margin: 0 auto;
        padding: 0 60px;
        max-width: $container-width--default;
        width: $container-width--wide;

        @include media-breakpoint-down(xl) {
            transform: none;
        }

        @include media-breakpoint-down(lg) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 20px;
        }
    }

    &__logo {
        display: block;
        max-height: 24px;
        width: auto;

        &-container {
            min-width: fit-content;
            margin-inline-end: auto;

            @include media-breakpoint-down(xl) {
                margin-inline-end: 0;
            }
        }
    }

    &__avatar {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &--default {
            padding: 10px;
        }

        &-container {
            position: relative;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            height: 40px;
            width: 40px;
            min-height: 40px;
            min-width: 40px;
            background-color: $bg--white;
            margin: 0 5px;
            overflow: hidden;

            @include transition;

            &:hover,
            &:focus {
                border-color: $light-blue;
                border-color: $black;
            }
        }

        &-number {
            position: absolute;
            top: -7px;
            right: -7px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: $bg--black;
            color: $text--white;

            @include BodyTextSm;
        }
    }

    &__links {
        gap: 30px;

        @include media-breakpoint-down(desktop) {
            gap: 15px;
        }

        &-item {
            height: $container-height--wide;
            min-width: fit-content;

            &:last-of-type {
                position: relative;
                padding-right: 30px;

                @include media-breakpoint-down(desktop) {
                    padding-right: 15px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: -8px;
                    height: 38px;
                    width: 1px;
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

    &__login {
        @include Button;
        border-bottom: 1px solid transparent;
        border-radius: 0;

        &:hover,
        &:focus {
            color: $text--white;
            border-color: $white;
        }
    }

    &__link {
        border: 1px solid $white;
        position: relative;

        &:hover,
        &:focus {
            background-color: $bg--dark-blue;
            color: $text--white;
        }

        &::before {
            content: '';
            position: absolute;
            left: -30px;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: $bg--white;
            opacity: 0.2;
            pointer-events: none;
        }
    }

    &__login,
    &__link {
        @include media-breakpoint-down(xl) {
            display: none;
        }
    }
}

/*=====  End of Platform Navbar  ======*/
