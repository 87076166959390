/*=============================================
=            Dashboard Unverified            =
=============================================*/

.dashboard-unverified {
    position: relative;
    min-height: 850px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        min-height: auto;
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        object-fit: cover;
    }

    &__title {
        width: 100%;
        padding-block: 0 30px;
        padding-inline: 80px;

        @include HeadingH3Desktop;

        @include media-breakpoint-down(xl) {
            padding-inline: 30px 80px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__left,
    &__right {
        width: $container-width--wide;
        flex: 1;
    }

    &__right {
        padding: 40px 0 50px 0;
        margin-inline-end: calc((100vw - $container-width--default) / 2);
        overflow: hidden;
        max-width: 720px;

        @include media-breakpoint-down(desktop) {
            margin-inline-end: auto;
        }

        @include media-breakpoint-down(md) {
            max-width: none;
            padding-block: 20px 0;
        }
    }

    &__left {
        flex: 1;
        position: relative;
        background-image: url('/assets/img/img/dashboard-image.svg');
        background-color: $bg--light-blue;
        background-position-x: 84px;
        background-size: auto;
        background-repeat: no-repeat;
        min-width: 400px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            min-height: 400px;
            min-width: auto;
        }

        @include media-breakpoint-down(sm) {
            background-size: 130%;
            background-position-y: 129px;
            background-position-x: 31px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0.4),
                rgba(255, 255, 255, 0.3),
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0)
            );
        }

        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            max-width: 720px;
            width: 100%;
            height: 100%;
            gap: 30px;
        }
    }

    &__box {
        width: 300px;
        padding: 38px 10px;
        gap: 20px;
        border-radius: 10px;
        backdrop-filter: blur(10px);
        position: relative;
        overflow: hidden;
        box-shadow: inset 0 -1px 0 #0497b1, inset 1px 0 0 #0497b1, inset 0 1px 0 #0497b1, inset -1px 0 0 #0497b1,
            0px 4px 20px 0px rgba(0, 0, 0, 0.15);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0.9),
                rgba(255, 255, 255, 0.6),
                rgba(255, 255, 255, 0)
            );
        }

        &-title,
        &-text,
        .link {
            z-index: 1;
            text-align: center;
        }

        &-title {
            @include HeadingH3Mobile;
        }

        &-text {
            @include BodyText;
        }
    }

    &__links {
        gap: 30px;
        overflow: hidden;
        overflow-x: auto;
        padding-block-end: 20px;
        margin-block-end: 20px;
        min-height: 62px;
        width: 100%;
        max-width: 540px;
        cursor: grab;

        &:hover {
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.5);
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-block-end: 0;
            gap: 0;
            min-height: 0;
            max-width: none;
        }

        &::-webkit-scrollbar {
            height: 4px;

            @include media-breakpoint-down(md) {
                height: 0;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }

        &-container {
            overflow: hidden;
            padding-inline: 80px;
            margin-block-start: 30px;
            width: 100%;

            @include media-breakpoint-down(xl) {
                padding-inline: 30px 80px;
            }

            @include media-breakpoint-down(md) {
                padding-inline-end: 0;
                margin-block-start: 0;
            }
        }

        &-title {
            padding-inline: 80px;

            @include BodyText;

            @include media-breakpoint-down(xl) {
                padding-inline: 30px 80px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-list {
            gap: 5.5px;
            min-height: 42px;
            margin-inline-start: 1px;

            @include media-breakpoint-down(md) {
                padding-inline-end: 20px;
            }
        }

        .link.dashboard-unverified__links-link {
            gap: 9.5px;
            margin-inline-start: 0.5px;
            width: max-content;
        }

        &-icon {
            width: auto;
            margin-inline-start: 8px;

            @include transition;
        }
    }

    &__contacts {
        position: relative;
        width: 100%;
        padding-inline-start: 80px;

        @include media-breakpoint-down(xl) {
            padding-inline-start: 30px;
        }

        @include media-breakpoint-down(md) {
            padding-inline: 0;
            padding-block-end: 20px;
            margin-block-end: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-header {
            width: 100%;
            gap: 10px;
            padding-inline-end: 80px;
            padding-block: 40px 30px;
            max-width: 640px;

            @include media-breakpoint-down(lg) {
                padding-inline-end: 59px;
            }

            @include media-breakpoint-down(md) {
                padding-inline: 20px;
                padding-block: 20px;
                max-width: none;

                .link {
                    display: none;
                }
            }
        }

        &-title {
            @include BodyText;

            @include media-breakpoint-down(md) {
                max-width: 70%;
            }
        }

        &-list {
            position: relative;
            gap: 10px;
            padding-inline-end: 40px;
            margin-inline-end: 40px;
            max-height: 450px;
            overflow-y: auto;

            @include media-breakpoint-down(lg) {
                padding-inline-end: 15px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &::after {
                content: '';
                position: sticky;
                bottom: 0;
                left: 0;
                height: 100px;
                min-height: 100px;
                width: 100%;
                pointer-events: none;
                background-image: linear-gradient(
                    to top,
                    rgba(255, 255, 255, 0.8),
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0)
                );
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background: $black;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &__mobile-link {
        width: calc(100% - 40px);
        margin-inline: auto;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__skeleton {
        background-color: rgba(0, 166, 195, 0.05);
        height: 90px;
        width: 100%;
        border-radius: 10px;

        @include media-breakpoint-down(md) {
            &:nth-of-type(2),
            &:nth-of-type(3) {
                display: none;
            }
        }

        &-container {
            padding-inline-end: 80px;
            gap: 10px;

            @include media-breakpoint-down(lg) {
                padding-inline-end: 40px;
            }

            @include media-breakpoint-down(md) {
                padding-inline: 20px;
            }
        }
    }

    // Glide Carousels
    &__carousel {
        @include media-breakpoint-up(md) {
            display: none;
        }

        &-inner {
            padding: 0 20px;
            overflow: hidden;
        }
    }

    &__range {
        height: 2px;
        width: 94px !important;
        background-color: rgba(0, 0, 0, 0.1);
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        &-container {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .glide__bullets {
        gap: 10px;
        margin-block-start: 27px;
    }

    .glide__bullet {
        height: 10px;
        width: 10px;
        background-color: $bg--white;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.3;

        @include transition;

        &--active {
            opacity: 1;
        }
    }
}

/*=====  End of Dashboard Unverified  ======*/
