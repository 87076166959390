/*=============================================
=            Transfer Summary Component            =
=============================================*/

.transfer-summary {
  display: flex;
  padding: 0;
  position: relative;
  max-width: $container-width--default;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__heading {
    @include BodyTextBold;
    color: $text--dark-blue;
  }

  &__wrapper {
    display: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(0, 166, 195, 0.05) 50%);

    @include media-breakpoint-down(md) {
      background-image: none;
    }

    &--active {
      display: block;
    }
  }

  &__divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.10);
    width: 100%;
  }

  &__back {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  &__title {
    margin: auto;

    @include HeadingH4Desktop; 
    
    @include media-breakpoint-down(md) {
      @include BodyText;
    }
  }

  &__top {
    position: relative;
    padding: 30px 80px;
    background-color: $bg--white;
    max-width: $container-width--default;
    margin: 0 auto;
    
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }

  &__left, &__right {
    width: $container-width--wide;
    max-height: 750px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__left {
    display: none;
    flex: 1;
    gap: 20px;
    margin-block: 40px 0;
    padding-block-end: 40px;
    padding-inline: 80px 36px;
    margin-inline-end: 40px;
    min-height: 670px;
    max-height: 670px;
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-down(lg) {
      padding-inline: 20px 20px;
      margin-inline-end: 0;
    }

    @include media-breakpoint-down(md) {
      margin-block: 20px 40px;
      padding-block-end: 0;
      padding-inline: 0;
      margin-block-end: 0;
      min-height: auto;
      max-height: none;
    }

    &--active {
      display: flex;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.10);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $black;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.50);
    }

    &-top {
      gap: 20px;
    }

    hr {
      background-color: rgba(0, 0, 0, 0.10);
      width: 100%;
      height: 1px;
      min-height: 1px;
    }

    .link {
      @include BodyText;

      @include media-breakpoint-down(md) {
        @include BodyTextSm;
      }
    }
  }
  
  &__right {
    flex: 1;
    gap: 20px;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.10);
    padding-inline: 80px;
    padding-block: 40px;
    z-index: 1;
    display: none;

    &--active {
      display: flex;
    }
    
    @include media-breakpoint-down(lg) {
      padding-inline: 20px;
    }

    @include media-breakpoint-down(md) {
      border-left: none;
      padding-block: 20px 40px;
      background-color: rgba(0, 166, 195, 0.05);
    }

    &-title {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      padding-block-end: 20px;

      @include BodyTextBold;
    }
  }

  &__subtitle {
    padding-block-end: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);

    @include BodyTextBold;

    @include media-breakpoint-down(md) {
      padding-inline: 20px;
    }

    &--bank {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__list {
    gap: 20px;

    @include media-breakpoint-down(md) {
      padding-inline: 20px;
    }
  }

  &__item {
    gap: 20px;
    
    @include BodyText;
  }

  &__bottom {
    padding-block-start: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.10);

    @include media-breakpoint-down(md) {
      padding-inline: 20px;
    }

    &-title {
      @include BodyText;
    }

    &-text {
      @include BodyTextBold;
    }
  }

  &__button {
    display: none;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: flex;
      
      &-container {
        background-color: rgba(0, 166, 195, 0.05);
        padding: 20px 20px 40px;
      }
    }
  }

  &__form {
    width: 100%;
    gap: 20px;

    input[type="text"] {
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      outline-color: $black;
      width: $container-width--wide;
      padding: 10px 20px;
      background-color: $bg--white;
      height: 40px;
      
      @include Input;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .button {
      width: 100%;
    }
  }

  &__field {
    gap: 5px;
    width: 100%;
  }

  &__label {
    @include Label;
  }
}

/*=====  End of Transfer Summary Component  ======*/