/*===================================================================
=            Feature With Text           =
=====================================================================*/

.feature-with-text {
  &__wrapper {
    position: relative;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 30px;
  }

  &__left,
  &__right {
    flex: 1;
    gap: 20px;
  }

  &__inner {
    gap: 120px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &__left {
    @include media-breakpoint-down(lg) {
      gap: 30px;
    }

    .rte {
      padding-top: 20px;
      border-top: 1px solid $light-blue;

      @include media-breakpoint-down(lg) {
        padding-top: 30px;
      }
    }
  }

  &__right {
    min-width: 600px;

    @include media-breakpoint-down(desktop) {
      min-width: 400px;
    }

    @include media-breakpoint-down(sm) {
      min-width: $container-width--wide;
    }
  }

  &__title {
    @include HeadingH1Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH1Mobile;
    }
  }

  &__subtitle {
    width: $container-width--wide;
    color: $text--light-blue;

    @include HeadingH4Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH4Mobile;
    }
  }
}

/*=====  End of Feature With Text  ======*/