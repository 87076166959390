.news-hero {
  width: 100%;
  position: relative;
  gap: 120px;

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-container {
      border-radius: 10px;
      height: 360px;
      overflow: hidden;
      width: 100%;
      flex: 1;
    }
  }

  &__video {
    width: $container-width--wide;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    width: 100%;
    flex: 0.8;
  }

  &__title {
    @include HeadingH4Desktop;
    color: $light-blue;

    &-container {
      padding-block: 20px;
      margin-block-end: 20px;
      border-bottom: 1px solid $light-blue;
      gap: 10px;
    }

    &-feature {
      @include HeadingH2Desktop;
    }
  }

  &__date {
    @include BodyText;
    opacity: 0.5;

    &-label {
      @include BodyTextSm;
      opacity: 0.3;
    }

    &-container {
      gap: 20px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .news-hero {
    gap: 60px;
  }
}

@include media-breakpoint-down(lg) {
  .news-hero {
    padding: 30px;
  }
}

@include media-breakpoint-down(md) {
  .news-hero {
    flex-direction: column-reverse;
    gap: 30px;

    &__title {
      @include HeadingH4Mobile;

      &-feature {
        @include HeadingH2Mobile;
      }
    }

    &__content {
      width: 100%;
    }

    &__image {
      &-container {
        flex: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .news-hero {
    &__image {
      &-container {
        height: 220px;
      }
    }
  }
}
