/*=============================================
=            Dashboard Payment Card            =
=============================================*/

.dash-card {
    border-radius: 10px;
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
    overflow: clip;
    cursor: pointer;
    box-shadow: inset 0 -1px 0 #0497b1, inset 1px 0 0 #0497b1, inset 0 1px 0 #0497b1, inset -1px 0 0 #0497b1,
        0px 4px 20px 0px rgba(0, 0, 0, 0.15);

    &:hover,
    &:focus {
        .dash-card__bottom {
            outline-color: $black;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0)
        );
    }

    &__inner {
        width: 100%;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &-placeholder {
            object-fit: contain;
            padding: 11px;
        }

        &-container {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            height: 40px;
            width: 40px;
        }
    }

    &__value {
        @include HeadingH3Mobile;
    }

    &__currency {
        @include BodyTextSm;
    }

    &__info {
        flex: 1;
    }

    &__top {
        gap: 5px;
        background-color: $bg--white;
        padding: 15px;
        z-index: 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: calc(100% + -2px);
        margin-block-start: 1px;
        margin-inline-start: 1px;
    }

    &__middle {
        margin-block: 20px 15px;
        margin-inline: 15px;
        margin-block-end: auto;
        z-index: 1;
    }

    &__bottom {
        gap: 10px;
        padding: 10px;
        padding-inline-end: 15px;
        background-color: $bg--white;
        border-radius: 10px;
        margin-block: 15px 20px;
        margin-inline: 15px;
        outline: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
        z-index: 1;

        @include transition;

        &:hover,
        &:focus {
            outline-color: $black;
        }
    }

    &__amount {
        flex: 1;
    }

    &__type {
        gap: 5px;

        &-title {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextSm;
        }

        &-text {
            @include BodyText;
        }
    }

    &__date {
        &-title {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextSm;
        }

        &-text {
            @include BodyText;
        }
    }

    &__info {
        gap: 5px;

        &-title {
            @include BodyText;
        }

        &-text {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextSm;
        }
    }

    &__status {
        gap: 5px;

        @include BodyTextTiny;

        .zmdi {
            font-size: 11px;
        }

        &-verified {
            color: $text--green;
        }

        &-pending {
            color: $text--light-blue;
        }

        &-unverified {
            color: $text--red;
        }
    }

    &__pill {
        padding: 2px 10px;
        border-radius: 10px;
        border: 1px solid;

        @include BodyTextTiny;

        &-gold {
            border-color: $gold;
            color: $text--gold;
        }

        &-royal {
            border-color: $dark-blue;
            color: $text--dark-blue;
        }

        &-sky {
            border-color: $light-blue;
            color: $text--light-blue;
        }
    }
}

/*=====  End of Dashboard Payment Card  ======*/
