.rte {

    &--white {
        color: $text--white;
    }

    &__title {
        margin-bottom: 10px;
    }

    &__subtitle {        
        margin-bottom: 56px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        display: block;
        margin-bottom: 20px;
    }

    p,
    ol,
    ul {
        display: block;
        margin-bottom: 5px;
    }

    ul,
    ol,
    li {
        margin-left: 16px;
    }

    ul,
    ol {
        margin-top: 20px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: 700;
    }

    a.underline {
        text-decoration: underline;
    }

    // Font Stlyes
    h1 {
        @include HeadingH1Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH1Mobile;
        }
    }

    h2 {
        @include HeadingH2Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH2Mobile;
        }
    }

    h3 {
        @include HeadingH3Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH3Mobile;
        }
    }

    h4 {
        @include HeadingH4Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH4Mobile;
        }
    }

    h5 {
        @include HeadingH5Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH5Mobile;
        }
    }

    h6 {
        @include HeadingH6Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH6Mobile;
        }
    }

    p, li {
        @include BodyText;
    }

    a {
        @include Button;
    }

    p.p--small {
        @include BodyTextSm;
    }

    p strong {
        @include BodyTextSm;
        
        font-weight: $font-weight--bold;
    }
}

.rte div > :only-child {
    margin-bottom: 0;
}

.rte div > :last-child {
    margin-bottom: 0;
}

/*=====  End of RTE  ======*/