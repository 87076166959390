/*=============================================
=            Team Grid            =
=============================================*/

.team-grid {
    position: relative;
    overflow: hidden;

    & + .team-grid {
        margin-top: -30px;

        .team-grid__inner {
            padding-top: 0;
        }
    }

    &__inner {
        gap: 30px;
        overflow: hidden;
    }

    &__header {
        width: $container-width--wide;
        gap: 20px;
    }

    &__heading {
        @include HeadingH2Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH2Mobile;
        }
    }

    &__text {
        @include BodyText;
    }

    &__grid {
        display: grid;
        width: $container-width--wide;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        gap: 12.5px;

        @include transition;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        }

        @include media-breakpoint-down(sm) {
            gap: 30px;
            display: none;
        }
    }

    &__carousel {
        position: relative;
        width: $container-width--wide;
        margin-bottom: 32px;

        @include media-breakpoint-up(sm) {
            display: none;
        }

        &-range {
            height: 2px;
            width: 300px !important;
            max-width: 300px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            cursor: pointer;
            overflow: hidden;

            @include transition;

            &-container {
                position: absolute;
                top: auto;
                top: 100%;
                transform: translateY(15px);
                left: 0;
                width: $container-width--wide;
            }
        }
    }

    &__card {
        gap: 10px;
        cursor: pointer;
        overflow: hidden;

        &:hover,
        &:focus {
            .team-grid__card-image {
                transform: scale(1.02);
            }

            .team-grid__card-button {
                color: $bg--black;
            }
        }

        &-top {
            border-radius: 10px;
            height: 230px;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                height: 145px;
            }
        }

        &-image {
            height: $container-height--wide;
            width: $container-width--wide;
            object-fit: cover;

            @include transition;
        }

        &-title {
            @include HeadingH5Mobile;

            @include media-breakpoint-down(sm) {
                @include BodyTextBold;
            }
        }

        &-text {
            @include BodyText;

            @include media-breakpoint-down(sm) {
                @include BodyTextSm;
            }
        }

        &-button {
            padding-block-start: 10px;
            padding-block-end: 10px;
            border-radius: 0;
        }
    }

    &__banner {
        max-width: 621px;
        gap: 30px;

        &-image {
            width: 231px;
            object-fit: contain;
        }

        &-right {
            max-width: 360px;

            @include BodyText;
        }
    }

    &__modal {
        height: 100vh !important;
        width: 100vw !important;
        margin: 0 !important;
        outline: none;
        background-color: rgba(0, 0, 0, 0.3);

        &:modal {
            max-width: 100vw;
            max-height: 100vh;
        }

        &-inner {
            background-color: $bg--white;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(calc(-50% + -8px), -50%);
            border-radius: 10px;
            height: 500px;
            width: 80%;
            max-width: $container-width--xl;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                height: 80%;
                max-height: 80%;
            }
        }

        &-left,
        &-right {
            height: auto;
        }

        &-right {
            flex: 1;
            padding: 20px;
            gap: 20px;
            overflow: hidden;
        }

        &-left {
            flex: 0.5;
            min-width: 300px;

            @include media-breakpoint-down(md) {
                flex: none;
                height: $container-height--wide;
                width: $container-width--wide;
                height: 300px;
            }

            @include media-breakpoint-down(sm) {
                height: 250px;
            }
        }

        &-image {
            height: $container-height--wide;
            width: $container-width--wide;
            object-fit: cover;
            vertical-align: middle;
        }

        &-top {
            gap: 20px;
        }

        &-main {
            width: $container-width--wide;
        }

        &-title {
            @include HeadingH4Desktop;
        }

        &-text {
            @include HeadingH5Desktop;
        }

        &-buttons {
            gap: 10px;
            margin-top: auto;
        }

        &-close {
            @include media-breakpoint-down(md) {
                position: absolute;
                bottom: 25px;
                right: 20px;
            }
        }

        .rte {
            overflow: auto;
            height: $container-height--wide;
        }
    }
}

/*=====  End of Team Grid  ======*/
