/*===================================================================
=            404 Component            =
=====================================================================*/

.not-found {
  padding: 60px 240px;
  gap: 30px;
  min-height: 600px;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding: 60px 30px;
    min-height: 500px;
  }

  &__title {
    @include HeadingH1Desktop;

    @include media-breakpoint-down(md) {
      @include HeadingH1Mobile;
    }
  }

  &__text {
    @include BodyText;
  }
}

/*=====  End of 404 Component  ======*/