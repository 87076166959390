.news-listing {
    gap: 30px;
    position: relative;

    &__grid {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px 60px;
    }
    
    &__card {
        gap: 20px;

        &-image {
            border-radius: 10px;
            width: 100%;
            height: 240px;
            object-fit: cover;
        }

        &-title {
            @include HeadingH5Desktop;
        }

        &-read {
            @include BodyText;
            opacity: .3;
        }
    }

    &__button {
        @include Button;
        @include transition;
        color: $dark-blue;
        gap: 8px;
        cursor: pointer;

        &:hover {
            color: $black;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .3;
            color: #000;
        }
    }
}

@include media-breakpoint-down(lg) {
    .news-listing {
        &__grid {
            grid-template-columns: repeat(1, 1fr);
            column-gap: 20px;
        }

        &__card {
            &-title {
                @include HeadingH4Mobile;
            }

            &-image {
                height: 225px;
            }

            &-bottom {
                flex-direction: row-reverse;
                justify-content: start;
                gap: 20px;
            }

            &-read {
                @include BodyTextSm;
            }
        }
    }
}