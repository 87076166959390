.flex {
  display: flex;
  flex-wrap: nowrap;

  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--justify-space-around {
    justify-content: space-around;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--row {
    flex-direction: row;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--column {
    flex-direction: column;
  }
}
