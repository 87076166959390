/*=============================================
=            Menu Mobile            =
=============================================*/

.menu-mobile {
  &__icon-container {
    z-index: 99999999;
    padding: 8px 0px 17px 8px;
    position: relative;
    cursor: pointer;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__icon {
    width: 20px;
    height: 7px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    cursor: pointer;
    z-index: 9999999;
    height: 100%;

    &-bar {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: $bg--black;
      border-radius: 9px;
      border-radius: 12px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
    }

    &-bar:nth-child(1) {
      top: 0px;
      right: 0;
      left: auto;
      -webkit-transform-origin: right center;
      -moz-transform-origin: right center;
      -o-transform-origin: right center;
      transform-origin: right center;
    }

    &-bar:nth-child(2) {
      top: 8px;
      right: 0;
      left: auto;
      -webkit-transform-origin: right center;
      -moz-transform-origin: right center;
      -o-transform-origin: right center;
      transform-origin: right center;
      width: 80%;
    }

    &--open .menu-mobile__icon-bar:nth-child(1) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: -2px;
      left: auto;
      right: auto;
      width: 20px;
    }

    &--open .menu-mobile__icon-bar:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 12.5px;
      left: auto;
      width: 20px;
    }
  }

  &__overlay {
    position: fixed;
    top: 70px;
    right: 0;
    width: 50%;
    width: 100%;
    height: calc($container-width--wide - 71px);
    z-index: 102;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.10);
    color: $text--dark-blue;

    @include transitionSlow;

    @include media-breakpoint-up(xl) {
      display: none;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__inner {
    z-index: 101;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $bg--white;
    height: calc($container-height--wide + 1px);
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 70%;
    }

    @include media-breakpoint-down(sm) {
      width: $container-width--wide;
    }
  }

  &__nested {
    z-index: 102;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $bg--dark-blue;
    color: $bg--white;
    height: calc($container-height--wide + 1px);
    width: 50%;
    padding: 30px;
    display: none;

    &--active {
      display: block;
    }

    @include media-breakpoint-down(md) {
      width: 70%;
    }

    @include media-breakpoint-down(sm) {
      width: $container-width--wide;
    }

    &-top {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    
    &-subtitle {
      @include HeadingH2Mobile;
    }

    &-title {
      @include BodyTextBold;
    }

    &-list {
      width: $container-width--wide;
      gap: 20px;

      &-item {
        width: $container-width--wide;
        padding-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.10);

        &:last-child {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        }
      }
    }

    &-bottom {
      gap: 20px;
    }

    &-link {
      @include transition;

      &:hover, &:focus {
        color: $text--black;
      }

      &-inner {
        gap: 5px;
      }

      &-title {
        @include HeadingH4Mobile;
      }

      &-subtitle {
        @include BodyText;
      }
    }
  }

  &__top {
    background: linear-gradient(180deg, #5ADDF4 0%, #00A6C3 100%);
    padding: 30px;
    gap: 20px;

    &-title {
      @include HeadingH4Mobile;
    }

    &-links {
      width: $container-width--wide;
      gap: 10px;
    }

    .link {
      width: $container-width--wide;
      padding: 10px 20px;
    }
  }

  &__bottom {
    gap: 15px;
    padding: 30px;
    height: calc($container-height--wide - 145.5px);
    overflow: auto;

    .link {
      justify-content: flex-start;
      width: $container-width--wide;

      @include HeadingH5Desktop;
    }
  }

  &__item {
    width: $container-width--wide;
    position: relative;
    padding-bottom: 15px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: $container-width--wide;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.10);
    }

    &:nth-last-child(2) {
      &::after {
        content: normal;
      }
    }
  }

  &__socials {
    gap: 30px;
    width: $container-width--wide;

    &-container {
      margin-top: auto;
    }
  }

  &__social {
    font-size: 22px;

    &-link {
      height: 20px;
      width: 20px;

      &:hover, &:focus {
        color: $black;
      }
    }
  }

  &__button {    
    width: $container-width--wide;
    justify-content: flex-start;

    @include HeadingH5Desktop;
  }
}

/*=====  End of Menu Mobile  ======*/
