/*=============================================
=            Send Funds            =
=============================================*/

.send-funds {
  &__overlay {
    position: fixed;
    top: 70px;
    right: 0;
    width: $container-width--wide;
    height: calc($container-height--wide - 71px);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.10);
    color: $text--white;

    @include transitionSlow;

    @include media-breakpoint-up(xl) {
      top: 80px;
      height: calc($container-height--wide - 80px);
    }

    @include media-breakpoint-down(sm){
      transition: none;
    }

    &--active {
      opacity: 1;
      visibility: visible;
      transition: all cubic-bezier(0.2, 0, 0.2, 1) 0.5s;
    }
  }

  &__inner {
    z-index: 101;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $bg--dark-blue;
    height: calc($container-height--wide + 1px);
    width: 600px;
    padding: 30px 70px 30px 60px;
    border-top: 1px solid #236EAE;

    &--large-padding {
      padding: 30px 80px 30px 60px;
    }

    @include media-breakpoint-down(md) {
      width: $container-width--wide;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px;
    }

    @media (max-height: 650px) {
      .send-funds__main {
        justify-content: flex-start;
      }
    }
  }

  &__main, &__nested {
    height: $container-height--wide;
  }

  &__buttons {
    padding-bottom: 30px;
    padding-inline-end: 10px;
    width: $container-width--wide;

    &--border {
      padding-inline-end: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  
  &__close, &__close-mobile {
    height: 40px;
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin-left: auto;

    .zmdi {
      font-size: 18px;
    }
  }

  &__close {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    &-mobile {
      margin-right: auto;
      margin-left: 0;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  &__main {
    overflow-y: auto;

    &--hidden {
      display: none;
    }
  }

  &__nested {
    display: none;
    gap: 30px;
    overflow: auto;
    margin: auto;
    height: fit-content;
    padding-top: 30px;

    &-link {
        @include Button;

        margin: 10px 0;
    }

    &--active {
      display: flex;
    }
  }

  &__columns {
    background-color: $bg--white;
    color: $text--black;
    border-radius: 10px;
    min-height: 280px;
    width: $container-width--wide;

    @include media-breakpoint-down(sm) {
      min-height: 169px;
    }
  }

  &__column {
    text-align: center;
    gap: 20px;
    padding: 20px 15px;
    min-height: 169px;
    flex: 1;

    &:first-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.10);
      }
    }

    .link {
      padding: 10px 20px;
    }

    &-title {
      max-width: 160px;

      @include HeadingH5Desktop;

      @include media-breakpoint-down(sm) {
        @include BodyText;
      }
    }
  }

  &__title {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    
    @include HeadingH3Desktop;

    @include media-breakpoint-down(sm) {
      @include HeadingH2Mobile;
    }
  }

  &__card {
    position: relative;
    cursor: pointer;
    padding-right: 40px;
    gap: 5px;

    &:hover, &:focus {
      .send-funds__card-button {
        background-color: $bg--black;
        color: $text--white;
      }
    }

    &:first-child{
      margin-block-end: 30px;
      padding-block-end: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &-subtitle {
      @include BodyTextSm;
    }

    &-title {
      @include HeadingH4Desktop;

      @include media-breakpoint-down(sm) {
        @include HeadingH4Mobile;
      }
    }

    &-text {
      @include BodyText;
    }

    &-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 30px;
      min-width: 30px;
      max-height: 30px;
      min-height: 30px;
    }
  }

  &__back {
    color: $text--white;
    display: none;

    &--active {
      display: flex;
    }
  }

  &__share {    
    @include HeadingH5Mobile;

    font-size: $font-size--sm;
    display: none;

    &--active {
      display: flex;
    }

    .zmdi {
      height: 28px;
      width: 28px;
      font-size: 28px;
    }
  }

  &__bottom {
    width: $container-width--wide;
    padding-top: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .send-funds__inner {
    background-color: transparent;
    transition: none;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: $bg--dark-blue;
      height: 0;
      width: 0;
      bottom: -70%;
      left: -120%;          
      z-index: $below;
      transition: none;
    }
  }

  .send-funds__overlay--active {
    .send-funds__inner::before {
      content: "";
      position: absolute;
      border-radius: 0;
      border-radius: 50%;
      background-color: $bg--dark-blue;
      height: 1500px;
      width: 1500px;
      aspect-ratio: 1/1;
      bottom: -70%;
      left: -120%; 
      z-index: $below;
      transition: all cubic-bezier(0.2, 0, 0.2, 1) 0.5s;
    }
  }
}

/*=====  End of Send Funds  ======*/
