$black: #000;
$white: #fff;
$dark-blue: #0D518B;
$light-blue: #00A6C3;
$green: #3B952D;
$bright-green: #0BB826;
$gold: #B99300;
$red: #CD2A2A;

// Background Colours
$bg--black: $black;
$bg--white: $white;
$bg--dark-blue: $dark-blue;
$bg--light-blue: $light-blue;
$bg--green: $green;
$bg--bright-green: $bright-green;
$bg--gold: $gold;
$bg--red: $red;

// Text Colours
$text--black: $black;
$text--white: $white;
$text--dark-blue: $dark-blue;
$text--light-blue: $light-blue;
$text--green: $green;
$text--bright-green: $bright-green;
$text--gold: $gold;
$text--red: $red;

// Color Classes
.background--blue {
  background-color: rgba(0, 166, 195, 0.1);
}

.background--white {
  background-color: $bg--white;
}