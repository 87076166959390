/*===================================================================
=            Feature With Media           =
=====================================================================*/

.feature-with-media {
  @include media-breakpoint-down(lg) {
    padding: 40px 0;
  }

  &__wrapper {
    position: relative;
  }

  &__left,
  &__right {
    flex: 1;
    gap: 20px;
  }

  &__inner {
    gap: 120px;

    &.flex--row-reverse {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &__left {
    @include media-breakpoint-down(lg) {
      gap: 30px;
    }
  }

  &__right {
    position: relative;
    min-height: auto;
    overflow: hidden;
    min-width: 600px;

    @include media-breakpoint-down(desktop) {
      min-width: 400px;
    }

    @include media-breakpoint-down(sm) {
      min-width: $container-width--wide;
    }
  }

  &__title {
    @include HeadingH1Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH1Mobile;
    }
  }

  &__image {
    height: auto;
    width: $container-width--wide;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 10px;
    object-fit: contain;
    margin: auto;
  }

  &__subtitle {
    width: $container-width--wide;
    color: $text--light-blue;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-blue;

    @include HeadingH4Desktop;

    @include media-breakpoint-down(lg) {
      padding-bottom: 30px;

      @include HeadingH4Mobile;
    }
  }
}

/*=====  End of Feature With Media  ======*/
