.login-country {
    position: relative;
    gap: 80px;

    &__items {
        gap: 20px;

        &-inner {
            padding-right: 20px;
            gap: 10px;
            max-height: 363px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background: $black;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &__item {
        padding: 7.5px 10px;
        gap: 20px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        cursor: pointer;
        @include transition;

        &:hover {
            background-color: $bg--dark-blue;
            border: 1px solid $bg--dark-blue;
            color: $white;
        }

        &-image {
            width: 35px;
            height: 35px;
        }

        &-name {
            @include BodyText;
        }

        &--active {
            background-color: $bg--dark-blue;
            border: 1px solid $bg--dark-blue;
            color: $white;
        }
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__bottom {
        gap: 10px;
        opacity: 0.5;

        &-icon {
            font-size: 21px;
            transform: translateY(2%);
        }

        &-text {
            @include BodyText;
        }
    }

    &__left {
        flex: 0.78;
        gap: 20px;
        padding: 80px 0 80px 80px;
        margin-left: calc((100vw - $container-width--default) / 2);
        max-width: calc(560px + 80px);
        width: calc(100% + 80px);
        max-width: 640px;

        &-title {
            @include HeadingH2Desktop;

            @include media-breakpoint-down(md) {
                @include HeadingH1Mobile;
            }
        }

        @include media-breakpoint-up(wide) {
            flex: 1;
        }

        @include media-breakpoint-down(desktop) {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
            padding-right: 80px;
            margin: auto;
            max-width: none;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            padding: 49px 30px;
        }
    }

    &__right {
        flex: 1;
        position: relative;
        background-color: $bg--light-blue;
        min-height: 820px;
        transform-style: preserve-3d;
        perspective: 1000px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    .login-country {
        &__bottom {
            &-text {
                @include BodyTextSm;
                font-size: $font-size--xxxs;
            }
        }
    }
}
