/*=============================================
=            My Account Component            =
=============================================*/

.account {
    display: flex;
    padding: 0;
    position: relative;
    max-width: $container-width--default;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &__notify {
        position: absolute;
        top: 0;
        left: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 5px 10px;
        max-width: 250px;
        color: $text--white;
        text-align: center;
        z-index: $above;
        box-shadow: 0px 4px 15px 0px #0000001A;
        display: none;

        @include BodyTextSm;
        @include transition;

        @include media-breakpoint-down(md) {
            position: static;
            max-width: none;
            transform: none;
        }

        &--success {
            display: none;
            background-color: $bg--green;

            &.active {
                display: flex;
            }
        }

        &--fail {
            display: none;
            background-color: $bg--red;

            &.active {
                display: flex;
            }
        }
    }

    &__wrapper {
        display: none;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 50%, rgba(0, 166, 195, 0.05) 50%);

        @include media-breakpoint-down(md) {
            background-image: none;
        }

        &--active {
            display: block;
        }
    }

    &__divider {
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    &__title {
        margin: auto;

        @include HeadingH4Desktop;

        @include media-breakpoint-down(md) {
            @include BodyText;
        }
    }

    &__top {
        position: relative;
        padding: 30px 80px;
        background-color: $bg--white;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }
    }

    &__subtitle {
        @include BodyText;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }
    }

    &__left,
    &__right {
        width: $container-width--wide;
        flex: 1;
        padding-block: 80px;
        padding-inline: 80px;

        @include media-breakpoint-down(lg) {
            padding-inline: 30px;
        }

        @include media-breakpoint-down(md) {
            padding-block: 30px;
        }
    }

    &__left {
        gap: 20px;
        padding-block: 80px;
        padding-inline: 80px;
        text-align: center;
        min-height: 750px;
        overflow-y: auto;
        overflow-x: hidden;

        @include media-breakpoint-down(lg) {
            padding-inline: 30px;
        }

        @include media-breakpoint-down(md) {
            padding-block: 30px;
            min-height: auto;
            max-height: none;
            background-color: rgba(0, 166, 195, 0.05);
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }

        &-top {
            gap: 20px;
        }

        hr {
            background-color: rgba(0, 0, 0, 0.1);
            width: 100%;
            height: 1px;
            min-height: 1px;
        }

        .link {
            @include BodyText;

            @include media-breakpoint-down(md) {
                @include BodyTextSm;
            }
        }
    }

    &__right {
        gap: 20px;
        position: relative;
        border-left: 1px solid rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(md) {
            border-left: none;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &--default {
            padding: 40px;
        }

        &-container {
            height: 190px;
            width: 190px;
            border-radius: 50%;
            border: 1px solid $white;
            box-shadow: 0px 4px 15px 0px #0000001A;
            background-color: $bg--white;
            overflow: hidden;

            @include media-breakpoint-down(lg) {
                height: 140px;
                width: 140px;
            }
        }
    }

    &__name {
        @include HeadingH3Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH3Mobile;
        }
    }

    &__text {
        opacity: 0.5;

        @include BodyText;
    }

    &__button, &__upload {
        @include BodyTextSm;
    }

    &__subtitle {
        @include BodyTextBold;
    }

    &__items {
        width: 100%;
        gap: 20px;
    }

    &__item {
        width: 100%;
        gap: 10px;

        &-address {
            margin-block-end: 20px;
        }

        &-label {
            color: rgba(0, 0, 0, 0.5);
            padding-block-end: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            @include BodyTextSm;
        }

        &-left {
            @include BodyText;
        }

        &-right {
            opacity: 0.8;
            margin-inline-end: auto;

            .rte div {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }

    &__upload {
        &-form {
            gap: 5px;
            text-align: center;
        }

        &-input {
            position: absolute;
            opacity: 0;
            visibility: hidden;

            &:disabled+label p {
                cursor: not-allowed;
                opacity: 0.25;
            }
        }

        &-label {
            cursor: pointer;
        }

        &-placeholder {
            color: $text--dark-blue;

            @include BodyTextSm;
            @include transition;

            &:hover, &:focus {
                color: $text--black;
            }
        }
    }
}

/*=====  End of My Account Component  ======*/
