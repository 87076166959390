/*=============================================
=            Individual Authentication            =
=============================================*/

.individual-auth {
    width: $container-width--wide;
    max-width: $container-width--default;
    margin: 0 auto;

    &__wrapper {
        background: linear-gradient(to right, white 50%, rgba(0, 166, 195, 0.05) 50%);
        position: relative;

        @include media-breakpoint-down(lg) {
            background: none;
        }
    }

    &__title {
        @include HeadingH2Desktop;

        @include media-breakpoint-down(lg) {
            @include HeadingH1Mobile;
        }
    }

    &__content {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;
    }

    &__user {
        width: 100%;
        gap: 20px;
        padding-block-start: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        &-title {
            @include HeadingH4Mobile;
        }
    }

    &__avatar {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &-placeholder {
            object-fit: contain;
            padding: 11px;
            background-color: rgba(0, 166, 195, 0.05);
        }

        &-container {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            height: 40px;
            width: 40px;
            min-height: 40px;
            min-width: 40px;
            background-color: $bg--white;
        }
    }

    &__left,
    &__right {
        flex: 1;
        width: 100%;
        padding: 40px 80px 80px;
        gap: 20px;
    }

    &__right {
        @include transition;

        @include media-breakpoint-down(lg) {
            padding: 40px 80px 40px;
        }

        @include media-breakpoint-down(lg) {
            background-color: $bg--white;
            padding: 40px 80px 40px;
            opacity: 0;
            visibility: hidden;
            position: absolute;
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
        }

        &--active {
            position: static;
            opacity: 1;
            visibility: visible;
        }
    }

    &__left {
        z-index: 0;

        &--hidden {
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        @include media-breakpoint-down(lg) {
            padding: 40px 80px 40px;
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
        }
    }

    &__cards {
        gap: 10px;
        width: 100%;
    }

    &__proof {
        gap: 20px;
        height: 100%;
        z-index: $above;
        display: none;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
        }

        &--active {
            display: flex;
        }

        &-title {
            @include HeadingH1Mobile;
        }

        &-text {
            color: rgba(0, 0, 0, 0.8);

            @include BodyText;
        }

        &-validation {
            color: $text--green;
            gap: 10px;

            @include BodyTextSm;

            .zmdi {
                font-size: 15px;
            }
        }

        &-docs {
            gap: 20px;
            width: 100%;
            background-color: $bg--white;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 10px;
            cursor: pointer;

            @include transition;

            &:hover,
            &:focus {
                border-color: $light-blue;
            }

            &--active {
                border-color: $light-blue;
            }

            &-container {
                gap: 10px;
                width: 100%;
            }

            &-icon {
                height: 35px;
                width: 35px;
            }

            &-text {
                @include HeadingH5Mobile;
            }
        }

        &-caption {
            color: rgba(0, 0, 0, 0.5);

            @include BodyTextTiny;
        }
    }

    &__button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        aspect-ratio: 1/1;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__bottom {
        width: $container-width--wide;
        gap: 20px;
    }

    &__caption {
        color: rgba(0, 0, 0, 0.5);

        @include BodyTextTiny;
    }

    &__upload-button {
        width: 100%;
    }

    &__link {
        width: 100%;
        padding: 10px 0;

        &-top{
            align-self: end;
            width: auto;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
}

/*=====  End of Individual Authentication  ======*/
