/*=============================================
=            Link            =
=============================================*/

.link {
    cursor: pointer;
    padding: 10px 15px;
    gap: 10px;
    min-width: max-content;
    border-radius: 20px;

    @include transition;
    @include Button;

    &__image {
        width: 14px;
        height: 16px;
        object-fit: contain;

        @include transition;
    }

    &--primary {
        background-color: $bg--dark-blue;
        color: $text--white;

        &-white {
            background-color: $bg--white;
            color: $text--dark-blue;

            &-thin {
                border: 1px solid $dark-blue;
                color: $text--dark-blue;
                background-color: $bg--white;

                @include BodyTextSm;

                .zmdi {
                    font-size: $font-size--sm;
                }

                &:hover,
                &:focus {
                    border-color: $black;

                    .link__image {
                        filter: grayscale(1);
                    }
                }
            }

            &:hover,
            &:focus {
                color: $text--black;
            }
        }

        &:hover,
        &:focus {
            color: $text--white;
            background-color: $bg--black;
        }
    }

    &--simple {
        padding: 0;
        color: $text--dark-blue;
        border-radius: 0;

        &:hover,
        &:focus {
            color: $text--black;
        }

        &-white {
            padding: 0;
            color: $text--white;
            border-bottom: 1px solid transparent;
            border-radius: 0;

            @include BodyText;

            &:hover,
            &:focus {
                border-color: $white;
            }

            &.link--active {
                border-color: $white;
            }
        }
    }
}

/*=====  End of Link  ======*/
