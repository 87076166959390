/*=============================================
=            Organisation Profile Confirm Component            =
=============================================*/

.org-profile-confirm {
  display: flex;
  position: relative;

  &__title {
    @include HeadingH2Desktop; 

    @include media-breakpoint-down(md) {
      @include HeadingH1Mobile; 
    }
  }

  &__subtitle {
    color: rgba(0, 0, 0, 0.50);

    @include BodyTextSm;
  }

  &__content {
    color: $text--green;
    
    @include BodyTextSm;
    
    .zmdi {
      margin-inline-end: 10px;
    }
  }

  &__left, &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.78;
    gap: 20px;
    margin-block-start: 80px;
    padding-block: 0 80px;
    padding-inline: 80px 36px;
    margin-inline-end: 40px;
    margin-inline-start: calc((100vw - $container-width--default) / 2);
    max-width: calc(560px + 80px);
    width: calc(100% + 80px);
    max-width: 640px;
    max-height: 820px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.10);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $black;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.50);
    }

    @include media-breakpoint-up(wide) {
      flex: 1;
    }

    @include media-breakpoint-down(desktop) {
      margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
      flex: 1;
      max-width: none;
      margin-inline-end: 0;
      padding-inline-end: 80px;
      width: 100%;
      max-height: none;
    }

    @include media-breakpoint-down(md) {
      padding-block: 30px;
      margin: 0;
      padding-inline: 30px;
    }
  }
  
  &__right {
    flex: 1;
    position: relative;
    background-color: $bg--light-blue;
    min-height: 820px;
    transform-style: preserve-3d;
    perspective: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      display: none
    }
  }

  &__info {
    border: 1px solid rgba(0, 0, 0, 0.10);
    background-color: rgba(0, 166, 195, 0.05);
    border-radius: 10px;
    padding: 15px;
    gap: 5px;
  }

  &__text {
    @include BodyText;

    &-main {
      @include BodyTextBold;
    }
  }
  
  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-placeholder {
      object-fit: contain;
      padding: 11px;
    }

    &-container {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      height: 40px;
      width: 40px;
      background-color: $bg--white;
    }
  }

  &__cards {
    gap: 20px;

    &-select {
      .org-profile-confirm__card-radio {
        display: block;
      }
    }
  }

  &__card {
    padding: 15px;
    background-color: rgba(0, 166, 195, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    gap: 10px;

    &-title {
      @include BodyText;
    }

    &-text {
      color: $text--red;

      @include BodyTextSm;
      
      &--active {
        color: $text--dark-blue;
      }
    }

    &-content {
      gap: 5px;
    }

    &-alt {
      cursor: pointer;

      @include transition;

      &:hover, &:focus {
        border-color: $light-blue;
      }
    }
    
    &-radio {
      display: none;
      padding: 10px;
      height: 28px;
      width: 28px;
      min-height: 28px;
      min-width: 28px;
      margin-right: 10px;
      cursor: pointer;
      margin: 0 0 0 auto !important;
      color: $text--white;
      position: relative;
      -webkit-appearance: none;
      margin-left: auto;
      pointer-events: none;

      &:before {
        position: relative;
        display: block;
        width: 11px;
        height: 11px;
        content: '';
        background: $bg--white;
        overflow: hidden;
      }

      &:after {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        height: 28px;
        width: 28px;
        content: '';
        background-color: $bg--white;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        background-position: 10% 10%;
        background-repeat: no-repeat;

        @include transition;
      }

      &:checked:after {
        background-image: url('/assets/img/img/white-tick.svg');
        background-color: $bg--dark-blue;
        border-color: $bg--dark-blue;
      }
    }
  }

  &__select {
    display: none;
    gap: 10px;

    &--active {
      display: flex;
    }

    &-left, &-right {
      color: rgba(0, 0, 0, 0.80);

      @include BodyText;
    }
  }

  &__button {
    width: 100%;
  }

  &__number {
    margin-right: 5px;
  }

  &__bottom {
    width: $container-width--wide;
    gap: 20px;

    .link {
      margin-block-start: 10px;
    }
  }

  &__caption {
    color: rgba(0, 0, 0, 0.80);

    @include BodyText;
  }

  &__pattern {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    translate: 0 0 50px;
  } 
}

/*=====  End of Organisation Profile Confirm Component  ======*/