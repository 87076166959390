.quote {
    position: relative;
    gap: 120px;

    &--right {
        flex-direction: row-reverse;
    }

    &__content {
        gap: 30px;
        flex: 0.8;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;

        &-container {
            flex: 1;
            width: 100%;
            height: 480px;
            max-height: 480px;
            border-radius: 18px;
            overflow: hidden;
        }
    }

    &__title {
        @include HeadingH3Desktop;
    }

    &__subtitle {
        @include BodyText;
    }

    &__quote {
        @include BodyText;
        font-size: $font-size--mdlg;
        width: 70%;

        &-container {
            gap: 30px;
        }

        &-icon {
            width: 46px;
            height: 29px;
        }
    }

    &__caption {
        @include BodyText;
        opacity: .5;

        &-container {
            gap: 10px;
        }
    }
}

@include media-breakpoint-down(xl) {
    .quote {
        gap: 60px;
    }
}

@include media-breakpoint-down(lg) {
    .quote {
        flex-direction: column-reverse;
    }
}

@include media-breakpoint-down(md) {
    .quote {
        &__image-container {
            height: 380px;
            max-height: 380px;
        }

        &__title {
            @include HeadingH2Mobile;
        }

        &__quote {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .quote {
        &__image-container {
            height: 240px;
            max-height: 240px;
        }
    }
}