/*=============================================
=            Verify Email            =
=============================================*/

.verify-email {
  display: flex;
  gap: 80px;
  position: relative;

  @include media-breakpoint-down(xl) {
    gap: 60px;
  }

  &__image {
    height: 64px;
    width: 64px;

    &-container {
      padding: 60px 0;
      border-radius: 100px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      width: 150px;
      height: 200px;

      @include media-breakpoint-down(md) {
        margin: auto;
      }
    }
  }

  &__title {
    @include HeadingH2Desktop; 

    @include media-breakpoint-down(md) {
      @include HeadingH1Mobile; 
    }
  }

  &__content {
    color: rgba(0, 0, 0, 0.80);

    @include BodyText;
  }

  &__left, &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.78;
    gap: 20px;
    padding: 40px 0 80px 80px;
    margin-left: calc((100vw - $container-width--default) / 2);
    max-width: calc(560px + 80px);
    width: calc(100% + 80px);
    max-width: 640px;

    @include media-breakpoint-up(wide) {
      flex: 1;
    }

    @include media-breakpoint-down(desktop) {
      margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
      flex: 1;
      padding-right: 80px;
      margin: auto;
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 30px 30px 38px;
    }

    .link {
      padding: 10px 0;
    }
  }
  
  &__right {
    flex: 1;
    position: relative;
    background-color: $bg--light-blue;
    min-height: 820px;
    transform-style: preserve-3d;
    perspective: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      display: none
    }
  }

  &__resend {
    gap: 20px;
  }

  &__bottom {
    width: $container-width--wide;
    gap: 20px;
    padding-block-start: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
  }

  &__caption {
    color: rgba(0, 0, 0, 0.50);

    @include BodyText;

    &-resent{
      font-weight: 800;
    }
  }

  &__pattern {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    translate: 0 0 50px;
  } 
}

/*=====  End of Verify Email  ======*/