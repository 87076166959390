@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,700;1,400&display=swap");
.background--blue {
  background-color: rgba(0, 166, 195, 0.1);
}

.background--white {
  background-color: #fff;
}

#embedContainer {
  max-width: 1440px !important;
  margin: 0 auto !important;
  width: 100% !important;
  padding: 60px !important;
}

.fade-in {
  animation: fadein 0.2s;
}

.fade-in-slow {
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut 1s;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  color: #000;
}

@media (max-width: 1249.98px) {
  body {
    margin-top: 70px;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px;
}
@media (max-width: 767.98px) {
  .container {
    padding: 0 30px;
  }
}
.container--padding-right {
  padding-left: 0;
}
.container--padding-left {
  padding-right: 0;
}
.container--margin-lg {
  padding-top: 90px;
  padding-bottom: 90px;
}
@media (max-width: 767.98px) {
  .container--margin-lg {
    padding: 60px 30px;
  }
}
.container--margin-md {
  padding-top: 60px;
  padding-bottom: 60px;
}

.hidden {
  display: none;
  visibility: hidden;
}

.horizontal-ruler--auto {
  width: 100%;
  height: 2px;
  background-color: #E5E5E5;
}
.horizontal-ruler--full {
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100vw;
  left: calc(-50vw + 50%);
  height: 2px;
  background-color: #E5E5E5;
}

input[type=text], input[type=password], select {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
input[type=text]::placeholder, input[type=password]::placeholder, select::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

select {
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-black-icon.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.5);
  height: auto;
  cursor: pointer;
}

option {
  color: #000;
}

option:first-child {
  color: rgba(0, 0, 0, 0.5);
}

input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 2px;
  opacity: 1;
  background: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 2px;
  opacity: 1;
  background: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}
.flex--wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex--justify-space-around {
  justify-content: space-around;
}
.flex--justify-between {
  justify-content: space-between;
}
.flex--justify-center {
  justify-content: center;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--align-center {
  align-items: center;
}
.flex--align-start {
  align-items: flex-start;
}
.flex--align-end {
  align-items: flex-end;
}
.flex--row {
  flex-direction: row;
}
.flex--row-reverse {
  flex-direction: row-reverse;
}
.flex--column {
  flex-direction: column;
}

.contact-form {
  display: flex;
  gap: 120px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .contact-form {
    gap: 60px;
  }
}
.contact-form__left, .contact-form__right {
  width: 100%;
}
.contact-form__left {
  flex: 0.668;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px 0 60px 120px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 600px;
  width: calc(100% + 120px);
}
@media (min-width: 1921px) {
  .contact-form__left {
    max-width: none;
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .contact-form__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .contact-form__left {
    flex: 1;
    padding-right: 120px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .contact-form__left {
    padding: 60px 30px;
  }
}
.contact-form__left .umbraco-forms-form {
  padding: 0;
}
.contact-form__left .umbraco-forms-page .umbraco-forms-caption {
  font-size: 26px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.52px;
}
.contact-form__left .umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-form__left .umbraco-forms-indicator {
  color: #CD2A2A;
}
.contact-form__left .umbraco-forms-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 !important;
}
.contact-form__left .umbraco-forms-field.singlechoice {
  padding: 5px 0 0 0;
}
.contact-form__left .umbraco-forms-field.singlechoice label {
  color: #000;
}
.contact-form__left .umbraco-forms-field.checkbox .umbraco-forms-field-wrapper {
  flex-direction: row !important;
  align-items: center;
  gap: 10px;
}
.contact-form__left .umbraco-forms-field.dataconsent .umbraco-forms-field-wrapper {
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  min-height: 30px;
}
.contact-form__left .umbraco-forms-field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  align-items: flex-start;
}
.contact-form__left .umbraco-forms-field-wrapper span.field-validation-error {
  color: #CD2A2A !important;
  padding: 0 !important;
  margin-top: 5px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.contact-form__left .umbraco-forms-container label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.contact-form__left .umbraco-forms-container legend {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.contact-form__left .umbraco-forms-container input[type=text],
.contact-form__left .umbraco-forms-container input[type=email],
.contact-form__left .umbraco-forms-container input[type=tel],
.contact-form__left .umbraco-forms-container textarea,
.contact-form__left .umbraco-forms-container select {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.contact-form__left .umbraco-forms-container input[type=text]::placeholder,
.contact-form__left .umbraco-forms-container input[type=email]::placeholder,
.contact-form__left .umbraco-forms-container input[type=tel]::placeholder,
.contact-form__left .umbraco-forms-container textarea::placeholder,
.contact-form__left .umbraco-forms-container select::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.contact-form__left .umbraco-forms-container .radiobuttonlist {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;
}
.contact-form__left .umbraco-forms-container .radiobuttonlist br {
  display: none;
}
.contact-form__left .umbraco-forms-container .radiobuttonlist label {
  display: inline-block;
  margin-top: 6px;
  color: #000;
}
.contact-form__left .umbraco-forms-container select {
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-black-icon.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
  color: #000;
  max-width: none !important;
  margin: 0 !important;
}
.contact-form__left .umbraco-forms-container input[type=text].datepickerfield {
  -webkit-appearance: none;
  background-image: url("/assets/img/img/date-icon.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
  color: #000 !important;
  max-width: none !important;
  margin: 0 !important;
}
.contact-form__left .umbraco-forms-container option {
  color: rgba(0, 0, 0, 0.5);
}
.contact-form__left .umbraco-forms-container textarea {
  resize: none;
  height: auto !important;
}
.contact-form__left .umbraco-forms-container p {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.contact-form__left .umbraco-forms-container a {
  color: #0D518B;
}
.contact-form__left .umbraco-forms-container .contact-form__content p {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.contact-form__left .umbraco-forms-container input[type=checkbox],
.contact-form__left .umbraco-forms-container input[type=radio] {
  padding: 10px;
  height: 28px;
  width: 28px;
  min-height: 28px;
  min-width: 28px;
  margin-right: 10px;
  cursor: pointer;
  margin: 0 !important;
  color: #fff;
  position: relative;
  -webkit-appearance: none;
}
.contact-form__left .umbraco-forms-container input[type=checkbox]:before,
.contact-form__left .umbraco-forms-container input[type=radio]:before {
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
  content: "";
  background: #fff;
  overflow: hidden;
}
.contact-form__left .umbraco-forms-container input[type=checkbox]:after,
.contact-form__left .umbraco-forms-container input[type=radio]:after {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 28px;
  width: 28px;
  content: "";
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-position: 10% 10%;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.contact-form__left .umbraco-forms-container input[type=checkbox]:checked:after,
.contact-form__left .umbraco-forms-container input[type=radio]:checked:after {
  background-image: url("/assets/img/img/white-tick.svg");
  background-color: #0D518B;
  border-color: #0D518B;
}
.contact-form__left .umbraco-forms-container input[type=radio] {
  padding: 0;
  -webkit-appearance: none;
  height: 30px !important;
  margin: auto !important;
}
.contact-form__left .umbraco-forms-navigation {
  padding: 0 !important;
}
.contact-form__left .umbraco-forms-navigation .col-md-12 {
  width: fit-content;
  position: relative;
}
.contact-form__left .umbraco-forms-navigation .col-md-12:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("/assets/img/img/long-arrow-right-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 20px;
  width: 12px;
  pointer-events: none;
}
.contact-form__left .umbraco-forms-navigation input[type=submit] {
  padding: 10px 37px 10px 15px;
  gap: 10px;
  min-width: max-content;
  border-radius: 20px;
  background-color: #0D518B;
  color: #fff;
  cursor: pointer;
  margin: 0 !important;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.contact-form__left .umbraco-forms-navigation input[type=submit]:hover, .contact-form__left .umbraco-forms-navigation input[type=submit]:focus {
  color: #fff;
  background-color: #000;
}
.contact-form__left .umbraco-forms-fieldset {
  padding: 0;
  margin-bottom: 20px !important;
}
.contact-form__left .umbraco-forms-fieldset .row-fluid {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}
.contact-form__left .umbraco-forms-fieldset .row-fluid .umbraco-forms-container {
  width: 100%;
}
.contact-form__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 947px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .contact-form__right {
    display: none;
  }
}
.contact-form__title h2 {
  font-size: 26px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.52px;
}
.contact-form__content {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.contact-form__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.contact-form__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: currentColor;
  border: none;
  background: none;
}
*:hover,
*::before:hover,
*::after:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
  opacity: 1;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
a:hover, a:focus {
  color: currentColor;
  opacity: 1;
}

img {
  display: block;
  width: 100%;
}
img.inline {
  width: unset;
}

@media (max-width: 479.98px) {
  .hidden--sm-down {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .hidden--md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden--lg-up {
    display: none !important;
  }
}
.hidden-element {
  display: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/*===================================================================
=            404 Component            =
=====================================================================*/
.not-found {
  padding: 60px 240px;
  gap: 30px;
  min-height: 600px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .not-found {
    padding: 60px 30px;
    min-height: 500px;
  }
}
.not-found__title {
  font-size: 50px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .not-found__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.not-found__text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}

/*=====  End of 404 Component  ======*/
.account-slider {
  width: 540px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-block: 40px;
  padding-inline: 40px;
  margin-inline-start: auto;
  background-color: #fff;
  gap: 30px;
  transform: translateX(540px);
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 767.98px) {
  .account-slider {
    gap: 20px;
    padding: 20px;
    height: auto;
    width: 100%;
    margin-inline-start: 0;
    margin-block-start: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    transform: translateX(0) translateY(100%);
  }
}
.account-slider__overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999999999;
  cursor: default;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 1249.98px) {
  .account-slider__overlay {
    height: calc(100vh - 70px);
  }
}
.account-slider__overlay--active {
  visibility: visible;
  opacity: 1;
  animation: backgroundFade 0.2s ease-in-out forwards;
}
.account-slider__overlay--active .account-slider {
  transform: translateX(0px);
}
@media (max-width: 767.98px) {
  .account-slider__overlay--active .account-slider {
    transform: translateY(0px);
  }
}
@keyframes backgroundFade {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.account-slider__inner {
  overflow: hidden;
  width: 100%;
  gap: 30px;
}
@media (min-width: 768px) {
  .account-slider__inner {
    padding-inline-end: 40px;
    overflow-y: auto;
  }
}
.account-slider__header {
  width: 100%;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .account-slider__header {
    margin-inline: auto;
    padding-block-end: 20px;
  }
}
.account-slider__title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .account-slider__title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.account-slider__close.account-slider__close {
  height: 40px;
  width: 40px;
}
@media (max-width: 767.98px) {
  .account-slider__close.account-slider__close {
    height: 20px;
    width: 20px;
    background-color: transparent;
    color: #0D518B;
  }
  .account-slider__close.account-slider__close:hover, .account-slider__close.account-slider__close:focus {
    background-color: transparent;
    color: #000;
  }
}
.account-slider__form, .account-slider__inputs, .account-slider__button {
  width: 100%;
}
.account-slider__inputs {
  gap: 30px;
  overflow-y: auto;
}
.account-slider__form {
  gap: 30px;
}
.account-slider__form input[type=text], .account-slider__form input[type=password] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 35px 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.account-slider__form input[type=text]::placeholder, .account-slider__form input[type=password]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.account-slider__form .zmdi:hover {
  opacity: 0.7;
}
.account-slider__form .zmdi-eye {
  display: none;
}
.account-slider__form .zmdi-eye--active {
  display: block;
}
.account-slider__form .zmdi-eye-off {
  display: none;
}
.account-slider__form .zmdi-eye-off--active {
  display: block;
}
.account-slider__password-icon {
  position: absolute;
  bottom: 50%;
  right: 20px;
  z-index: 1;
  transform: translateY(50%);
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.account-slider__box {
  position: relative;
}
.account-slider__field {
  gap: 5px;
}
.account-slider__field-password {
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.account-slider__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.account-slider__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.account-slider__validation--active {
  display: block;
}

/*===================================================================
=            App Download            =
=====================================================================*/
.app-download {
  padding: 90px 0;
}
@media (max-width: 991.98px) {
  .app-download {
    padding: 40px 0;
  }
}
.app-download__wrapper {
  position: relative;
}
.app-download__left, .app-download__right {
  flex: 1;
}
.app-download__inner {
  gap: 120px;
}
@media (max-width: 991.98px) {
  .app-download__inner {
    gap: 40px;
    flex-direction: column;
  }
}
.app-download__left {
  box-sizing: border-box;
  gap: 30px;
}
.app-download__left-top {
  gap: 20px;
}
.app-download__right {
  position: relative;
  min-height: 480px;
  border-radius: 10px;
  overflow: hidden;
  min-width: 600px;
}
@media (max-width: 1439.98px) {
  .app-download__right {
    min-width: 400px;
  }
}
@media (max-width: 991.98px) {
  .app-download__right {
    display: grid;
  }
}
@media (max-width: 479.98px) {
  .app-download__right {
    height: 487px;
    min-height: 487px;
    max-height: 487px;
    min-width: 100%;
  }
}
.app-download__icon {
  width: 63px;
  height: 63px;
}
.app-download__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.app-download__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .app-download__title {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.app-download__stores {
  gap: 20px;
}
.app-download__store-icon {
  height: 100%;
  width: 100%;
  max-width: 160px;
  max-height: 50px;
  object-fit: contain;
}
@media (max-width: 479.98px) {
  .app-download__store-icon {
    max-width: 140px;
  }
}

/*=====  End of App Download  ======*/
/*=============================================
=            Button            =
=============================================*/
.button {
  cursor: pointer;
  padding: 10px 15px;
  gap: 10px;
  min-width: max-content;
  border-radius: 20px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.button--primary {
  background-color: #0D518B;
  color: #fff;
}
.button--primary:hover, .button--primary:focus {
  background-color: #000;
}
.button--primary-white {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid transparent;
}
.button--primary-white:hover, .button--primary-white:focus {
  background-color: #0D518B;
  color: #fff;
  border-color: #fff;
}
.button--simple {
  padding: 0;
  color: #0D518B;
}
.button--simple:hover, .button--simple:focus {
  color: #000;
}
.button--simple-white {
  padding: 0;
}
.button--simple-white:hover, .button--simple-white:focus {
  color: #000;
}
.button--round-blue {
  background-color: #0D518B;
  color: #fff;
  border-color: #fff;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.button--round-blue:hover, .button--round-blue:focus {
  background-color: #fff;
  color: #0D518B;
}
.button--round-blue .zmdi {
  font-size: 20px;
}
.button--round-white {
  background-color: #fff;
  color: #0D518B;
  border-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.button--round-white:hover {
  background-color: #000;
  color: #fff;
}
.button--round-white .zmdi {
  font-size: 20px;
}
.button--round-white-alt {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.button--round-white-alt:hover {
  background-color: #0D518B;
  color: #fff;
  border-color: #0D518B;
}

/*=====  End of Button  ======*/
.change-currency {
  width: 540px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-block: 40px;
  padding-inline: 40px 80px;
  margin-inline-start: auto;
  background-color: #fff;
  gap: 30px;
  transform: translateX(540px);
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 767.98px) {
  .change-currency {
    gap: 20px;
    padding: 20px 0;
    height: auto;
    width: 100%;
    margin-inline-start: 0;
    margin-block-start: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    transform: translateX(0) translateY(100%);
  }
}
.change-currency__item {
  width: 100%;
  gap: 10px;
}
.change-currency .link {
  width: 100%;
}
@media (max-width: 767.98px) {
  .change-currency .link {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}
.change-currency__overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999999999;
  cursor: default;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 1249.98px) {
  .change-currency__overlay {
    height: calc(100vh - 70px);
  }
}
.change-currency__overlay--active {
  visibility: visible;
  opacity: 1;
  animation: backgroundFade 0.2s ease-in-out forwards;
}
.change-currency__overlay--active .change-currency {
  transform: translateX(0px);
}
@media (max-width: 767.98px) {
  .change-currency__overlay--active .change-currency {
    transform: translateY(0px);
  }
}
@keyframes backgroundFade {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.change-currency__inner {
  overflow: hidden;
  width: 100%;
  gap: 30px;
}
@media (min-width: 768px) {
  .change-currency__inner {
    min-height: 770px;
  }
}
.change-currency__header {
  width: 100%;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .change-currency__header {
    width: calc(100% - 40px);
    margin-inline: auto;
    padding-block-end: 20px;
  }
}
.change-currency__title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .change-currency__title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.change-currency__close {
  height: 40px;
  width: 40px;
}
@media (max-width: 767.98px) {
  .change-currency__close {
    height: 20px;
    width: 20px;
    background-color: transparent;
    color: #0D518B;
  }
  .change-currency__close:hover, .change-currency__close:focus {
    background-color: transparent;
    color: #000;
  }
}

/*=============================================
=            Create Payment Component            =
=============================================*/
.create-pay {
  display: flex;
  padding: 0;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .create-pay {
    flex-direction: column;
  }
}
.create-pay__wrapper {
  display: none;
  background-image: linear-gradient(to right, rgb(255, 255, 255) 50%, rgba(0, 166, 195, 0.05) 50%);
}
@media (max-width: 767.98px) {
  .create-pay__wrapper {
    background-image: none;
  }
}
.create-pay__wrapper--active {
  display: block;
}
.create-pay__divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
.create-pay__title {
  margin: auto;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .create-pay__title {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.create-pay__top {
  position: relative;
  padding: 30px 80px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .create-pay__top {
    padding: 20px;
  }
}
.create-pay__subtitle, .create-pay__error {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .create-pay__subtitle, .create-pay__error {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.create-pay__error {
  display: none;
  color: #CD2A2A;
}
.create-pay__error--active {
  display: flex;
}
.create-pay__min-container {
  gap: 5px;
}
.create-pay__min-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__min-amount {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__threshold {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__threshold-container {
  gap: 5px;
}
.create-pay__threshold-amount {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__threshold-error {
  display: none;
  color: #CD2A2A;
}
.create-pay__threshold-error--active {
  display: flex;
}
.create-pay__left, .create-pay__right {
  width: 100%;
}
.create-pay__left {
  flex: 1;
  gap: 20px;
  margin-block: 40px 0;
  padding-inline: 80px 36px;
  margin-inline-end: 40px;
  min-height: 670px;
  max-height: 670px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  .create-pay__left {
    padding-inline: 20px 20px;
    margin-inline-end: 0;
  }
}
@media (max-width: 767.98px) {
  .create-pay__left {
    margin-block: 20px 40px;
    min-height: auto;
    max-height: none;
  }
}
.create-pay__left::-webkit-scrollbar {
  width: 4px;
}
.create-pay__left::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.create-pay__left::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.create-pay__left::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.create-pay__left-top {
  gap: 20px;
}
.create-pay__left hr {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  min-height: 1px;
}
.create-pay__left .link {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .create-pay__left .link {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.create-pay__right {
  flex: 1;
  gap: 20px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-inline: 80px;
  padding-block: 40px;
}
@media (max-width: 991.98px) {
  .create-pay__right {
    padding-inline: 20px;
  }
}
@media (max-width: 767.98px) {
  .create-pay__right {
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-block: 20px 40px;
    background-color: rgba(0, 166, 195, 0.05);
  }
}
.create-pay__right-title {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-block-end: 20px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__details {
  display: grid;
  grid-template-columns: 1fr 17px 1fr;
  background-color: #0D518B;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  gap: 30px;
}
@media (max-width: 1249.98px) {
  .create-pay__details {
    gap: 11.5px;
  }
}
.create-pay__details-icon {
  font-size: 25.5px;
  height: 48px;
  margin-top: auto;
}
.create-pay__details select,
.create-pay__details input {
  background-color: #fff;
  color: #000;
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  height: 50px;
  font-size: 23px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.23px;
}
.create-pay__details select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-black-icon.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 22px;
  margin-top: auto;
  cursor: pointer;
}
.create-pay__details select option {
  color: #000;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__details select option:first-child {
  color: #000;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.create-pay__details--disabled input {
  border: 2px solid #CD2A2A;
}
.create-pay__column {
  flex: 1;
  gap: 10px;
  height: auto;
  width: 100%;
}
.create-pay__column-small {
  gap: 10px;
}
.create-pay__column-small-space {
  height: 22.5px;
}
.create-pay__column-title, .create-pay__column-subtitle {
  text-align: center;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .create-pay__column-title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.create-pay__dropdown {
  width: 100%;
  gap: 5px;
}
@media (max-width: 767.98px) {
  .create-pay__dropdown {
    order: 2;
  }
}
.create-pay__dropdown-title {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.create-pay__dropdown-select {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding-inline: 20px 37px;
  padding-block: 10px;
  border-radius: 5px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-black-icon.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 18px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.create-pay__dropdown-select option {
  color: #000;
}
.create-pay__dropdown-select option:first-child {
  color: #000;
}
.create-pay__text {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.create-pay__caption {
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.create-pay__content {
  width: 100%;
  gap: 20px;
}
.create-pay__icon {
  position: relative;
  margin-left: 10px;
  color: #0D518B;
  cursor: pointer;
}
.create-pay__tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #0D518B;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  margin-left: 10px;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.create-pay__tooltip--active {
  visibility: visible;
  opacity: 1;
}
.create-pay__icon:hover + .create-pay__tooltip, .create-pay__icon:active + .create-pay__tooltip {
  opacity: 1;
}
.create-pay__row {
  width: 100%;
  gap: 20px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .create-pay__row-title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.create-pay__row:last-child {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.create-pay__row:last-child .create-pay__row-text {
  font-weight: 700;
}
.create-pay__button {
  width: 100%;
}
.create-pay__items {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 166, 195, 0.05);
  margin-block-end: 111px;
}
@media (max-width: 767.98px) {
  .create-pay__items {
    margin-block-end: 0;
  }
}
.create-pay__item-left {
  gap: 10px;
}
.create-pay__item-label {
  position: relative;
  width: 100%;
  gap: 10px;
  padding: 20px;
}
.create-pay__item-label::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.create-pay__item input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.create-pay__item input:checked + .create-pay__item-icon {
  background-image: url("/assets/img/img/tick-black-icon.svg");
}
.create-pay__item input:hover + .create-pay__item-icon {
  border-color: #00A6C3;
}
.create-pay__item-icon {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #fff;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center center;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.create-pay__item-title {
  flex: 1;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.create-pay__item-text {
  color: #3B952D;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.create-pay__cards {
  gap: 10px;
}
.create-pay__card {
  border-radius: 10px;
  background-color: rgba(0, 166, 195, 0.0509803922);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
}
.create-pay__card-title {
  color: #000;
  opacity: 0.8;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.create-pay__card--lg {
  flex: 0 1 70%;
}
.create-pay__card--sm {
  flex: 0 0 30%;
}
.create-pay__change-currency {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.create-pay__currencies {
  margin-top: auto;
}
.create-pay__currencies-save {
  width: 100%;
}
.create-pay__currencies option:first-child {
  color: #000;
}

/*=====  End of Create Payment Component  ======*/
/*=============================================
=            CTA Cards            =
=============================================*/
.cta-cards {
  position: relative;
  overflow: hidden;
}
.cta-cards__inner {
  gap: 30px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .cta-cards__inner {
    padding: 90px 30px 122px;
  }
}
.cta-cards__header {
  width: 100%;
}
.cta-cards__heading {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .cta-cards__heading {
    font-size: 23px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.23px;
  }
}
.cta-cards__grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 13px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991.98px) {
  .cta-cards__grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}
@media (max-width: 767.98px) {
  .cta-cards__grid {
    gap: 30px;
    display: none;
  }
}
@media (max-width: 767.98px) {
  .cta-cards__grid {
    display: none;
  }
}
.cta-cards__card {
  position: relative;
  overflow: hidden;
  white-space: normal;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  height: 290px;
  z-index: 1;
  color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 991.98px) {
  .cta-cards__card {
    height: 260px;
  }
}
.cta-cards__card:hover, .cta-cards__card:focus {
  color: #fff;
}
.cta-cards__card:hover .cta-cards__button, .cta-cards__card:focus .cta-cards__button {
  background-color: #000;
}
.cta-cards__card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(13, 81, 139, 0.8);
  z-index: -1;
}
.cta-cards__card-inner {
  height: 100%;
}
.cta-cards__card .link {
  align-self: flex-end;
  margin: auto;
}
.cta-cards__title {
  flex: 1;
  align-items: center;
  margin-block-start: 30px;
  font-size: 26px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.52px;
}
.cta-cards__button {
  border-radius: 50%;
  background-color: #0D518B;
  color: #fff;
  height: 30px;
  width: 30px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.cta-cards__carousel {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-cards__carousel {
    display: none;
  }
}
.cta-cards__carousel-range {
  height: 2px;
  width: 300px !important;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 2px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.cta-cards__carousel-range:focus {
  border-color: #fff;
}
.cta-cards__carousel-range-container {
  position: absolute;
  top: auto;
  bottom: 83px;
  left: 120px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .cta-cards__carousel-range-container {
    left: 30px;
  }
}

/*=====  End of CTA Cards  ======*/
/*=============================================
=            Dashboard            =
=============================================*/
.dashboard {
  display: flex;
  position: relative;
  min-height: 850px;
  max-height: 850px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .dashboard {
    flex-direction: column;
    max-height: none;
    min-height: auto;
  }
}
.dashboard__image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.dashboard__title {
  width: 100%;
  padding: 0 80px;
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .dashboard__title {
    padding: 0 30px 0 40px;
  }
}
@media (max-width: 767.98px) {
  .dashboard__title {
    display: none;
  }
}
.dashboard__left, .dashboard__right {
  width: 100%;
}
.dashboard__right {
  flex: 0.83;
  padding: 40px 0 50px 0;
  margin-right: calc((100vw - 1440px) / 2);
  max-width: 650px;
  overflow: hidden;
}
@media (max-width: 1439.98px) {
  .dashboard__right {
    margin-right: auto;
  }
}
@media (max-width: 991.98px) {
  .dashboard__right {
    flex: 1;
  }
}
@media (max-width: 767.98px) {
  .dashboard__right {
    max-width: none;
    padding-block: 20px;
  }
}
.dashboard__right--hidden {
  display: none;
}
.dashboard__left {
  flex: 1;
  position: relative;
  background-image: url("/assets/img/img/dashboard-image.png");
  background-color: #00A6C3;
  background-position: right center;
  background-size: auto;
  background-repeat: no-repeat;
  min-width: 400px;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .dashboard__left {
    min-width: auto;
  }
}
@media (max-width: 479.98px) {
  .dashboard__left {
    background-size: 94%;
    background-position-y: 102px;
    background-position-x: 31px;
  }
}
.dashboard__left::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
}
.dashboard__left-inner {
  position: absolute;
  top: 0;
  right: 0;
  padding-block-start: 40px;
  z-index: 1;
  max-width: 790px;
  width: 100%;
  gap: 30px;
}
@media (max-width: 767.98px) {
  .dashboard__left-inner {
    position: static;
    padding-block: 20px;
  }
}
.dashboard__payments {
  width: 100%;
  gap: 30px;
}
@media (max-width: 767.98px) {
  .dashboard__payments {
    gap: 20px;
  }
}
.dashboard__payments-header {
  width: 100%;
  padding: 0 80px;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .dashboard__payments-header {
    padding: 0 30px 0 40px;
  }
}
@media (max-width: 767.98px) {
  .dashboard__payments-header {
    justify-content: center;
    text-align: center;
  }
}
.dashboard__payments-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .dashboard__payments-title {
    min-height: 20px;
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
@media (max-width: 767.98px) {
  .dashboard__payments-link {
    display: none;
  }
}
.dashboard__cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 30px;
  max-height: 602px;
  padding-inline: 80px 40px;
  margin-inline-end: 40px;
  overflow-y: auto;
}
@media (max-width: 991.98px) {
  .dashboard__cards {
    padding-inline: 40px 15px;
    margin-inline-end: 15px;
  }
}
@media (max-width: 767.98px) {
  .dashboard__cards {
    display: none;
  }
}
.dashboard__cards::-webkit-scrollbar {
  width: 4px;
}
.dashboard__cards::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
.dashboard__cards::-webkit-scrollbar-thumb {
  background: #000;
}
.dashboard__cards::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.dashboard__links {
  gap: 30px;
  overflow: hidden;
  overflow-x: auto;
  padding-block-end: 20px;
  margin-block-end: 20px;
  min-height: 62px;
  width: 100%;
  max-width: 540px;
  cursor: grab;
}
.dashboard__links:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}
@media (max-width: 767.98px) {
  .dashboard__links {
    width: 100%;
    padding-block-end: 0;
    gap: 0;
    min-height: 0;
    max-width: none;
  }
}
.dashboard__links::-webkit-scrollbar {
  height: 4px;
}
@media (max-width: 767.98px) {
  .dashboard__links::-webkit-scrollbar {
    height: 0;
  }
}
.dashboard__links::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.dashboard__links::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.dashboard__links::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.dashboard__links-container {
  overflow: hidden;
  padding-inline-end: 80px;
  padding-inline-start: 30px;
  margin-block-start: 30px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .dashboard__links-container {
    padding-inline-end: 0;
    margin-block-start: 0;
  }
}
.dashboard__links-title {
  padding-inline-start: 30px;
  padding-inline-end: 80px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .dashboard__links-title {
    display: none;
  }
}
.dashboard__links-list {
  gap: 5.5px;
  min-height: 42px;
  margin-inline-start: 1px;
}
@media (max-width: 767.98px) {
  .dashboard__links-list {
    padding-inline-end: 20px;
  }
}
.dashboard__links .link.dashboard__links-link {
  gap: 9.5px;
  margin-inline-start: 0.5px;
  width: max-content;
  user-select: none;
  -moz-user-select: -moz-none;
}
.dashboard__links-icon {
  width: auto;
  margin-inline-start: 8px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard__contacts {
  position: relative;
  width: 100%;
  padding-inline-start: 40px;
}
@media (max-width: 767.98px) {
  .dashboard__contacts {
    padding-inline: 0;
    padding-block-end: 20px;
    margin-block-end: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.dashboard__contacts::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10000px;
  transform: translateX(-30px);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .dashboard__contacts::before {
    display: none;
  }
}
.dashboard__contacts .dashboard__carousel {
  padding-inline: 20px 0;
}
.dashboard__contacts .dashboard__carousel-inner {
  padding-inline: 0;
}
.dashboard__contacts-new {
  width: 100%;
}
.dashboard__contacts-header {
  width: 100%;
  gap: 10px;
  padding-inline-end: 80px;
  padding-block: 40px 30px;
  max-width: 640px;
}
@media (max-width: 991.98px) {
  .dashboard__contacts-header {
    padding-inline-end: 59px;
  }
}
@media (max-width: 767.98px) {
  .dashboard__contacts-header {
    padding-inline: 20px;
    padding-block: 20px;
  }
  .dashboard__contacts-header .link {
    display: none;
  }
}
.dashboard__contacts-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .dashboard__contacts-title {
    max-width: 70%;
  }
}
.dashboard__contacts-list {
  position: relative;
  gap: 10px;
  padding-inline-end: 40px;
  margin-inline-end: 40px;
  max-height: 512px;
  overflow-y: auto;
}
@media (max-width: 991.98px) {
  .dashboard__contacts-list {
    padding-inline-end: 15px;
  }
}
@media (max-width: 767.98px) {
  .dashboard__contacts-list {
    display: none;
  }
}
.dashboard__contacts-list::after {
  content: "";
  position: sticky;
  bottom: 0;
  left: 0;
  height: 100px;
  min-height: 100px;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
.dashboard__contacts-list::-webkit-scrollbar {
  width: 4px;
}
.dashboard__contacts-list::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.dashboard__contacts-list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.dashboard__contacts-list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.dashboard__mobile-link {
  width: calc(100% - 40px);
  margin-inline: auto;
}
@media (min-width: 768px) {
  .dashboard__mobile-link {
    display: none;
  }
}
@media (min-width: 768px) {
  .dashboard__carousel {
    display: none;
  }
}
.dashboard__carousel-inner {
  padding: 0 30px;
  overflow: hidden;
}
.dashboard__range {
  width: 94px !important;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
@media (min-width: 768px) {
  .dashboard__range-container {
    display: none;
  }
}
.dashboard .glide__bullets {
  gap: 10px;
  margin-block-start: 27px;
}
.dashboard .glide__bullet {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard .glide__bullet--active {
  opacity: 1;
}

/*=====  End of Dashboard  ======*/
/*=============================================
=            Dashboard Contact Card            =
=============================================*/
.dashboard-contact {
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard-contact:hover {
  border-color: #000;
}
.dashboard-contact--hidden {
  display: none;
}
.dashboard-contact__left {
  gap: 10px;
}
.dashboard-contact__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.dashboard-contact__info {
  gap: 5px;
}
.dashboard-contact__info-title, .dashboard-contact__info-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.dashboard-contact__info-text {
  color: rgba(0, 0, 0, 0.5);
}
.dashboard-contact__status {
  gap: 5px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dashboard-contact__status .zmdi {
  font-size: 11px;
}
.dashboard-contact__status-verified {
  color: #3B952D;
}
.dashboard-contact__status-pending {
  color: #00A6C3;
}
.dashboard-contact__status-unverified {
  color: #CD2A2A;
}
.dashboard-contact__card {
  position: relative;
  justify-content: space-between;
  padding-block: 10px;
  padding-inline: 10px 15px;
  border-radius: 10px;
  width: 100%;
  gap: 10px;
}
.dashboard-contact__card .dashboard-contact__pill {
  margin-inline-end: 20px;
  border-radius: 15px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dashboard-contact__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.dashboard-contact__image-placeholder {
  object-fit: contain;
  padding: 16px;
}
.dashboard-contact__image-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  background-color: #fff;
}
.dashboard-contact__pill {
  padding: 2px 10px;
  border-radius: 10px;
  border: 1px solid;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dashboard-contact__pill-gold {
  border-color: #B99300;
  color: #B99300;
}
.dashboard-contact__pill-royal-blue {
  border-color: #0D518B;
  color: #0D518B;
}
.dashboard-contact__pill-sky-blue {
  border-color: #00A6C3;
  color: #00A6C3;
}

/*=====  End of Dashboard Contact Card  ======*/
/*=============================================
=            Dashboard Payment Card            =
=============================================*/
.dash-card {
  border-radius: 10px;
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  overflow: clip;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 #0497b1, inset 1px 0 0 #0497b1, inset 0 1px 0 #0497b1, inset -1px 0 0 #0497b1, 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.dash-card:hover .dash-card__bottom, .dash-card:focus .dash-card__bottom {
  outline-color: #000;
}
.dash-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
}
.dash-card__inner {
  width: 100%;
}
.dash-card__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.dash-card__image-placeholder {
  object-fit: contain;
  padding: 11px;
}
.dash-card__image-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 40px;
  width: 40px;
}
.dash-card__value {
  font-size: 23px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.23px;
}
.dash-card__currency {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dash-card__info {
  flex: 1;
}
.dash-card__top {
  gap: 5px;
  background-color: #fff;
  padding: 15px;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: calc(100% - 2px);
  margin-block-start: 1px;
  margin-inline-start: 1px;
}
.dash-card__middle {
  margin-block: 20px 15px;
  margin-inline: 15px;
  margin-block-end: auto;
  z-index: 1;
}
.dash-card__bottom {
  gap: 10px;
  padding: 10px;
  padding-inline-end: 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-block: 15px 20px;
  margin-inline: 15px;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dash-card__bottom:hover, .dash-card__bottom:focus {
  outline-color: #000;
}
.dash-card__amount {
  flex: 1;
}
.dash-card__type {
  gap: 5px;
}
.dash-card__type-title {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dash-card__type-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.dash-card__date-title {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dash-card__date-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.dash-card__info {
  gap: 5px;
}
.dash-card__info-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.dash-card__info-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dash-card__status {
  gap: 5px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dash-card__status .zmdi {
  font-size: 11px;
}
.dash-card__status-verified {
  color: #3B952D;
}
.dash-card__status-pending {
  color: #00A6C3;
}
.dash-card__status-unverified {
  color: #CD2A2A;
}
.dash-card__pill {
  padding: 2px 10px;
  border-radius: 10px;
  border: 1px solid;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.dash-card__pill-gold {
  border-color: #B99300;
  color: #B99300;
}
.dash-card__pill-royal {
  border-color: #0D518B;
  color: #0D518B;
}
.dash-card__pill-sky {
  border-color: #00A6C3;
  color: #00A6C3;
}

/*=====  End of Dashboard Payment Card  ======*/
/*=============================================
=            Dashboard Unverified            =
=============================================*/
.dashboard-unverified {
  position: relative;
  min-height: 850px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .dashboard-unverified {
    flex-direction: column;
    min-height: auto;
  }
}
.dashboard-unverified__image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.dashboard-unverified__title {
  width: 100%;
  padding-block: 0 30px;
  padding-inline: 80px;
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1249.98px) {
  .dashboard-unverified__title {
    padding-inline: 30px 80px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__title {
    display: none;
  }
}
.dashboard-unverified__left, .dashboard-unverified__right {
  width: 100%;
  flex: 1;
}
.dashboard-unverified__right {
  padding: 40px 0 50px 0;
  margin-inline-end: calc((100vw - 1440px) / 2);
  overflow: hidden;
  max-width: 720px;
}
@media (max-width: 1439.98px) {
  .dashboard-unverified__right {
    margin-inline-end: auto;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__right {
    max-width: none;
    padding-block: 20px 0;
  }
}
.dashboard-unverified__left {
  flex: 1;
  position: relative;
  background-image: url("/assets/img/img/dashboard-image.svg");
  background-color: #00A6C3;
  background-position-x: 84px;
  background-size: auto;
  background-repeat: no-repeat;
  min-width: 400px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .dashboard-unverified__left {
    min-height: 400px;
    min-width: auto;
  }
}
@media (max-width: 479.98px) {
  .dashboard-unverified__left {
    background-size: 130%;
    background-position-y: 129px;
    background-position-x: 31px;
  }
}
.dashboard-unverified__left::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
}
.dashboard-unverified__left-inner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  max-width: 720px;
  width: 100%;
  height: 100%;
  gap: 30px;
}
.dashboard-unverified__box {
  width: 300px;
  padding: 38px 10px;
  gap: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -1px 0 #0497b1, inset 1px 0 0 #0497b1, inset 0 1px 0 #0497b1, inset -1px 0 0 #0497b1, 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.dashboard-unverified__box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
}
.dashboard-unverified__box-title, .dashboard-unverified__box-text,
.dashboard-unverified__box .link {
  z-index: 1;
  text-align: center;
}
.dashboard-unverified__box-title {
  font-size: 23px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.23px;
}
.dashboard-unverified__box-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.dashboard-unverified__links {
  gap: 30px;
  overflow: hidden;
  overflow-x: auto;
  padding-block-end: 20px;
  margin-block-end: 20px;
  min-height: 62px;
  width: 100%;
  max-width: 540px;
  cursor: grab;
}
.dashboard-unverified__links:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}
@media (max-width: 767.98px) {
  .dashboard-unverified__links {
    width: 100%;
    padding-block-end: 0;
    gap: 0;
    min-height: 0;
    max-width: none;
  }
}
.dashboard-unverified__links::-webkit-scrollbar {
  height: 4px;
}
@media (max-width: 767.98px) {
  .dashboard-unverified__links::-webkit-scrollbar {
    height: 0;
  }
}
.dashboard-unverified__links::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.dashboard-unverified__links::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.dashboard-unverified__links::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.dashboard-unverified__links-container {
  overflow: hidden;
  padding-inline: 80px;
  margin-block-start: 30px;
  width: 100%;
}
@media (max-width: 1249.98px) {
  .dashboard-unverified__links-container {
    padding-inline: 30px 80px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__links-container {
    padding-inline-end: 0;
    margin-block-start: 0;
  }
}
.dashboard-unverified__links-title {
  padding-inline: 80px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 1249.98px) {
  .dashboard-unverified__links-title {
    padding-inline: 30px 80px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__links-title {
    display: none;
  }
}
.dashboard-unverified__links-list {
  gap: 5.5px;
  min-height: 42px;
  margin-inline-start: 1px;
}
@media (max-width: 767.98px) {
  .dashboard-unverified__links-list {
    padding-inline-end: 20px;
  }
}
.dashboard-unverified__links .link.dashboard-unverified__links-link {
  gap: 9.5px;
  margin-inline-start: 0.5px;
  width: max-content;
}
.dashboard-unverified__links-icon {
  width: auto;
  margin-inline-start: 8px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard-unverified__contacts {
  position: relative;
  width: 100%;
  padding-inline-start: 80px;
}
@media (max-width: 1249.98px) {
  .dashboard-unverified__contacts {
    padding-inline-start: 30px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__contacts {
    padding-inline: 0;
    padding-block-end: 20px;
    margin-block-end: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.dashboard-unverified__contacts::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .dashboard-unverified__contacts::before {
    display: none;
  }
}
.dashboard-unverified__contacts-header {
  width: 100%;
  gap: 10px;
  padding-inline-end: 80px;
  padding-block: 40px 30px;
  max-width: 640px;
}
@media (max-width: 991.98px) {
  .dashboard-unverified__contacts-header {
    padding-inline-end: 59px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__contacts-header {
    padding-inline: 20px;
    padding-block: 20px;
    max-width: none;
  }
  .dashboard-unverified__contacts-header .link {
    display: none;
  }
}
.dashboard-unverified__contacts-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .dashboard-unverified__contacts-title {
    max-width: 70%;
  }
}
.dashboard-unverified__contacts-list {
  position: relative;
  gap: 10px;
  padding-inline-end: 40px;
  margin-inline-end: 40px;
  max-height: 450px;
  overflow-y: auto;
}
@media (max-width: 991.98px) {
  .dashboard-unverified__contacts-list {
    padding-inline-end: 15px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__contacts-list {
    display: none;
  }
}
.dashboard-unverified__contacts-list::after {
  content: "";
  position: sticky;
  bottom: 0;
  left: 0;
  height: 100px;
  min-height: 100px;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
.dashboard-unverified__contacts-list::-webkit-scrollbar {
  width: 4px;
}
.dashboard-unverified__contacts-list::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.dashboard-unverified__contacts-list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.dashboard-unverified__contacts-list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.dashboard-unverified__mobile-link {
  width: calc(100% - 40px);
  margin-inline: auto;
}
@media (min-width: 768px) {
  .dashboard-unverified__mobile-link {
    display: none;
  }
}
.dashboard-unverified__skeleton {
  background-color: rgba(0, 166, 195, 0.05);
  height: 90px;
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 767.98px) {
  .dashboard-unverified__skeleton:nth-of-type(2), .dashboard-unverified__skeleton:nth-of-type(3) {
    display: none;
  }
}
.dashboard-unverified__skeleton-container {
  padding-inline-end: 80px;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .dashboard-unverified__skeleton-container {
    padding-inline-end: 40px;
  }
}
@media (max-width: 767.98px) {
  .dashboard-unverified__skeleton-container {
    padding-inline: 20px;
  }
}
@media (min-width: 768px) {
  .dashboard-unverified__carousel {
    display: none;
  }
}
.dashboard-unverified__carousel-inner {
  padding: 0 20px;
  overflow: hidden;
}
.dashboard-unverified__range {
  height: 2px;
  width: 94px !important;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
@media (min-width: 768px) {
  .dashboard-unverified__range-container {
    display: none;
  }
}
.dashboard-unverified .glide__bullets {
  gap: 10px;
  margin-block-start: 27px;
}
.dashboard-unverified .glide__bullet {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard-unverified .glide__bullet--active {
  opacity: 1;
}

/*=====  End of Dashboard Unverified  ======*/
/*===================================================================
=            Document Category            =
=====================================================================*/
.document-type {
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.document-type:hover, .document-type:focus {
  border-color: #00A6C3;
}
.document-type:hover .individual-auth__card-icon, .document-type:focus .individual-auth__card-icon {
  background-color: #000;
}
.document-type--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.document-type--active {
  border-color: #00A6C3;
}
.document-type--error {
  border-color: #CD2A2A;
}
.document-type__left {
  gap: 10px;
}
.document-type__title {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
.document-type__text, .document-type__status {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.document-type__text {
  display: list-item;
  margin-inline-start: 20px;
}
.document-type__text::marker {
  font-size: 9px;
}
.document-type__status {
  color: rgba(0, 0, 0, 0.3);
  gap: 10px;
}
.document-type__status--error {
  color: #CD2A2A;
}
.document-type__status--success {
  color: #3B952D;
}
.document-type__status--pending {
  color: #00A6C3;
}
.document-type__icon {
  height: 40px;
  width: 40px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.document-type__icon .zmdi {
  font-size: 15px;
}

/*=====  End of Document Category  ======*/
/*===================================================================
=            Feature Media With Text            =
=====================================================================*/
.feature-media-with-text {
  padding: 80px 0;
  position: relative;
}
@media (max-width: 991.98px) {
  .feature-media-with-text {
    padding: 30px 0;
  }
}
.feature-media-with-text__left, .feature-media-with-text__right {
  flex: 1;
}
.feature-media-with-text__inner {
  gap: 120px;
}
@media (max-width: 991.98px) {
  .feature-media-with-text__inner.flex--row-reverse {
    flex-direction: column;
  }
}
@media (max-width: 1439.98px) {
  .feature-media-with-text__inner {
    gap: 60px;
  }
}
@media (max-width: 1249.98px) {
  .feature-media-with-text__inner {
    flex-direction: column;
  }
}
@media (max-width: 479.98px) {
  .feature-media-with-text__inner {
    gap: 30px;
  }
}
.feature-media-with-text__left {
  box-sizing: border-box;
  gap: 30px;
  min-width: 400px;
}
@media (max-width: 991.98px) {
  .feature-media-with-text__left {
    min-width: auto;
  }
}
.feature-media-with-text__right {
  position: relative;
  height: 480px;
  border-radius: 10px;
  overflow: hidden;
  min-width: 600px;
}
@media (max-width: 1439.98px) {
  .feature-media-with-text__right {
    min-width: 400px;
    flex: auto;
    max-width: 100%;
  }
}
@media (max-width: 479.98px) {
  .feature-media-with-text__right {
    min-width: 100%;
    display: none;
    flex: auto;
  }
}
.feature-media-with-text__right-mobile {
  display: none;
}
@media (max-width: 479.98px) {
  .feature-media-with-text__right-mobile {
    display: grid;
    min-height: 300px;
    max-height: 300px;
    overflow: hidden;
  }
}
.feature-media-with-text__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 479.98px) {
  .feature-media-with-text__video {
    height: 100%;
  }
}
.feature-media-with-text__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
@media (min-width: 768px) and (max-width: 1299.98px) {
  .feature-media-with-text__image {
    width: auto;
    object-fit: contain;
    margin: 0 auto;
    border-radius: 10px;
  }
}
.feature-media-with-text__title {
  font-size: 50px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .feature-media-with-text__title {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.feature-media-with-text__text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  opacity: 0.8;
}
.feature-media-with-text__bullets {
  width: 100%;
  gap: 10px;
  padding: 10px 0;
}
.feature-media-with-text__bullet {
  gap: 10px;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 479.98px) {
  .feature-media-with-text__bullet {
    font-size: 15px;
    line-height: 150%;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.feature-media-with-text__bullet-icon {
  height: 24px;
  width: 24px;
}
@media (max-width: 479.98px) {
  .feature-media-with-text .link {
    display: none;
  }
}

/*=====  End of Feature Media With Text  ======*/
/*===================================================================
=            Feature With Media           =
=====================================================================*/
@media (max-width: 991.98px) {
  .feature-with-media {
    padding: 40px 0;
  }
}
.feature-with-media__wrapper {
  position: relative;
}
.feature-with-media__left, .feature-with-media__right {
  flex: 1;
  gap: 20px;
}
.feature-with-media__inner {
  gap: 120px;
}
@media (max-width: 991.98px) {
  .feature-with-media__inner.flex--row-reverse {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  .feature-with-media__inner {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: 991.98px) {
  .feature-with-media__left {
    gap: 30px;
  }
}
.feature-with-media__right {
  position: relative;
  min-height: auto;
  overflow: hidden;
  min-width: 600px;
}
@media (max-width: 1439.98px) {
  .feature-with-media__right {
    min-width: 400px;
  }
}
@media (max-width: 479.98px) {
  .feature-with-media__right {
    min-width: 100%;
  }
}
.feature-with-media__title {
  font-size: 50px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .feature-with-media__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.feature-with-media__image {
  height: auto;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 10px;
  object-fit: contain;
  margin: auto;
}
.feature-with-media__subtitle {
  width: 100%;
  color: #00A6C3;
  padding-bottom: 20px;
  border-bottom: 1px solid #00A6C3;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 991.98px) {
  .feature-with-media__subtitle {
    padding-bottom: 30px;
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}

/*=====  End of Feature With Media  ======*/
/*===================================================================
=            Feature With Text           =
=====================================================================*/
.feature-with-text__wrapper {
  position: relative;
}
@media (max-width: 991.98px) {
  .feature-with-text {
    padding-top: 30px;
  }
}
.feature-with-text__left, .feature-with-text__right {
  flex: 1;
  gap: 20px;
}
.feature-with-text__inner {
  gap: 120px;
}
@media (max-width: 991.98px) {
  .feature-with-text__inner {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: 991.98px) {
  .feature-with-text__left {
    gap: 30px;
  }
}
.feature-with-text__left .rte {
  padding-top: 20px;
  border-top: 1px solid #00A6C3;
}
@media (max-width: 991.98px) {
  .feature-with-text__left .rte {
    padding-top: 30px;
  }
}
.feature-with-text__right {
  min-width: 600px;
}
@media (max-width: 1439.98px) {
  .feature-with-text__right {
    min-width: 400px;
  }
}
@media (max-width: 479.98px) {
  .feature-with-text__right {
    min-width: 100%;
  }
}
.feature-with-text__title {
  font-size: 50px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .feature-with-text__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.feature-with-text__subtitle {
  width: 100%;
  color: #00A6C3;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 991.98px) {
  .feature-with-text__subtitle {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}

/*=====  End of Feature With Text  ======*/
/*===================================================================
=            Footer            =
=====================================================================*/
.footer {
  background-color: #0D518B;
  color: #fff;
  padding: 90px 0;
}
@media (max-width: 479.98px) {
  .footer {
    padding: 90px 0 120px 0;
  }
}
.footer__inner {
  padding: 0 120px;
}
@media (max-width: 767.98px) {
  .footer__inner {
    padding: 0 30px;
  }
}
.footer__top {
  width: 100%;
  padding-block-end: 30px;
  margin-block-end: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  gap: 60px;
}
@media (max-width: 479.98px) {
  .footer__top {
    gap: 0;
  }
}
.footer__bottom {
  opacity: 0.8;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 479.98px) {
  .footer__bottom {
    opacity: 1;
  }
}
.footer__left {
  gap: 30px;
}
@media (max-width: 479.98px) {
  .footer__left {
    width: 100%;
    padding-block-end: 30px;
    margin-block-end: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.footer__right {
  gap: 60px;
  margin-block-start: 23px;
}
@media (max-width: 479.98px) {
  .footer__right {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    margin-block-start: 0px;
  }
}
.footer__logo {
  width: 173px;
  max-width: 173px;
}
.footer__caption {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.footer__column {
  gap: 20px;
}
@media (max-width: 479.98px) {
  .footer__column:nth-child(3) {
    display: none;
  }
}
.footer__column-mobile {
  width: 100%;
}
@media (min-width: 480px) {
  .footer__column-mobile {
    display: none;
  }
}
.footer__column-mobile .footer__item {
  width: fit-content;
}
@media (max-width: 479.98px) {
  .footer__item {
    width: 100%;
  }
  .footer__item .link {
    width: 100%;
    justify-content: flex-start;
  }
}
.footer__socials {
  gap: 30px;
}
@media (max-width: 479.98px) {
  .footer__socials {
    display: none;
  }
}
.footer__socials-mobile {
  gap: 30px;
}
@media (min-width: 480px) {
  .footer__socials-mobile {
    display: none;
  }
}
.footer__social {
  font-size: 20px;
}
.footer__social .zmdi {
  height: 20px;
  width: 20px;
}
.footer__social:hover, .footer__social:focus {
  color: #000;
}
.footer__mobile {
  gap: 30px;
  margin-block-end: 30px;
  width: 100%;
}
@media (min-width: 480px) {
  .footer__mobile {
    display: none;
  }
}

/*=====  End of Footer  ======*/
/*=============================================
=            Found Recipient Component            =
=============================================*/
.found-recipient {
  gap: 30px;
  padding-block: 60px;
  background-color: rgba(0, 166, 195, 0.05);
}
@media (max-width: 767.98px) {
  .found-recipient {
    padding-block: 20px 239px;
    gap: 20px;
  }
}
.found-recipient__wrapper hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.found-recipient__header {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px 80px;
  opacity: 0.8;
}
@media (max-width: 767.98px) {
  .found-recipient__header {
    padding: 20px;
  }
}
.found-recipient__heading {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .found-recipient__heading {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.found-recipient__top {
  gap: 30px;
  padding-inline: 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .found-recipient__top {
    padding-inline: 20px;
    align-items: flex-start;
    gap: 20px;
  }
}
.found-recipient__middle, .found-recipient__bottom {
  gap: 30px;
  padding-inline: 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .found-recipient__middle, .found-recipient__bottom {
    padding-inline: 20px;
    gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .found-recipient__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-block: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    gap: 20px;
  }
}
.found-recipient__status {
  display: none;
  gap: 5px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.found-recipient__status--active {
  display: flex;
}
.found-recipient__status--verified {
  color: #3B952D;
}
.found-recipient__status--pending {
  color: #00A6C3;
}
.found-recipient__status--unverifed {
  color: #CD2A2A;
}
.found-recipient__button {
  min-width: 320px;
}
@media (max-width: 479.98px) {
  .found-recipient__button {
    min-width: auto;
    width: 100%;
  }
}
.found-recipient__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.found-recipient__image-placeholder {
  object-fit: contain;
  padding: 16px;
}
.found-recipient__image-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  background-color: #fff;
}
.found-recipient__user {
  gap: 10px;
}
.found-recipient__card {
  width: 100%;
  max-width: 600px;
  padding: 11px 10px;
  background-color: #fff;
  border-radius: 5px;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.found-recipient__card-left {
  flex: 1;
}
.found-recipient__card-right {
  max-width: 200px;
  max-height: 126px;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .found-recipient__card-right {
    max-height: 78px;
    max-width: 123px;
    align-items: flex-start;
  }
}
.found-recipient__card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.found-recipient__info {
  gap: 5px;
}
.found-recipient__info-title {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.found-recipient__info-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.found-recipient__location {
  text-align: center;
  margin-block: 5px 10px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .found-recipient__location {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.found-recipient__pill {
  padding: 2px 10px;
  border-radius: 15px;
  border: 1px solid;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .found-recipient__pill {
    border-radius: 10px;
    font-size: 10px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
@media (max-width: 479.98px) {
  .found-recipient__pill {
    text-align: center;
    width: 100%;
  }
}
.found-recipient__pill-gold {
  border-color: #B99300;
  color: #B99300;
}
.found-recipient__pill-royal {
  border-color: #0D518B;
  color: #0D518B;
}
.found-recipient__pill-sky {
  border-color: #00A6C3;
  color: #00A6C3;
}
.found-recipient__row {
  width: 100%;
  max-width: 600px;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .found-recipient__row {
    max-width: none;
    justify-content: space-between;
  }
}
.found-recipient__row-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .found-recipient__row-title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.found-recipient__row-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.found-recipient__row-value--right {
  text-align: right;
}
.found-recipient__row-value .found-recipient__input {
  width: 200px;
}
@media (max-width: 479.98px) {
  .found-recipient__row-value .found-recipient__input {
    width: 176px;
  }
}
.found-recipient__dropdown {
  width: 200px;
}

/*=====  End of Found Recipient Component  ======*/
/*=============================================
=            Individual Authentication            =
=============================================*/
.individual-auth {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.individual-auth__wrapper {
  background: linear-gradient(to right, white 50%, rgba(0, 166, 195, 0.05) 50%);
  position: relative;
}
@media (max-width: 991.98px) {
  .individual-auth__wrapper {
    background: none;
  }
}
.individual-auth__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .individual-auth__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.individual-auth__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-auth__user {
  width: 100%;
  gap: 20px;
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.individual-auth__user-title {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.individual-auth__avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.individual-auth__avatar-placeholder {
  object-fit: contain;
  padding: 11px;
  background-color: rgba(0, 166, 195, 0.05);
}
.individual-auth__avatar-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: #fff;
}
.individual-auth__left, .individual-auth__right {
  flex: 1;
  width: 100%;
  padding: 40px 80px 80px;
  gap: 20px;
}
.individual-auth__right {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991.98px) {
  .individual-auth__right {
    padding: 40px 80px 40px;
  }
}
@media (max-width: 991.98px) {
  .individual-auth__right {
    background-color: #fff;
    padding: 40px 80px 40px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
}
@media (max-width: 767.98px) {
  .individual-auth__right {
    padding: 30px;
  }
}
.individual-auth__right--active {
  position: static;
  opacity: 1;
  visibility: visible;
}
.individual-auth__left {
  z-index: 0;
}
@media (max-width: 991.98px) {
  .individual-auth__left--hidden {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .individual-auth__left {
    padding: 40px 80px 40px;
  }
}
@media (max-width: 767.98px) {
  .individual-auth__left {
    padding: 30px;
  }
}
.individual-auth__cards {
  gap: 10px;
  width: 100%;
}
.individual-auth__proof {
  gap: 20px;
  height: 100%;
  z-index: 1;
  display: none;
}
@media (max-width: 767.98px) {
  .individual-auth__proof {
    justify-content: flex-start;
  }
}
.individual-auth__proof--active {
  display: flex;
}
.individual-auth__proof-title {
  font-size: 30px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.6px;
}
.individual-auth__proof-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-auth__proof-validation {
  color: #3B952D;
  gap: 10px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-auth__proof-validation .zmdi {
  font-size: 15px;
}
.individual-auth__proof-docs {
  gap: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-auth__proof-docs:hover, .individual-auth__proof-docs:focus {
  border-color: #00A6C3;
}
.individual-auth__proof-docs--active {
  border-color: #00A6C3;
}
.individual-auth__proof-docs-container {
  gap: 10px;
  width: 100%;
}
.individual-auth__proof-docs-icon {
  height: 35px;
  width: 35px;
}
.individual-auth__proof-docs-text {
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.individual-auth__proof-caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-auth__button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
@media (min-width: 992px) {
  .individual-auth__button {
    display: none;
  }
}
.individual-auth__bottom {
  width: 100%;
  gap: 20px;
}
.individual-auth__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-auth__upload-button {
  width: 100%;
}
.individual-auth__link {
  width: 100%;
  padding: 10px 0;
}
.individual-auth__link-top {
  align-self: end;
  width: auto;
}
@media (max-width: 767.98px) {
  .individual-auth__link-top {
    display: none;
  }
}

/*=====  End of Individual Authentication  ======*/
.login-country {
  position: relative;
  gap: 80px;
}
.login-country__items {
  gap: 20px;
}
.login-country__items-inner {
  padding-right: 20px;
  gap: 10px;
  max-height: 363px;
  overflow-y: auto;
}
.login-country__items-inner::-webkit-scrollbar {
  width: 4px;
}
.login-country__items-inner::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.login-country__items-inner::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.login-country__items-inner::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.login-country__item {
  padding: 7.5px 10px;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.login-country__item:hover {
  background-color: #0D518B;
  border: 1px solid #0D518B;
  color: #fff;
}
.login-country__item-image {
  width: 35px;
  height: 35px;
}
.login-country__item-name {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.login-country__item--active {
  background-color: #0D518B;
  border: 1px solid #0D518B;
  color: #fff;
}
.login-country__left, .login-country__right {
  width: 100%;
}
.login-country__bottom {
  gap: 10px;
  opacity: 0.5;
}
.login-country__bottom-icon {
  font-size: 21px;
  transform: translateY(2%);
}
.login-country__bottom-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.login-country__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
.login-country__left-title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .login-country__left-title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
@media (min-width: 1921px) {
  .login-country__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .login-country__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .login-country__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .login-country__left {
    padding: 49px 30px;
  }
}
.login-country__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .login-country__right {
    display: none;
  }
}

@media (max-width: 479.98px) {
  .login-country__bottom-text {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
  }
}
/*=============================================
=            Individual Email Confirm            =
=============================================*/
.email-confirm {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .email-confirm {
    gap: 60px;
  }
}
.email-confirm__image {
  height: 64px;
  width: 64px;
}
.email-confirm__image-container {
  position: relative;
  padding: 60px 0;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 150px;
  height: 200px;
}
@media (max-width: 767.98px) {
  .email-confirm__image-container {
    margin: auto;
  }
}
.email-confirm__icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 45px;
  width: 45px;
}
.email-confirm__icon--red {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  background-color: #CD2A2A;
  font-size: 20px;
}
.email-confirm__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .email-confirm__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.email-confirm__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.email-confirm__left, .email-confirm__right {
  width: 100%;
}
.email-confirm__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .email-confirm__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .email-confirm__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .email-confirm__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .email-confirm__left {
    padding: 30px 30px 38px;
  }
}
.email-confirm__button {
  width: 100%;
}
.email-confirm__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .email-confirm__right {
    display: none;
  }
}
.email-confirm__resend {
  gap: 20px;
}
.email-confirm__bottom {
  width: 100%;
  gap: 20px;
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.email-confirm__bottom .link {
  padding: 10px 0;
}
@media (max-width: 767.98px) {
  .email-confirm__next {
    width: 100%;
  }
}
.email-confirm__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.email-confirm__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}

/*=====  End of Individual Email Confirm  ======*/
/*=============================================
=            Individual Forgot Password            =
=============================================*/
.individual-forgot-pass {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .individual-forgot-pass {
    gap: 60px;
  }
}
.individual-forgot-pass__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .individual-forgot-pass__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.individual-forgot-pass__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-forgot-pass__left, .individual-forgot-pass__right {
  width: 100%;
}
.individual-forgot-pass__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .individual-forgot-pass__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .individual-forgot-pass__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .individual-forgot-pass__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .individual-forgot-pass__left {
    padding: 49px 30px;
  }
}
.individual-forgot-pass__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .individual-forgot-pass__right {
    display: none;
  }
}
.individual-forgot-pass__form, .individual-forgot-pass__inputs, .individual-forgot-pass__bottom {
  width: 100%;
  gap: 20px;
}
.individual-forgot-pass__form input[type=email] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-forgot-pass__form input[type=email]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.individual-forgot-pass__button {
  width: 100%;
}
.individual-forgot-pass__field {
  gap: 5px;
}
.individual-forgot-pass__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.individual-forgot-pass__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-forgot-pass__validation--active {
  display: block;
}
.individual-forgot-pass__bottom {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.individual-forgot-pass__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-forgot-pass__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.individual-forgot-pass__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (min-width: 1921px) {
  .individual-forgot-pass__image {
    width: auto;
  }
}
.individual-forgot-pass-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-forgot-pass-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Individual Forgot Password  ======*/
/*=============================================
=            Individual Profile            =
=============================================*/
.individual-profile {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .individual-profile {
    gap: 60px;
  }
}
.individual-profile__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .individual-profile__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.individual-profile__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-profile__left, .individual-profile__right {
  width: 100%;
}
.individual-profile__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .individual-profile__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .individual-profile__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .individual-profile__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .individual-profile__left {
    padding: 30px;
  }
}
.individual-profile__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .individual-profile__right {
    display: none;
  }
}
.individual-profile__form, .individual-profile__inputs, .individual-profile__bottom {
  width: 100%;
  gap: 20px;
}
.individual-profile__form input[type=text],
.individual-profile__form input[type=password],
.individual-profile__form select {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.individual-profile__form input[type=text]::placeholder,
.individual-profile__form input[type=password]::placeholder,
.individual-profile__form select::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.individual-profile__form select {
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-black-icon.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.5);
  height: auto;
  cursor: pointer;
}
.individual-profile__form .individual-profile__country--active {
  color: #000;
}
.individual-profile__form option {
  color: #000;
}
.individual-profile__form option:first-child {
  color: rgba(0, 0, 0, 0.5);
}
.individual-profile__form input[type=date] {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 10px 20px;
  padding-inline-end: 15px;
  height: 40px;
  cursor: default;
  width: 100%;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.individual-profile__form input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
.individual-profile__dob-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.individual-profile__dob-icon {
  height: 16px;
  width: 20px;
  object-fit: contain;
}
.individual-profile__dob-icon-container {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  height: 30px;
  width: 50px;
  background-color: #fff;
  border-radius: 5px;
  pointer-events: none;
}
.individual-profile__dob-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  padding-inline-start: 20px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  height: 30px;
  width: calc(100% - 50px);
  pointer-events: none;
}
.individual-profile__dob-overlay--hidden {
  display: none;
}
.individual-profile__optional {
  color: rgba(0, 56, 101, 0.8);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-profile__button {
  width: 100%;
}
.individual-profile__field {
  gap: 5px;
}
.individual-profile__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.individual-profile__label-required {
  color: #CD2A2A;
}
.individual-profile__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-profile__validation--active {
  display: block;
}
.individual-profile__bottom {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.individual-profile__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-profile__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.individual-profile__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-profile-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-profile-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Individual Profile  ======*/
/*=============================================
=            Individual Reset Password            =
=============================================*/
.individual-reset-pass {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .individual-reset-pass {
    gap: 60px;
  }
}
.individual-reset-pass__title {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .individual-reset-pass__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.individual-reset-pass__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-reset-pass__left, .individual-reset-pass__right {
  width: 100%;
}
.individual-reset-pass__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .individual-reset-pass__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .individual-reset-pass__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .individual-reset-pass__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .individual-reset-pass__left {
    padding: 30px;
  }
}
.individual-reset-pass__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .individual-reset-pass__right {
    display: none;
  }
}
.individual-reset-pass__form, .individual-reset-pass__inputs, .individual-reset-pass__bottom {
  width: 100%;
  gap: 20px;
}
.individual-reset-pass__form input[type=text], .individual-reset-pass__form input[type=password] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 35px 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-reset-pass__form input[type=text]::placeholder, .individual-reset-pass__form input[type=password]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.individual-reset-pass__form .zmdi:hover {
  opacity: 0.7;
}
.individual-reset-pass__form .zmdi-eye {
  display: none;
}
.individual-reset-pass__form .zmdi-eye--active {
  display: block;
}
.individual-reset-pass__form .zmdi-eye-off {
  display: none;
}
.individual-reset-pass__form .zmdi-eye-off--active {
  display: block;
}
.individual-reset-pass__password-icon {
  position: absolute;
  bottom: 50%;
  right: 20px;
  z-index: 1;
  transform: translateY(50%);
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-reset-pass__box {
  position: relative;
}
.individual-reset-pass__button {
  width: 100%;
}
.individual-reset-pass__field {
  position: relative;
  gap: 5px;
}
.individual-reset-pass__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.individual-reset-pass__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-reset-pass__validation--active {
  display: block;
}
.individual-reset-pass__criterias {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
  gap: 10px;
  width: 100%;
}
.individual-reset-pass__criterias-text {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-reset-pass__list {
  gap: 10px;
}
.individual-reset-pass__item {
  gap: 5px;
  height: 100%;
}
.individual-reset-pass__item--active .individual-reset-pass__item-icon {
  border-color: #0D518B;
  color: #0D518B;
}
.individual-reset-pass__item--active .individual-reset-pass__item-icon .zmdi {
  opacity: 1;
  color: #0D518B;
}
.individual-reset-pass__item--active .rte {
  color: rgba(0, 0, 0, 0.3);
}
.individual-reset-pass__item .rte {
  height: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-reset-pass__item-icon {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  font-size: 12px;
}
.individual-reset-pass__item-icon .zmdi {
  opacity: 0;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-reset-pass__bottom {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.individual-reset-pass__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-reset-pass__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-reset-pass__validation--active {
  display: block;
}
.individual-reset-pass__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.individual-reset-pass__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-reset-pass-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-reset-pass-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Individual Reset Password  ======*/
/*=============================================
=            Individual Sign In Details            =
=============================================*/
.sign-in-details {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .sign-in-details {
    gap: 60px;
  }
}
.sign-in-details__title {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .sign-in-details__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.sign-in-details__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.sign-in-details__left, .sign-in-details__right {
  width: 100%;
}
.sign-in-details__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .sign-in-details__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .sign-in-details__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .sign-in-details__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .sign-in-details__left {
    padding: 30px;
  }
}
.sign-in-details__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .sign-in-details__right {
    display: none;
  }
}
.sign-in-details__back.link {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  padding: 0;
  gap: 0;
}
.sign-in-details__form, .sign-in-details__inputs, .sign-in-details__bottom {
  width: 100%;
  gap: 20px;
}
.sign-in-details__form input[type=text],
.sign-in-details__form input[type=password] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 35px 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.sign-in-details__form input[type=text]::placeholder,
.sign-in-details__form input[type=password]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sign-in-details__form .zmdi:hover {
  opacity: 0.7;
}
.sign-in-details__form .zmdi-eye {
  display: none;
}
.sign-in-details__form .zmdi-eye--active {
  display: block;
}
.sign-in-details__form .zmdi-eye-off {
  display: none;
}
.sign-in-details__form .zmdi-eye-off--active {
  display: block;
}
.sign-in-details__password-icon {
  position: absolute;
  bottom: 50%;
  right: 20px;
  z-index: 1;
  transform: translateY(50%);
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.sign-in-details__box {
  position: relative;
}
.sign-in-details__button {
  width: 100%;
}
.sign-in-details__field {
  gap: 5px;
}
.sign-in-details__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.sign-in-details__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.sign-in-details__validation--active {
  display: block;
}
.sign-in-details__criterias {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
  gap: 10px;
  width: 100%;
}
.sign-in-details__criterias-text {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.sign-in-details__list {
  gap: 10px;
}
.sign-in-details__item {
  gap: 5px;
  height: 100%;
}
.sign-in-details__item--active .sign-in-details__item-icon {
  border-color: #0D518B;
  color: #0D518B;
}
.sign-in-details__item--active .sign-in-details__item-icon .zmdi {
  opacity: 1;
  color: #0D518B;
}
.sign-in-details__item--active .rte {
  color: rgba(0, 0, 0, 0.3);
}
.sign-in-details__item .rte {
  height: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.sign-in-details__item-icon {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  font-size: 12px;
}
.sign-in-details__item-icon .zmdi {
  opacity: 0;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.sign-in-details__bottom {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sign-in-details__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.sign-in-details__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.sign-in-details__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.sign-in-details-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.sign-in-details-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Individual Sign In Details  ======*/
/*=============================================
=            Verify Email            =
=============================================*/
.verify-email {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .verify-email {
    gap: 60px;
  }
}
.verify-email__image {
  height: 64px;
  width: 64px;
}
.verify-email__image-container {
  padding: 60px 0;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 150px;
  height: 200px;
}
@media (max-width: 767.98px) {
  .verify-email__image-container {
    margin: auto;
  }
}
.verify-email__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .verify-email__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.verify-email__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.verify-email__left, .verify-email__right {
  width: 100%;
}
.verify-email__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .verify-email__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .verify-email__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .verify-email__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .verify-email__left {
    padding: 30px 30px 38px;
  }
}
.verify-email__left .link {
  padding: 10px 0;
}
.verify-email__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .verify-email__right {
    display: none;
  }
}
.verify-email__resend {
  gap: 20px;
}
.verify-email__bottom {
  width: 100%;
  gap: 20px;
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.verify-email__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.verify-email__caption-resent {
  font-weight: 800;
}
.verify-email__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}

/*=====  End of Verify Email  ======*/
.key-facts {
  position: relative;
  gap: 120px;
}
.key-facts__left {
  gap: 20px;
  flex: 0.8;
}
.key-facts__left-line {
  width: 100%;
  height: 1px;
  background-color: #00A6C3;
}
.key-facts__left-title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.key-facts__left .rte {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.key-facts__right {
  flex: 1;
  gap: 30px;
}
.key-facts__right-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.key-facts__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.key-facts__card {
  padding: 15px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 5px;
  gap: 10px;
}
.key-facts__card-title {
  font-size: 30px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.6px;
  position: relative;
}
.key-facts__card-title::before, .key-facts__card-title::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  margin: 0 10px;
  background-color: #fff;
  opacity: 0.5;
}
.key-facts__card-content {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.key-facts__card-title, .key-facts__card-content {
  text-align: center;
}

@media (max-width: 1249.98px) {
  .key-facts {
    gap: 60px;
  }
}
@media (max-width: 991.98px) {
  .key-facts {
    flex-direction: column;
    gap: 30px;
  }
  .key-facts__left-title {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
  .key-facts__cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .key-facts__right {
    gap: 10px;
  }
}
/*=============================================
=            Link            =
=============================================*/
.link {
  cursor: pointer;
  padding: 10px 15px;
  gap: 10px;
  min-width: max-content;
  border-radius: 20px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.link__image {
  width: 14px;
  height: 16px;
  object-fit: contain;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.link--primary {
  background-color: #0D518B;
  color: #fff;
}
.link--primary-white {
  background-color: #fff;
  color: #0D518B;
}
.link--primary-white-thin {
  border: 1px solid #0D518B;
  color: #0D518B;
  background-color: #fff;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.link--primary-white-thin .zmdi {
  font-size: 15px;
}
.link--primary-white-thin:hover, .link--primary-white-thin:focus {
  border-color: #000;
}
.link--primary-white-thin:hover .link__image, .link--primary-white-thin:focus .link__image {
  filter: grayscale(1);
}
.link--primary-white:hover, .link--primary-white:focus {
  color: #000;
}
.link--primary:hover, .link--primary:focus {
  color: #fff;
  background-color: #000;
}
.link--simple {
  padding: 0;
  color: #0D518B;
  border-radius: 0;
}
.link--simple:hover, .link--simple:focus {
  color: #000;
}
.link--simple-white {
  padding: 0;
  color: #fff;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.link--simple-white:hover, .link--simple-white:focus {
  border-color: #fff;
}
.link--simple-white.link--active {
  border-color: #fff;
}

/*=====  End of Link  ======*/
.loader {
  margin: auto;
  display: none;
}
.loader--active {
  display: flex;
}

/*=============================================
=            Login Component            =
=============================================*/
.login {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .login {
    gap: 60px;
  }
}
.login__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .login__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.login__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.login__left, .login__right {
  width: 100%;
}
.login__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .login__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .login__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .login__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .login__left {
    padding: 49px 30px;
  }
}
.login__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .login__right {
    display: none;
  }
}
.login__form, .login__inputs, .login__bottom {
  width: 100%;
  gap: 20px;
}
.login__form input[type=text], .login__form input[type=password] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.login__form input[type=text]::placeholder, .login__form input[type=password]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.login__button {
  width: 100%;
}
.login__field {
  gap: 5px;
}
.login__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.login__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.login__validation--active {
  display: block;
}
.login__bottom {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.login__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.login__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.login__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (min-width: 1921px) {
  .login__image {
    width: auto;
  }
}
.login-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.login-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Login Component  ======*/
/*=============================================
=            Media Carousel            =
=============================================*/
.media-carousel {
  max-width: 1440px;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.media-carousel__wrapper.background--blue .media-carousel__carousel-track::after {
  background: linear-gradient(to right, #e5f6f9 0%, rgba(255, 255, 255, 0) 100%);
}
.media-carousel__wrapper.background--blue .media-carousel__carousel-track::before {
  background: linear-gradient(to left, #e5f6f9 0%, rgba(255, 255, 255, 0) 100%);
}
.media-carousel__inner {
  gap: 120px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 90px 0;
}
@media (max-width: 1249.98px) {
  .media-carousel__inner {
    gap: 60px;
  }
}
@media (max-width: 991.98px) {
  .media-carousel__inner {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .media-carousel__inner {
    padding: 60px 0;
  }
}
.media-carousel__content {
  flex: 0.846;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .media-carousel__content {
    padding-right: 120px;
  }
}
@media (max-width: 767.98px) {
  .media-carousel__content {
    padding-right: 30px;
  }
}
@media (max-width: 991.98px) {
  .media-carousel__content.container--padding-right {
    padding-left: 120px;
  }
}
@media (max-width: 767.98px) {
  .media-carousel__content.container--padding-right {
    padding-left: 30px;
  }
}
.media-carousel__carousel {
  flex: 1;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .media-carousel__carousel {
    width: 100%;
    padding-left: 120px;
  }
}
@media (max-width: 767.98px) {
  .media-carousel__carousel {
    padding-left: 30px;
  }
}
@media (max-width: 479.98px) {
  .media-carousel__carousel {
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .media-carousel__carousel.container--padding-left {
    width: 100%;
    padding-right: 120px;
  }
}
@media (max-width: 767.98px) {
  .media-carousel__carousel.container--padding-left {
    padding-right: 30px;
  }
}
@media (max-width: 479.98px) {
  .media-carousel__carousel.container--padding-left {
    padding: 0;
  }
}
.media-carousel__carousel-slides {
  min-height: 320px;
}
@media (max-width: 479.98px) {
  .media-carousel__carousel-slides {
    min-height: 260px;
  }
}
.media-carousel__carousel-inner {
  position: relative;
  overflow: hidden;
  padding: 0 20px;
}
.media-carousel__carousel-track {
  position: relative;
}
.media-carousel__carousel-track::after {
  content: "";
  width: 20%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translateX(-20px);
  background: linear-gradient(to right, #ffffff 0px, #ffffff 20px, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 479.98px) {
  .media-carousel__carousel-track::after {
    display: none;
  }
}
.media-carousel__carousel-track::before {
  content: "";
  width: 20%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(20px);
  background: linear-gradient(to left, #ffffff 0, #ffffff 20px, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}
@media (max-width: 479.98px) {
  .media-carousel__carousel-track::before {
    display: none;
  }
}
.media-carousel__heading {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .media-carousel__heading {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.media-carousel__card {
  overflow: hidden;
  border-radius: 10px;
  height: 190px;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.media-carousel__card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.media-carousel__title {
  flex: 1;
  align-items: center;
  margin-block-start: 30px;
  font-size: 26px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.52px;
}
.media-carousel__arrow {
  background-color: #0D518B;
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.media-carousel__arrow .zmdi {
  font-size: 15px;
}
.media-carousel__arrow:hover, .media-carousel__arrow:focus {
  color: #fff;
  background-color: #000;
}
.media-carousel__controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
@media (max-width: 479.98px) {
  .media-carousel__controls {
    display: none;
  }
}
.media-carousel__controls--prev {
  left: 0;
}
.media-carousel__controls--next {
  right: 0;
}
.media-carousel .glide__slide--clone {
  height: 190px;
}
.media-carousel .glide__slide--active {
  height: 320px;
}
@media (max-width: 479.98px) {
  .media-carousel .glide__slide--active {
    height: 260px;
  }
}

/*=====  End of Media Carousel  ======*/
/*===================================================================
=            Media With Text            =
=====================================================================*/
.media-with-text {
  padding: 90px 0;
}
.media-with-text__wrapper {
  position: relative;
}
@media (max-width: 991.98px) {
  .media-with-text {
    padding: 60px 0;
  }
}
.media-with-text__left, .media-with-text__right {
  flex: 1;
}
.media-with-text__inner {
  gap: 120px;
}
@media (max-width: 991.98px) {
  .media-with-text__inner.flex--row-reverse {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  .media-with-text__inner {
    flex-direction: column;
    gap: 40px;
  }
}
.media-with-text__left {
  box-sizing: border-box;
  gap: 30px;
}
.media-with-text__right {
  position: relative;
  min-height: auto;
  border-radius: 10px;
  overflow: hidden;
  min-width: 600px;
}
@media (max-width: 1439.98px) {
  .media-with-text__right {
    min-width: 400px;
    display: grid;
  }
}
@media (max-width: 479.98px) {
  .media-with-text__right {
    height: 240px;
    min-height: 240px;
    min-width: 100%;
  }
}
.media-with-text__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 479.98px) {
  .media-with-text__video {
    height: 100%;
  }
}
.media-with-text__image {
  height: auto;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
  border-radius: 10px;
  margin: auto;
}
.media-with-text__title {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .media-with-text__title {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}

/*=====  End of Media With Text  ======*/
/*=============================================
=            Menu Mobile            =
=============================================*/
.menu-mobile__icon-container {
  z-index: 99999999;
  padding: 8px 0px 17px 8px;
  position: relative;
  cursor: pointer;
}
@media (min-width: 1250px) {
  .menu-mobile__icon-container {
    display: none;
  }
}
.menu-mobile__icon {
  width: 20px;
  height: 7px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  cursor: pointer;
  z-index: 9999999;
  height: 100%;
}
.menu-mobile__icon-bar {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #000;
  border-radius: 9px;
  border-radius: 12px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.menu-mobile__icon-bar:nth-child(1) {
  top: 0px;
  right: 0;
  left: auto;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}
.menu-mobile__icon-bar:nth-child(2) {
  top: 8px;
  right: 0;
  left: auto;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
  width: 80%;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -2px;
  left: auto;
  right: auto;
  width: 20px;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 12.5px;
  left: auto;
  width: 20px;
}
.menu-mobile__overlay {
  position: fixed;
  top: 70px;
  right: 0;
  width: 50%;
  width: 100%;
  height: calc(100% - 71px);
  z-index: 102;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  color: #0D518B;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (min-width: 1250px) {
  .menu-mobile__overlay {
    display: none;
  }
}
.menu-mobile__overlay--active {
  opacity: 1;
  visibility: visible;
}
.menu-mobile__inner {
  z-index: 101;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  height: calc(100% + 1px);
  width: 50%;
}
@media (max-width: 767.98px) {
  .menu-mobile__inner {
    width: 70%;
  }
}
@media (max-width: 479.98px) {
  .menu-mobile__inner {
    width: 100%;
  }
}
.menu-mobile__nested {
  z-index: 102;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0D518B;
  color: #fff;
  height: calc(100% + 1px);
  width: 50%;
  padding: 30px;
  display: none;
}
.menu-mobile__nested--active {
  display: block;
}
@media (max-width: 767.98px) {
  .menu-mobile__nested {
    width: 70%;
  }
}
@media (max-width: 479.98px) {
  .menu-mobile__nested {
    width: 100%;
  }
}
.menu-mobile__nested-top {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-mobile__nested-subtitle {
  font-size: 26px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.52px;
}
.menu-mobile__nested-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.menu-mobile__nested-list {
  width: 100%;
  gap: 20px;
}
.menu-mobile__nested-list-item {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-mobile__nested-list-item:last-child {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-mobile__nested-bottom {
  gap: 20px;
}
.menu-mobile__nested-link {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.menu-mobile__nested-link:hover, .menu-mobile__nested-link:focus {
  color: #000;
}
.menu-mobile__nested-link-inner {
  gap: 5px;
}
.menu-mobile__nested-link-title {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.menu-mobile__nested-link-subtitle {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.menu-mobile__top {
  background: linear-gradient(180deg, #5ADDF4 0%, #00A6C3 100%);
  padding: 30px;
  gap: 20px;
}
.menu-mobile__top-title {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.menu-mobile__top-links {
  width: 100%;
  gap: 10px;
}
.menu-mobile__top .link {
  width: 100%;
  padding: 10px 20px;
}
.menu-mobile__bottom {
  gap: 15px;
  padding: 30px;
  height: calc(100% - 145.5px);
  overflow: auto;
}
.menu-mobile__bottom .link {
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
.menu-mobile__item {
  width: 100%;
  position: relative;
  padding-bottom: 15px;
}
.menu-mobile__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.menu-mobile__item:nth-last-child(2)::after {
  content: normal;
}
.menu-mobile__socials {
  gap: 30px;
  width: 100%;
}
.menu-mobile__socials-container {
  margin-top: auto;
}
.menu-mobile__social {
  font-size: 22px;
}
.menu-mobile__social-link {
  height: 20px;
  width: 20px;
}
.menu-mobile__social-link:hover, .menu-mobile__social-link:focus {
  color: #000;
}
.menu-mobile__button {
  width: 100%;
  justify-content: flex-start;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}

/*=====  End of Menu Mobile  ======*/
/*=============================================
=            Menu Mobile Platform            =
=============================================*/
.menu-mobile-platform__icon-container {
  z-index: 99999999;
  padding: 8px 0px 17px 8px;
  position: relative;
  cursor: pointer;
}
@media (min-width: 1250px) {
  .menu-mobile-platform__icon-container {
    display: none;
  }
}
.menu-mobile-platform__icon {
  width: 20px;
  height: 7px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  cursor: pointer;
  z-index: 9999999;
  height: 100%;
}
.menu-mobile-platform__icon-bar {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  border-radius: 12px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.menu-mobile-platform__icon-bar:nth-child(1) {
  top: 0px;
  right: 0;
  left: auto;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}
.menu-mobile-platform__icon-bar:nth-child(2) {
  top: 8px;
  left: 0;
  left: auto;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
  width: 80%;
}
.menu-mobile-platform__icon--open .menu-mobile-platform__icon-bar:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -2px;
  left: auto;
  right: auto;
  width: 20px;
}
.menu-mobile-platform__icon--open .menu-mobile-platform__icon-bar:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 12.5px;
  left: auto;
  width: 20px;
}
.menu-mobile-platform__overlay {
  position: fixed;
  top: 70px;
  right: 0;
  width: 100%;
  height: calc(100% - 71px);
  z-index: 102;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  color: #0D518B;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (min-width: 1250px) {
  .menu-mobile-platform__overlay {
    display: none;
  }
}
.menu-mobile-platform__overlay--active {
  opacity: 1;
  visibility: visible;
}
.menu-mobile-platform__inner {
  z-index: 101;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0D518B;
  color: #fff;
  height: calc(100% + 1px);
  width: 50%;
  gap: 15px;
  padding-block-end: 60px;
  overflow-y: auto;
}
@media (max-width: 767.98px) {
  .menu-mobile-platform__inner {
    width: 70%;
  }
}
@media (max-width: 479.98px) {
  .menu-mobile-platform__inner {
    width: 100%;
  }
}
.menu-mobile-platform__inner::-webkit-scrollbar {
  width: 4px;
}
.menu-mobile-platform__inner::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
.menu-mobile-platform__inner::-webkit-scrollbar-thumb {
  background: #000;
}
.menu-mobile-platform__inner::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.menu-mobile-platform__links {
  gap: 15px;
  padding-inline: 30px;
  margin-block-start: 113px;
}
.menu-mobile-platform__link {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
.menu-mobile-platform__link-item {
  padding-block-end: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.menu-mobile-platform__middle {
  padding-block-start: 20px;
  gap: 20px;
  margin-block-end: auto;
}
.menu-mobile-platform__middle-title {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.menu-mobile-platform__bottom {
  width: 100%;
  padding-inline: 30px;
  margin-block-start: 113px;
}
.menu-mobile-platform__header {
  padding-inline: 30px;
  padding-block: 7px;
}
.menu-mobile-platform__payment-link {
  margin-inline: 30px;
  width: fit-content;
}
.menu-mobile-platform__carousel {
  color: #000;
  padding-inline: 30px 0;
}
.menu-mobile-platform__carousel-inner {
  overflow: hidden;
}
.menu-mobile-platform__carousel .dashboard-contact__info-text {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.menu-mobile-platform__carousel .dashboard-contact__pill {
  border-radius: 10px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.menu-mobile-platform__carousel .dashboard-contact__icon {
  color: #0D518B;
}
.menu-mobile-platform__carousel .dashboard-contact__image-container {
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
}
.menu-mobile-platform__carousel .dashboard-contact__image-placeholder {
  padding: 12px;
}

/*=====  End of Menu Mobile Platform  ======*/
/*=============================================
=            My Account Component            =
=============================================*/
.account {
  display: flex;
  padding: 0;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .account {
    flex-direction: column;
  }
}
.account__notify {
  position: absolute;
  top: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 5px 10px;
  max-width: 250px;
  color: #fff;
  text-align: center;
  z-index: 1;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1019607843);
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .account__notify {
    position: static;
    max-width: none;
    transform: none;
  }
}
.account__notify--success {
  display: none;
  background-color: #3B952D;
}
.account__notify--success.active {
  display: flex;
}
.account__notify--fail {
  display: none;
  background-color: #CD2A2A;
}
.account__notify--fail.active {
  display: flex;
}
.account__wrapper {
  display: none;
  background-image: linear-gradient(to left, rgb(255, 255, 255) 50%, rgba(0, 166, 195, 0.05) 50%);
}
@media (max-width: 767.98px) {
  .account__wrapper {
    background-image: none;
  }
}
.account__wrapper--active {
  display: block;
}
.account__divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
.account__title {
  margin: auto;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .account__title {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.account__top {
  position: relative;
  padding: 30px 80px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .account__top {
    padding: 20px;
  }
}
.account__subtitle {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .account__subtitle {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.account__left, .account__right {
  width: 100%;
  flex: 1;
  padding-block: 80px;
  padding-inline: 80px;
}
@media (max-width: 991.98px) {
  .account__left, .account__right {
    padding-inline: 30px;
  }
}
@media (max-width: 767.98px) {
  .account__left, .account__right {
    padding-block: 30px;
  }
}
.account__left {
  gap: 20px;
  padding-block: 80px;
  padding-inline: 80px;
  text-align: center;
  min-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  .account__left {
    padding-inline: 30px;
  }
}
@media (max-width: 767.98px) {
  .account__left {
    padding-block: 30px;
    min-height: auto;
    max-height: none;
    background-color: rgba(0, 166, 195, 0.05);
  }
}
.account__left::-webkit-scrollbar {
  width: 4px;
}
.account__left::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.account__left::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.account__left::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.account__left-top {
  gap: 20px;
}
.account__left hr {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  min-height: 1px;
}
.account__left .link {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .account__left .link {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.account__right {
  gap: 20px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .account__right {
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.account__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.account__image--default {
  padding: 40px;
}
.account__image-container {
  height: 190px;
  width: 190px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: #fff;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .account__image-container {
    height: 140px;
    width: 140px;
  }
}
.account__name {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .account__name {
    font-size: 23px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.23px;
  }
}
.account__text {
  opacity: 0.5;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.account__button, .account__upload {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.account__subtitle {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.account__items {
  width: 100%;
  gap: 20px;
}
.account__item {
  width: 100%;
  gap: 10px;
}
.account__item-address {
  margin-block-end: 20px;
}
.account__item-label {
  color: rgba(0, 0, 0, 0.5);
  padding-block-end: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.account__item-left {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.account__item-right {
  opacity: 0.8;
  margin-inline-end: auto;
}
.account__item-right .rte div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.account__upload-form {
  gap: 5px;
  text-align: center;
}
.account__upload-input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.account__upload-input:disabled + label p {
  cursor: not-allowed;
  opacity: 0.25;
}
.account__upload-label {
  cursor: pointer;
}
.account__upload-placeholder {
  color: #0D518B;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.account__upload-placeholder:hover, .account__upload-placeholder:focus {
  color: #000;
}

/*=====  End of My Account Component  ======*/
/*=============================================
=            Navbar            =
=============================================*/
.navbar {
  position: relative;
  background-color: #0D518B;
  color: #fff;
  padding: 20px 0;
  max-height: 80px;
  height: 80px;
}
@media (max-width: 1249.98px) {
  .navbar {
    padding: 20px 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 70px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
}
.navbar__inner {
  margin: 0 auto;
  padding: 0 60px;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 1249.98px) {
  .navbar__inner {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .navbar__inner {
    padding: 0 30px;
  }
}
@media (max-width: 479.98px) {
  .navbar__inner {
    padding: 0 20px;
  }
}
.navbar__logo {
  display: block;
  max-height: 24px;
  width: auto;
}
@media (max-width: 1249.98px) {
  .navbar__logo {
    display: none;
  }
}
.navbar__logo-mobile {
  display: block;
  max-height: 30px;
  transform: translateY(0);
}
@media (min-width: 1250px) {
  .navbar__logo-mobile {
    display: none;
  }
}
.navbar__logo-container {
  min-width: fit-content;
  margin-right: auto;
}
.navbar__links {
  gap: 30px;
}
@media (max-width: 1439.98px) {
  .navbar__links {
    gap: 15px;
  }
}
@media (max-width: 1249.98px) {
  .navbar__links {
    display: none;
  }
}
.navbar__links-item {
  height: 100%;
  min-width: fit-content;
}
.navbar__links-item:last-of-type {
  position: relative;
  padding-right: 30px;
}
@media (max-width: 1439.98px) {
  .navbar__links-item:last-of-type {
    padding-right: 15px;
  }
}
.navbar__links-item:last-of-type:after {
  content: "";
  position: absolute;
  right: 0;
  top: -8px;
  height: 38px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}
.navbar__login {
  font-weight: 700;
  line-height: 133.33%;
}
.navbar__sticky-button {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 1;
}
@media (min-width: 1250px) {
  .navbar__sticky-button {
    display: none;
  }
}

/*=====  End of Navbar  ======*/
/*=============================================
=            New Payment Contacts Component            =
=============================================*/
.new-pay {
  display: flex;
  padding-inline-start: 80px;
  position: relative;
}
@media (max-width: 991.98px) {
  .new-pay {
    padding-inline-start: 40px;
  }
}
@media (max-width: 767.98px) {
  .new-pay {
    padding-inline-start: 0;
    flex-direction: column-reverse;
  }
}
.new-pay__divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
@media (max-width: 767.98px) {
  .new-pay__divider {
    display: none;
  }
}
.new-pay__title {
  margin: auto;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .new-pay__title {
    display: flex;
    align-items: center;
    min-height: 40px;
    text-align: center;
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.new-pay__top {
  position: relative;
  padding: 30px 80px;
}
@media (max-width: 991.98px) {
  .new-pay__top {
    padding-inline: 20px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__top {
    padding-block: 20px;
    margin-block-end: 20px;
    gap: 20px;
  }
  .new-pay__top::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.new-pay__info {
  padding-inline-end: 80px;
}
@media (max-width: 767.98px) {
  .new-pay__info {
    padding-inline: 20px;
  }
}
.new-pay__subtitle {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .new-pay__subtitle {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.new-pay__number {
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (min-width: 768px) {
  .new-pay__number {
    display: none;
  }
}
.new-pay__left, .new-pay__right {
  width: 100%;
}
.new-pay__left {
  flex: 0.89;
  gap: 30px;
  padding-block-start: 40px;
  padding-block-end: 30px;
  min-height: 750px;
}
@media (max-width: 991.98px) {
  .new-pay__left {
    flex: 0.8;
  }
}
@media (max-width: 767.98px) {
  .new-pay__left {
    flex: 1;
    padding-block-start: 0;
    padding-block-end: 20px;
    min-height: auto;
    gap: 20px;
  }
}
.new-pay__right {
  flex: 1;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-block: 40px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .new-pay__right {
    padding-block: 0 20px;
    margin-block-end: 20px;
  }
  .new-pay__right::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 1000%;
  }
  .new-pay__right::-webkit-scrollbar {
    width: 4px;
  }
  .new-pay__right::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  .new-pay__right::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 2px;
  }
  .new-pay__right::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}
.new-pay__skeleton {
  width: 100%;
  background-color: rgba(0, 166, 195, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-block: 17px;
  padding-inline: 10px 15px;
  max-height: 90px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.new-pay__skeleton:hover {
  border-color: #000;
}
.new-pay__skeleton:hover .new-pay__button {
  background-color: #000;
}
.new-pay__skeleton-left {
  width: 100%;
  gap: 10px;
}
.new-pay__bars {
  width: 100%;
  height: 100%;
  gap: 10px;
}
.new-pay__bar {
  border-radius: 3px;
  opacity: 0.1;
}
.new-pay__bar:nth-child(1) {
  width: 75.398%;
  background-color: #0D518B;
  height: 12px;
}
.new-pay__bar:nth-child(2) {
  width: 58.745%;
  background-color: #0D518B;
  height: 12px;
}
.new-pay__bar:nth-child(3) {
  width: 27.645%;
  background-color: #0D518B;
  height: 12px;
}
.new-pay__button {
  cursor: pointer;
  padding: 10px 15px;
  gap: 10px;
  min-width: max-content;
  border-radius: 20px;
  background-color: #0D518B;
  color: #fff;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.new-pay__button:hover, .new-pay__button:focus {
  background-color: #000;
}
.new-pay__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.new-pay__image-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  background-color: #fff;
  object-fit: contain;
  padding: 16px;
}
.new-pay__form, .new-pay__inputs {
  width: 100%;
  gap: 20px;
}
@media (max-width: 767.98px) {
  .new-pay__inputs .new-pay__label {
    width: 50%;
    min-width: 300px;
  }
}
@media (max-width: 479.98px) {
  .new-pay__inputs .new-pay__label {
    width: 100%;
    min-width: auto;
  }
}
.new-pay__form {
  padding-inline-end: 80px;
}
@media (max-width: 991.98px) {
  .new-pay__form {
    padding-inline-end: 60px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__form-desktop {
    display: none;
  }
}
.new-pay__form-mobile {
  padding-inline-end: 0;
}
@media (min-width: 768px) {
  .new-pay__form-mobile {
    display: none;
  }
}
.new-pay__form input[type=text] {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline-color: #000;
  width: 100%;
  padding-block: 10px;
  padding-inline: 20px 41px;
  background-color: #fff;
  background-image: url("/assets/img/img/search-icon.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position-x: calc(100% - 16px);
  background-position-y: 13px;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.new-pay__form input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.new-pay__form [type=date] {
  position: relative;
  background: #fff url(/assets/img/img/date-icon.svg) no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  height: 40px;
  width: 100%;
  margin-block-start: 5px;
  cursor: pointer;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
@media (max-width: 767.98px) {
  .new-pay__form [type=date] {
    width: auto;
  }
}
@media (max-width: 479.98px) {
  .new-pay__form [type=date] {
    width: 100%;
  }
}
.new-pay__form [type=date]::-webkit-inner-spin-button {
  display: none;
}
.new-pay__form [type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
.new-pay__contacts {
  position: relative;
  width: 100%;
}
@media (max-width: 767.98px) {
  .new-pay__contacts {
    padding-inline: 0;
  }
}
.new-pay__contacts-header {
  position: relative;
  width: 100%;
  gap: 10px;
  padding-inline-end: 80px;
  padding-block: 40px 30px;
  max-width: 640px;
}
.new-pay__contacts-header::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10000%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translateX(-30px);
}
@media (max-width: 767.98px) {
  .new-pay__contacts-header::before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .new-pay__contacts-header {
    padding-inline-end: 59px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__contacts-header {
    padding-inline: 20px;
    padding-block: 20px;
  }
  .new-pay__contacts-header .link {
    display: none;
  }
}
.new-pay__contacts-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .new-pay__contacts-title {
    max-width: 70%;
  }
}
.new-pay__contacts-list {
  position: relative;
  gap: 10px;
  padding-inline-end: 40px;
  margin-inline-end: 40px;
  max-height: 533px;
  overflow-y: auto;
  overflow-x: hidden;
}
.new-pay__contacts-list::after {
  content: "";
  position: sticky;
  bottom: -3px;
  left: 0;
  height: 100px;
  min-height: 100px;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
@media (max-width: 991.98px) {
  .new-pay__contacts-list {
    padding-inline-end: 20px;
    margin-inline-end: 20px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__contacts-list {
    padding-inline-start: 20px;
    max-height: none;
    margin-inline-end: 0;
  }
  .new-pay__contacts-list::after {
    content: none;
  }
}
.new-pay__contacts-list::-webkit-scrollbar {
  width: 4px;
}
.new-pay__contacts-list::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.new-pay__contacts-list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.new-pay__contacts-list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.new-pay__recipients {
  width: 100%;
  padding-inline-start: 40px;
}
@media (max-width: 767.98px) {
  .new-pay__recipients {
    overflow: hidden;
    padding-inline-start: 20px;
  }
}
@media (min-width: 768px) {
  .new-pay__recipients-carousel {
    display: none;
  }
}
.new-pay__recipients-list {
  position: relative;
  gap: 10px;
  padding-inline-end: 80px;
  max-height: 512px;
  overflow-y: auto;
}
@media (max-width: 991.98px) {
  .new-pay__recipients-list {
    padding-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__recipients-list {
    display: none;
  }
}
.new-pay__recipients-info {
  padding-block: 40px 30px;
  padding-inline-end: 80px;
}
@media (max-width: 991.98px) {
  .new-pay__recipients-info {
    padding-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__recipients-info {
    padding-inline-end: 30px;
    padding-block: 0 20px;
  }
}
.new-pay__recipients-subtitle {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .new-pay__recipients-subtitle {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.new-pay__recipients-header {
  position: relative;
  width: 100%;
  gap: 30px;
  padding-inline-end: 80px;
  padding-block: 0 40px;
}
.new-pay__recipients-header::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -40px;
  width: 1000%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 991.98px) {
  .new-pay__recipients-header::before {
    padding-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__recipients-header::before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .new-pay__recipients-header {
    padding-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__recipients-header {
    display: none;
  }
}
.new-pay__field {
  width: 100%;
  gap: 5px;
}
@media (max-width: 767.98px) {
  .new-pay__field {
    justify-content: center;
  }
}
.new-pay__label {
  width: 100%;
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.new-pay__list {
  position: relative;
  max-height: 567px;
  overflow-y: auto;
  padding-inline-end: 40px;
  margin-inline-end: 40px;
}
@media (max-width: 991.98px) {
  .new-pay__list {
    padding-inline-end: 30px;
    margin-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__list {
    padding-inline-end: 0px;
    margin-inline-end: 0px;
  }
}
.new-pay__list::after {
  content: "";
  position: sticky;
  bottom: 0;
  left: 0;
  height: 100px;
  min-height: 100px;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
.new-pay__list::-webkit-scrollbar {
  width: 4px;
}
.new-pay__list::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.new-pay__list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.new-pay__list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.new-pay__item {
  width: 100%;
  padding: 15px 20px;
  background-color: rgba(0, 166, 195, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.new-pay__item:hover, .new-pay__item:focus {
  border-color: rgba(0, 166, 195, 0.05);
  background-color: #00A6C3;
  color: #fff;
}
.new-pay__item:hover .new-pay__item-date, .new-pay__item:focus .new-pay__item-date {
  color: #fff;
}
.new-pay__item--active {
  border-color: rgba(0, 166, 195, 0.05);
  background-color: #00A6C3;
  color: #fff;
}
.new-pay__item--active .new-pay__item-date {
  color: #fff;
}
.new-pay__item--hidden {
  display: none;
}
.new-pay__item-caption {
  margin-block-start: 20px;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-block-end: 20px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.new-pay__item-title, .new-pay__item-amount {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.new-pay__item-info {
  margin: 0 auto;
  gap: 10px;
}
.new-pay__item-status .zmdi {
  color: #0D518B;
  height: 16px;
  width: 16px;
}
.new-pay__item-dash {
  height: 4px;
  width: 30px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 767.98px) {
  .new-pay__item-dash {
    width: 16px;
  }
}
.new-pay__item-date {
  min-width: max-content;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.new-pay__header {
  padding-inline: 30px 80px;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .new-pay__header {
    padding-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .new-pay__header {
    justify-content: center;
  }
}
.new-pay__mobile-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 20px;
}
.new-pay__mobile-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .new-pay__mobile-container {
    display: none;
  }
}
.new-pay__mobile-link {
  width: 50%;
  min-width: 300px;
  margin: auto;
}
@media (max-width: 479.98px) {
  .new-pay__mobile-link {
    width: 100%;
    min-width: auto;
  }
}
.new-pay__load {
  width: 100%;
  padding-block-start: 20px;
  padding-block-end: 0;
}
@media (max-width: 767.98px) {
  .new-pay__load {
    padding-block-end: 120px;
  }
}
.new-pay__range {
  height: 2px;
  width: 94px !important;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
@media (min-width: 768px) {
  .new-pay__range-container {
    display: none;
  }
}

/*=====  End of New Payment Contacts Component  ======*/
/*=============================================
=            New Recipient Component            =
=============================================*/
.new-recipient {
  gap: 60px;
  padding-block: 80px 111px;
}
@media (max-width: 767.98px) {
  .new-recipient {
    padding-block: 20px 163px;
    gap: 20px;
  }
}
.new-recipient__wrapper hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.new-recipient__error {
  display: flex;
  color: #CD2A2A;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .new-recipient__error {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.new-recipient__header {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px 80px;
  opacity: 0.8;
}
@media (max-width: 767.98px) {
  .new-recipient__header {
    padding: 20px;
  }
}
.new-recipient__heading {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .new-recipient__heading {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.new-recipient__top {
  gap: 10px;
  padding-inline: 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .new-recipient__top {
    padding-inline: 20px;
    align-items: flex-start;
    padding-block-end: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.new-recipient__middle, .new-recipient__bottom {
  gap: 20px;
  padding-inline: 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .new-recipient__middle, .new-recipient__bottom {
    padding-inline: 20px;
  }
}
@media (max-width: 767.98px) {
  .new-recipient__middle:nth-child(2) {
    padding-block-end: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 767.98px) {
  .new-recipient__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-block: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}
.new-recipient__title {
  text-align: center;
  opacity: 0.8;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 767.98px) {
  .new-recipient__title {
    text-align: start;
  }
}
.new-recipient__subtitle {
  text-align: center;
  opacity: 0.8;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .new-recipient__subtitle {
    text-align: start;
  }
}
.new-recipient__text {
  text-align: center;
  opacity: 0.8;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.new-recipient__form {
  gap: 16px;
}
.new-recipient__input {
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  height: 80px;
  width: 60px;
  text-align: center;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .new-recipient__input {
    height: 60px;
    width: 40px;
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.new-recipient__input--active {
  border-color: #0BB826;
}
.new-recipient__input--error {
  border-color: #CD2A2A;
}
.new-recipient__status {
  display: none;
  gap: 5px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.new-recipient__status--active {
  display: flex;
}
.new-recipient__status--valid {
  color: #3B952D;
}
.new-recipient__status--invalid {
  color: #CD2A2A;
}
.new-recipient__button {
  min-width: 320px;
}
@media (max-width: 479.98px) {
  .new-recipient__button {
    min-width: auto;
    width: 100%;
  }
}
.new-recipient__button:disabled {
  filter: grayscale(1);
}
.new-recipient__loader {
  display: none;
}
.new-recipient__loader--active {
  display: block;
}
.new-recipient input::-webkit-outer-spin-button,
.new-recipient input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.new-recipient input[type=number] {
  -moz-appearance: textfield;
}

/*=====  End of New Recipient Component  ======*/
/*=============================================
=            News Grid            =
=============================================*/
.news-grid {
  position: relative;
  overflow: hidden;
}
.news-grid__inner {
  gap: 30px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .news-grid__inner {
    padding-block-end: 92px;
  }
}
.news-grid__header {
  width: 100%;
  gap: 20px;
}
.news-grid__header-left {
  gap: 20px;
}
@media (max-width: 479.98px) {
  .news-grid__header-left {
    gap: 30px;
  }
}
.news-grid__text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.news-grid__heading {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .news-grid__heading {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.news-grid__grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(269px, 1fr));
  gap: 15px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991.98px) {
  .news-grid__grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}
@media (max-width: 479.98px) {
  .news-grid__grid {
    display: none;
  }
}
.news-grid__carousel {
  width: 100%;
}
@media (min-width: 480px) {
  .news-grid__carousel {
    display: none;
  }
}
.news-grid__carousel-range {
  height: 2px;
  width: 300px !important;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.news-grid__carousel-range:focus {
  border-color: #fff;
}
.news-grid__carousel-range-container {
  position: absolute;
  top: auto;
  bottom: 53px;
  left: 120px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .news-grid__carousel-range-container {
    left: 30px;
  }
}
.news-grid__card-top {
  height: 240px;
  min-height: 240px;
  max-height: 240px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 479.98px) {
  .news-grid__card-top {
    height: 180px;
    min-height: 180px;
    max-height: 180px;
  }
}
.news-grid__card-bottom {
  height: 100%;
  gap: 10px;
}
.news-grid__card-footer {
  width: 100%;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .news-grid__card-footer {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
.news-grid__card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-grid__card-title {
  margin-block-start: 20px;
  width: 100%;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 479.98px) {
  .news-grid__card-title {
    max-width: 230px;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.news-grid__card-read {
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 479.98px) {
  .news-grid__card-read {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}

/*=====  End of News Grid  ======*/
.news-hero {
  width: 100%;
  position: relative;
  gap: 120px;
}
.news-hero__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.news-hero__image-container {
  border-radius: 10px;
  height: 360px;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.news-hero__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-hero__content {
  width: 100%;
  flex: 0.8;
}
.news-hero__title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
  color: #00A6C3;
}
.news-hero__title-container {
  padding-block: 20px;
  margin-block-end: 20px;
  border-bottom: 1px solid #00A6C3;
  gap: 10px;
}
.news-hero__title-feature {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.news-hero__date {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  opacity: 0.5;
}
.news-hero__date-label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  opacity: 0.3;
}
.news-hero__date-container {
  gap: 20px;
}

@media (max-width: 1249.98px) {
  .news-hero {
    gap: 60px;
  }
}
@media (max-width: 991.98px) {
  .news-hero {
    padding: 30px;
  }
}
@media (max-width: 767.98px) {
  .news-hero {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .news-hero__title {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .news-hero__title-feature {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
  .news-hero__content {
    width: 100%;
  }
  .news-hero__image-container {
    flex: none;
  }
}
@media (max-width: 479.98px) {
  .news-hero__image-container {
    height: 220px;
  }
}
.news-listing {
  gap: 30px;
  position: relative;
}
.news-listing__grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px 60px;
}
.news-listing__card {
  gap: 20px;
}
.news-listing__card-image {
  border-radius: 10px;
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.news-listing__card-title {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
.news-listing__card-read {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  opacity: 0.3;
}
.news-listing__button {
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: #0D518B;
  gap: 8px;
  cursor: pointer;
}
.news-listing__button:hover {
  color: #000;
}
.news-listing__button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  color: #000;
}

@media (max-width: 991.98px) {
  .news-listing__grid {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
  }
  .news-listing__card-title {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .news-listing__card-image {
    height: 225px;
  }
  .news-listing__card-bottom {
    flex-direction: row-reverse;
    justify-content: start;
    gap: 20px;
  }
  .news-listing__card-read {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
/*=============================================
=            Organisation Details Not Found Component            =
=============================================*/
.org-not-found {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .org-not-found {
    gap: 60px;
  }
}
.org-not-found__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-not-found__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-not-found__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-not-found__left, .org-not-found__right {
  width: 100%;
}
.org-not-found__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-inline-start: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
  min-height: 657px;
}
@media (min-width: 1921px) {
  .org-not-found__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-not-found__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-not-found__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .org-not-found__left {
    padding: 49px 30px;
    min-height: auto;
  }
}
.org-not-found__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-not-found__right {
    display: none;
  }
}
.org-not-found__inner {
  gap: 20px;
  margin: auto 0;
}
.org-not-found__bottom {
  width: 100%;
  gap: 20px;
}
.org-not-found__button {
  width: 100%;
}
.org-not-found__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.org-not-found__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (min-width: 1921px) {
  .org-not-found__image {
    width: auto;
  }
}
.org-not-found__text {
  color: #CD2A2A;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .org-not-found__text {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.org-not-found__text .zmdi {
  margin-inline-end: 10px;
}

/*=====  End of Organisation Details Not Found Component  ======*/
/*=============================================
=           Start of Organisation Director Authentication            =
=============================================*/
.org-auth {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .org-auth {
    gap: 60px;
  }
}
.org-auth__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-auth__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-auth__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-auth__left, .org-auth__right {
  width: 100%;
}
.org-auth__left {
  flex: 0.78;
  gap: 30px;
  margin-block: 80px 0;
  padding-block-end: 80px;
  padding-inline: 80px 36px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
  max-height: 820px;
  overflow-y: auto;
  overflow-x: hidden;
}
.org-auth__left::-webkit-scrollbar {
  width: 4px;
}
.org-auth__left::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.org-auth__left::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.org-auth__left::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
@media (min-width: 1921px) {
  .org-auth__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-auth__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-auth__left {
    flex: 1;
    padding-right: 80px;
    max-width: none;
    width: 100%;
    max-height: none;
  }
}
@media (max-width: 767.98px) {
  .org-auth__left {
    padding: 30px;
    margin: 0;
    gap: 20px;
  }
}
.org-auth__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-auth__right {
    display: none;
  }
}
.org-auth__top, .org-auth__middle, .org-auth__bottom {
  gap: 30px;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .org-auth__top, .org-auth__middle, .org-auth__bottom {
    gap: 20px;
    border: none;
    padding-block-end: 0;
  }
}
.org-auth__bottom {
  border: none;
  padding-block-end: 0;
}
.org-auth__cards {
  gap: 10px;
}
.org-auth__card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-auth__card:hover, .org-auth__card:focus {
  border-color: rgba(0, 0, 0, 0.8);
}
.org-auth__card:hover .org-auth__card-icon, .org-auth__card:focus .org-auth__card-icon {
  background-color: #000;
}
.org-auth__card-title {
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-align: left;
}
.org-auth__card-icon {
  background-color: #0D518B;
  height: 40px;
  width: 40px;
  color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-auth__card-icon .zmdi {
  font-size: 18px;
}
.org-auth__card-left {
  width: 100%;
  gap: 10px;
}
.org-auth__progress {
  gap: 20px;
}
.org-auth__progress-text {
  min-width: fit-content;
  color: rgba(0, 0, 0, 0.3);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-auth__progress-text--active {
  color: #00A6C3;
}
.org-auth__progress-bar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(to right, #00A6C3 var(--progress), rgba(0, 0, 0, 0.1) var(--progress));
}
.org-auth__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-auth__caption {
    font-size: 10px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.org-auth__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-auth__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.org-auth__link {
  margin: 10px 0;
}
.org-auth__link-top {
  align-self: end;
}
@media (max-width: 767.98px) {
  .org-auth__link-top {
    display: none;
  }
}

/*=====  End of Organisation Director Authentication  ======*/
/*=============================================
=            Organisation Director Manual Entry Component            =
=============================================*/
.org-director {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .org-director {
    gap: 60px;
  }
}
.org-director__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-director__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-director__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-director__left, .org-director__right {
  width: 100%;
}
.org-director__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-inline-start: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .org-director__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-director__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-director__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .org-director__left {
    padding: 49px 30px;
  }
}
.org-director__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-director__right {
    display: none;
  }
}
.org-director__form, .org-director__inputs {
  width: 100%;
  gap: 20px;
}
.org-director__form input[type=text] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  max-width: none !important;
  margin: 0 !important;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-director__form input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.org-director__inner {
  margin: auto 0;
  gap: 20px;
}
.org-director__button {
  width: 100%;
}
.org-director__field {
  gap: 5px;
}
.org-director__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.org-director__label-required {
  color: #CD2A2A;
}
.org-director__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-director__validation--active {
  display: block;
}
.org-director__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.org-director-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-director-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Organisation Director Manual Entry Component  ======*/
/*=============================================
=            Organisation Manual Entry            =
=============================================*/
.org-manual-entry {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .org-manual-entry {
    gap: 60px;
  }
}
.org-manual-entry__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-manual-entry__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-manual-entry__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-manual-entry__left, .org-manual-entry__right {
  width: 100%;
}
.org-manual-entry__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .org-manual-entry__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-manual-entry__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-manual-entry__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .org-manual-entry__left {
    padding: 30px;
  }
}
.org-manual-entry__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-manual-entry__right {
    display: none;
  }
}
.org-manual-entry__form, .org-manual-entry__inputs {
  width: 100%;
  gap: 20px;
}
.org-manual-entry__form input[type=text] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.org-manual-entry__form input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.org-manual-entry__optional {
  color: rgba(0, 56, 101, 0.8);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-manual-entry__button {
  width: 100%;
}
.org-manual-entry__field {
  gap: 5px;
}
.org-manual-entry__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.org-manual-entry__label-required {
  color: #CD2A2A;
}
.org-manual-entry__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-manual-entry__validation--active {
  display: block;
}
.org-manual-entry hr {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
}
.org-manual-entry__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.org-manual-entry__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  perspective: 10px;
  object-fit: cover;
  translate: 0 0 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-manual-entry-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-manual-entry-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Organisation Manual Entry  ======*/
/*=============================================
=            Organisation Member Profile Component            =
=============================================*/
.org-member-profile {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .org-member-profile {
    gap: 60px;
  }
}
.org-member-profile__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-member-profile__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-member-profile__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-member-profile__left, .org-member-profile__right {
  width: 100%;
}
.org-member-profile__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .org-member-profile__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-member-profile__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-member-profile__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .org-member-profile__left {
    padding: 30px;
  }
}
.org-member-profile__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-member-profile__right {
    display: none;
  }
}
.org-member-profile__form, .org-member-profile__inputs, .org-member-profile__bottom {
  width: 100%;
  gap: 20px;
}
.org-member-profile__form {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-block-start: 20px;
}
.org-member-profile__form input[type=text], .org-member-profile__form select {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.org-member-profile__form input[type=text]::placeholder, .org-member-profile__form select::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.org-member-profile__form select {
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-black-icon.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.5);
  height: auto;
  cursor: pointer;
}
.org-member-profile__form .org-member-profile__country--active {
  color: #000;
}
.org-member-profile__form option {
  color: #000;
}
.org-member-profile__form option:first-child {
  color: rgba(0, 0, 0, 0.5);
}
.org-member-profile__form [type=date] {
  position: relative;
  background: #fff url(/assets/img/img/date-icon.svg) no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 10px 20px;
  height: 40px;
  cursor: pointer;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.org-member-profile__form [type=date]::-webkit-inner-spin-button {
  display: none;
}
.org-member-profile__form [type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
.org-member-profile__form input[type=date]::-webkit-datetime-edit {
  color: #000;
  opacity: 1;
}
.org-member-profile__form input[type=date]:not([value])::-webkit-datetime-edit {
  opacity: 0;
}
.org-member-profile__form input[type=date]:before {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.org-member-profile__form input[type=date].org-member-profile__dob--active::before {
  display: none;
}
.org-member-profile__form input[type=date].org-member-profile__dob--active::-webkit-datetime-edit {
  opacity: 1;
}
.org-member-profile__optional {
  color: rgba(0, 56, 101, 0.8);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-member-profile__button {
  width: 100%;
}
.org-member-profile__field {
  gap: 5px;
}
.org-member-profile__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.org-member-profile__label-required {
  color: #CD2A2A;
}
.org-member-profile__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-member-profile__validation--active {
  display: block;
}
.org-member-profile__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.org-member-profile-button {
  padding: 10px;
  background-color: #0D518B;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 1px solid transparent;
  width: 38px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-member-profile-button:hover {
  background-color: #fff;
  color: #0D518B;
  border: 1px solid #0D518B;
}

/*=====  End of Organisation Member Profile Component  ======*/
/*=============================================
=            Organisation Profile           =
=============================================*/
.org-profile {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .org-profile {
    gap: 60px;
  }
}
.org-profile__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-profile__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-profile__left, .org-profile__right {
  width: 100%;
}
.org-profile__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .org-profile__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-profile__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-profile__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .org-profile__left {
    padding: 30px 30px 120px;
  }
}
.org-profile__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-profile__right {
    display: none;
  }
}
.org-profile__cards {
  gap: 20px;
}
.org-profile__card {
  flex: 1;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  min-height: 161px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-profile__card:hover, .org-profile__card:focus {
  border-color: #00A6C3;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1019607843);
}
.org-profile__card--active {
  border-color: #00A6C3;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1019607843);
}
.org-profile__card--active .org-profile__tick {
  background-color: #00A6C3;
  border-color: #00A6C3;
}
.org-profile__card--active .org-profile__tick .zmdi {
  display: block;
}
.org-profile__card-icon {
  height: 48px;
  width: 48px;
}
.org-profile__card-title {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 479.98px) {
  .org-profile__card-title {
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.org-profile__card-text {
  text-align: center;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 479.98px) {
  .org-profile__card-text {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.org-profile__tick {
  height: 100%;
  width: 100%;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-profile__tick .zmdi {
  color: #fff;
  display: none;
}
.org-profile__button {
  width: 100%;
}
.org-profile__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}
.org-profile__form {
  gap: 20px;
  display: none;
}
.org-profile__form--active {
  display: flex;
}
.org-profile__field {
  gap: 5px;
}
.org-profile__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.org-profile__label-required {
  color: #CD2A2A;
}
.org-profile__validation {
  color: #CD2A2A;
  display: none;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-profile__validation--active {
  display: block;
}
.org-profile__next:disabled {
  filter: grayscale(1);
  pointer-events: none;
}

/*=====  End of Organisation Profile ======*/
/*=============================================
=            Organisation Profile Confirm Component            =
=============================================*/
.org-profile-confirm {
  display: flex;
  position: relative;
}
.org-profile-confirm__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .org-profile-confirm__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.org-profile-confirm__subtitle {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-profile-confirm__content {
  color: #3B952D;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-profile-confirm__content .zmdi {
  margin-inline-end: 10px;
}
.org-profile-confirm__left, .org-profile-confirm__right {
  width: 100%;
}
.org-profile-confirm__left {
  flex: 0.78;
  gap: 20px;
  margin-block-start: 80px;
  padding-block: 0 80px;
  padding-inline: 80px 36px;
  margin-inline-end: 40px;
  margin-inline-start: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  max-width: 640px;
  max-height: 820px;
  overflow-y: auto;
  overflow-x: hidden;
}
.org-profile-confirm__left::-webkit-scrollbar {
  width: 4px;
}
.org-profile-confirm__left::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.org-profile-confirm__left::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.org-profile-confirm__left::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
@media (min-width: 1921px) {
  .org-profile-confirm__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .org-profile-confirm__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .org-profile-confirm__left {
    flex: 1;
    max-width: none;
    margin-inline-end: 0;
    padding-inline-end: 80px;
    width: 100%;
    max-height: none;
  }
}
@media (max-width: 767.98px) {
  .org-profile-confirm__left {
    padding-block: 30px;
    margin: 0;
    padding-inline: 30px;
  }
}
.org-profile-confirm__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .org-profile-confirm__right {
    display: none;
  }
}
.org-profile-confirm__info {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 166, 195, 0.05);
  border-radius: 10px;
  padding: 15px;
  gap: 5px;
}
.org-profile-confirm__text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-profile-confirm__text-main {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-profile-confirm__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.org-profile-confirm__image-placeholder {
  object-fit: contain;
  padding: 11px;
}
.org-profile-confirm__image-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 40px;
  width: 40px;
  background-color: #fff;
}
.org-profile-confirm__cards {
  gap: 20px;
}
.org-profile-confirm__cards-select .org-profile-confirm__card-radio {
  display: block;
}
.org-profile-confirm__card {
  padding: 15px;
  background-color: rgba(0, 166, 195, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  gap: 10px;
}
.org-profile-confirm__card-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-profile-confirm__card-text {
  color: #CD2A2A;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.org-profile-confirm__card-text--active {
  color: #0D518B;
}
.org-profile-confirm__card-content {
  gap: 5px;
}
.org-profile-confirm__card-alt {
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-profile-confirm__card-alt:hover, .org-profile-confirm__card-alt:focus {
  border-color: #00A6C3;
}
.org-profile-confirm__card-radio {
  display: none;
  padding: 10px;
  height: 28px;
  width: 28px;
  min-height: 28px;
  min-width: 28px;
  margin-right: 10px;
  cursor: pointer;
  margin: 0 0 0 auto !important;
  color: #fff;
  position: relative;
  -webkit-appearance: none;
  margin-left: auto;
  pointer-events: none;
}
.org-profile-confirm__card-radio:before {
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
  content: "";
  background: #fff;
  overflow: hidden;
}
.org-profile-confirm__card-radio:after {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 28px;
  width: 28px;
  content: "";
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-position: 10% 10%;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.org-profile-confirm__card-radio:checked:after {
  background-image: url("/assets/img/img/white-tick.svg");
  background-color: #0D518B;
  border-color: #0D518B;
}
.org-profile-confirm__select {
  display: none;
  gap: 10px;
}
.org-profile-confirm__select--active {
  display: flex;
}
.org-profile-confirm__select-left, .org-profile-confirm__select-right {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-profile-confirm__button {
  width: 100%;
}
.org-profile-confirm__number {
  margin-right: 5px;
}
.org-profile-confirm__bottom {
  width: 100%;
  gap: 20px;
}
.org-profile-confirm__bottom .link {
  margin-block-start: 10px;
}
.org-profile-confirm__caption {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.org-profile-confirm__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}

/*=====  End of Organisation Profile Confirm Component  ======*/
/*=============================================
=            Payment Confirmed Component            =
=============================================*/
.pay-confirmed {
  gap: 30px;
  padding-block: 60px;
  max-width: 600px;
  margin: auto;
}
@media (max-width: 767.98px) {
  .pay-confirmed {
    padding-block: 40px 142px;
    gap: 20px;
  }
}
.pay-confirmed__bank-left, .pay-confirmed__bank-right {
  gap: 20px;
  font-size: 13px;
}
.pay-confirmed__bank-left {
  opacity: 0.8;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.pay-confirmed__bank-right {
  font-weight: 700;
}
.pay-confirmed__bank-wrapper {
  background-color: #0D518B;
  color: #fff;
  padding: 18px 15px;
  border-radius: 12px;
  gap: 20px;
  width: 100%;
}
.pay-confirmed__bank-description {
  text-align: center;
  opacity: 0.8;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.pay-confirmed__wrapper hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.pay-confirmed__header {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px 80px;
  opacity: 0.8;
}
@media (max-width: 767.98px) {
  .pay-confirmed__header {
    padding: 30px 20px;
  }
}
.pay-confirmed__heading {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .pay-confirmed__heading {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.pay-confirmed__title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .pay-confirmed__title {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.pay-confirmed__amount {
  font-size: 30px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.6px;
}
.pay-confirmed__icon {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  color: #fff;
}
.pay-confirmed__icon-container {
  height: 84px;
  width: 84px;
  border-radius: 50%;
}
.pay-confirmed__icon-container--green {
  background-color: #3B952D;
}
.pay-confirmed__icon-container--red {
  background-color: #CD2A2A;
}
.pay-confirmed__icon .zmdi {
  font-size: 28px;
}
.pay-confirmed__top {
  gap: 30px;
  padding-inline: 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .pay-confirmed__top {
    padding-inline: 20px;
    gap: 20px;
  }
}
.pay-confirmed__middle, .pay-confirmed__bottom {
  gap: 30px;
  padding-inline: 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .pay-confirmed__middle, .pay-confirmed__bottom {
    padding-inline: 20px;
    gap: 20px;
  }
}
.pay-confirmed__middle .button {
  margin-inline-start: 5px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .pay-confirmed__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-block: 20px 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    gap: 20px;
  }
}
.pay-confirmed__button {
  min-width: 320px;
}
@media (max-width: 479.98px) {
  .pay-confirmed__button {
    min-width: auto;
    width: 100%;
  }
}
.pay-confirmed__user {
  width: 100%;
  background-color: rgba(0, 166, 195, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  gap: 10px;
  padding: 15px;
}
.pay-confirmed__user-right {
  gap: 5px;
}
.pay-confirmed__user-title {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.pay-confirmed__user-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.pay-confirmed__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.pay-confirmed__image-placeholder {
  object-fit: contain;
  padding: 16px;
}
.pay-confirmed__image-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  background-color: #fff;
}
.pay-confirmed__pill {
  width: fit-content;
  padding: 2px 10px;
  border-radius: 10px;
  border: 1px solid;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.pay-confirmed__pill-gold {
  border-color: #B99300;
  color: #B99300;
}
.pay-confirmed__pill-royal {
  border-color: #0D518B;
  color: #0D518B;
}
.pay-confirmed__pill-sky {
  border-color: #00A6C3;
  color: #00A6C3;
}
.pay-confirmed__row {
  width: 100%;
  max-width: 600px;
}
@media (max-width: 767.98px) {
  .pay-confirmed__row {
    max-width: none;
    justify-content: space-between;
  }
}
.pay-confirmed__row-title {
  text-align: center;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.pay-confirmed__row-caption {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .pay-confirmed__row-caption {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}

/*=====  End of Payment Confirmed Component  ======*/
/*=============================================
=            Payments Component            =
=============================================*/
.payments {
  display: flex;
  padding-inline-start: 80px;
  position: relative;
}
@media (max-width: 991.98px) {
  .payments {
    padding-inline-start: 40px;
  }
}
@media (max-width: 767.98px) {
  .payments {
    padding-inline-start: 0;
  }
}
.payments__divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
@media (max-width: 991.98px) {
  .payments__divider {
    display: none;
  }
}
.payments__title {
  margin: auto;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 991.98px) {
  .payments__title {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-inline: 60px;
    text-align: center;
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.payments__top {
  position: relative;
  padding: 30px 80px;
}
@media (max-width: 991.98px) {
  .payments__top {
    padding-block: 20px;
    padding-inline: 20px;
  }
}
.payments__back {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  display: none;
}
.payments__back--active {
  display: flex;
}
@media (min-width: 992px) {
  .payments__back {
    display: none;
  }
}
.payments__subtitle {
  padding-inline-end: 80px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 991.98px) {
  .payments__subtitle {
    display: none;
  }
}
.payments__left, .payments__right {
  width: 100%;
}
.payments__left {
  flex: 0.89;
  gap: 20px;
  padding: 40px 0;
  min-height: 750px;
}
@media (max-width: 991.98px) {
  .payments__left {
    flex: 0.8;
  }
}
@media (max-width: 991.98px) {
  .payments__left {
    flex: 1;
    padding-block-start: 0;
  }
}
.payments__right {
  flex: 1;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-block: 30px;
  background-color: #fff;
}
@media (max-width: 991.98px) {
  .payments__right {
    display: none;
    padding-block: 10px 0;
    overflow-y: auto;
  }
  .payments__right--active {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .payments__right::-webkit-scrollbar {
    width: 4px;
  }
  .payments__right::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  .payments__right::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 2px;
  }
  .payments__right::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}
.payments__form, .payments__inputs {
  width: 100%;
  gap: 20px;
}
@media (max-width: 991.98px) {
  .payments__inputs .payments__label {
    width: 50%;
    min-width: 300px;
  }
}
@media (max-width: 479.98px) {
  .payments__inputs .payments__label {
    width: 100%;
    min-width: auto;
  }
}
.payments__form {
  padding-inline-end: 80px;
}
@media (max-width: 991.98px) {
  .payments__form {
    padding-inline-end: 60px;
  }
}
@media (max-width: 991.98px) {
  .payments__form {
    padding-inline: 20px;
  }
}
.payments__form input[type=text] {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline-color: #000;
  width: 100%;
  padding-block: 10px;
  padding-inline: 20px 41px;
  background-color: #fff;
  background-image: url("/assets/img/img/search-icon.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position-x: calc(100% - 16px);
  background-position-y: 13px;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.payments__form input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.payments__form [type=date] {
  position: relative;
  background: #fff url(/assets/img/img/date-icon.svg) no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  height: 40px;
  width: 100%;
  margin-block-start: 5px;
  cursor: pointer;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
@media (max-width: 991.98px) {
  .payments__form [type=date] {
    width: auto;
  }
}
@media (max-width: 479.98px) {
  .payments__form [type=date] {
    width: 100%;
  }
}
.payments__form [type=date]::-webkit-inner-spin-button {
  display: none;
}
.payments__form [type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
.payments__button .zmdi {
  transform: translateY(1px);
}
.payments__button--active {
  background-color: #0D518B;
  color: #fff;
}
.payments__field {
  width: 100%;
  gap: 5px;
}
@media (max-width: 991.98px) {
  .payments__field {
    justify-content: center;
  }
}
.payments__label {
  width: 100%;
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}
.payments__dates {
  display: none;
  width: 100%;
  gap: 20px;
}
@media (max-width: 991.98px) {
  .payments__dates {
    gap: 5px;
  }
}
.payments__dates--active {
  display: flex;
}
@media (max-width: 991.98px) {
  .payments__dates .payments__label {
    width: auto;
  }
}
@media (max-width: 479.98px) {
  .payments__dates .payments__label {
    width: 100%;
  }
}
.payments__list {
  position: relative;
  max-height: 567px;
  min-height: 567px;
  overflow-y: auto;
  padding-inline-end: 40px;
  margin-inline-end: 40px;
}
@media (max-width: 991.98px) {
  .payments__list {
    padding-inline-end: 30px;
    margin-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .payments__list {
    padding-inline-end: 0px;
    margin-inline-end: 0px;
  }
}
.payments__list::after {
  content: "";
  position: sticky;
  bottom: -1px;
  left: 0;
  height: 100px;
  min-height: 100px;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
.payments__list::-webkit-scrollbar {
  width: 4px;
}
.payments__list::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.payments__list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.payments__list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.payments__item {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  background-color: rgba(0, 166, 195, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  gap: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .payments__item {
    flex-direction: column;
    align-items: flex-start;
    padding-inline-end: 30px;
  }
}
@media (max-width: 479.98px) {
  .payments__item .zmdi-chevron-right {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
.payments__item:hover, .payments__item:focus {
  border-color: rgba(0, 166, 195, 0.05);
  background-color: #00A6C3;
  color: #fff;
}
.payments__item:hover .payments__item-date, .payments__item:focus .payments__item-date {
  color: #fff;
}
.payments__item--active {
  border-color: rgba(0, 166, 195, 0.05);
  background-color: #00A6C3;
  color: #fff;
}
.payments__item--active .payments__item-date {
  color: #fff;
}
.payments__item--hidden {
  display: none;
}
.payments__item-caption {
  margin-block-start: 20px;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-block-end: 20px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__item-title {
  max-width: 130px;
  min-width: 130px;
  margin-inline-end: 30px;
}
@media (max-width: 767.98px) {
  .payments__item-title {
    margin-inline-end: 15px;
  }
}
@media (max-width: 479.98px) {
  .payments__item-title {
    max-width: 100%;
    margin-inline-end: 0;
  }
}
.payments__item-title, .payments__item-amount {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.payments__item-info {
  margin-inline-end: auto;
  gap: 10px;
}
@media (max-width: 479.98px) {
  .payments__item-info {
    flex-wrap: wrap;
  }
}
.payments__item-status .zmdi {
  height: 16px;
  width: 16px;
}
.payments__item-status .zmdi-check-circle {
  color: #0D518B;
}
.payments__item-status .zmdi-close-circle {
  color: #CD2A2A;
}
.payments__item-dash {
  height: 4px;
  width: 30px;
  border-radius: 2px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .payments__item-dash {
    width: 16px;
  }
}
.payments__item-dash-inner {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0D518B var(--itemProgress), rgba(0, 0, 0, 0.1) var(--itemProgress));
}
.payments__item-date {
  min-width: max-content;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__details {
  display: none;
  width: 100%;
}
@media (max-width: 991.98px) {
  .payments__details {
    height: 100%;
    outline: none;
  }
}
.payments__details--active {
  display: flex;
}
.payments__header {
  padding-inline: 30px 80px;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .payments__header {
    padding-inline-end: 30px;
  }
}
@media (max-width: 767.98px) {
  .payments__header {
    justify-content: center;
  }
}
.payments__buttons {
  gap: 10px;
}
.payments__buttons .link {
  min-width: 137.5px;
  padding-inline: 20px;
}
@media (max-width: 991.98px) {
  .payments__buttons {
    display: none;
  }
}
.payments__transaction {
  padding-inline: 30px 80px;
  padding-block: 20px;
  background-color: rgba(0, 166, 195, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  gap: 20px;
}
@media (max-width: 991.98px) {
  .payments__transaction {
    padding-inline-end: 30px;
  }
}
.payments__amounts {
  width: 100%;
}
.payments__amount {
  flex: 1;
}
.payments__value {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .payments__value {
    font-size: 23px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.23px;
  }
}
.payments__currency {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__text {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__info {
  width: 100%;
  padding-inline: 30px 80px;
  padding-block: 20px;
  gap: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 991.98px) {
  .payments__info {
    padding-inline-end: 30px;
  }
}
.payments__info-title {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__info-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.payments__content {
  width: 100%;
  padding-inline: 30px 80px;
  padding-block: 20px;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .payments__content {
    padding-inline-end: 30px;
  }
}
.payments__row {
  width: 100%;
  gap: 20px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__mobile-buttons {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 166, 195, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-block: 20px 60px;
  padding-inline: 20px;
  gap: 10px;
}
@media (min-width: 992px) {
  .payments__mobile-buttons {
    display: none;
  }
}
.payments__mobile-buttons .button {
  min-width: 137.5px;
  padding-inline: 20px;
}
@media (max-width: 479.98px) {
  .payments__mobile-buttons .button {
    min-width: max-content;
    flex: 1;
  }
}
.payments__loader {
  margin: auto;
  display: none;
}
.payments__loader--active {
  display: flex;
}
.payments__step {
  gap: 15px;
  padding: 20px 80px 20px 40px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 991.98px) {
  .payments__step {
    padding: 20px 30px;
  }
}
.payments__step-progress-bar {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
}
.payments__step-progress {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #00A6C3 var(--progress), rgba(0, 0, 0, 0.1) var(--progress));
}
.payments__step-progress--failed {
  background: #CD2A2A;
}
.payments__step-progress--complete {
  background: #0BB826;
}
.payments__step-title {
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.payments__step-subtitle {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.payments__step-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

/*=====  End of Payments Component  ======*/
.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}
.modal.active {
  display: flex;
}
.modal .phone-input.readonly {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.modal-button {
  margin-block-start: 10px;
  width: 100%;
}
.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 80%;
  max-width: 540px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.modal-header {
  text-align: center;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .modal-header {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.modal-body {
  gap: 10px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .modal-body {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
@media (max-width: 767.98px) {
  .modal-body--section {
    flex-direction: column;
    gap: 10px;
    margin-block-end: 5px;
  }
}
.modal .close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.modal .close:hover,
.modal .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.iti__tel-input {
  width: 100%;
  border: 1px solid #ccc;
  padding-block: 10px;
}
.iti__tel-input .selected-flag {
  height: 40px;
}
.iti__tel-input .flag-container {
  height: 40px;
}
.iti__tel-input .iti__flag {
  top: 50%;
  transform: translateY(-50%);
}
.iti__tel-input input {
  height: 40px;
  border: 1px solid #ccc;
  padding: 0 10px;
}

/*=============================================
=            Platform Navbar            =
=============================================*/
.navbar-platform {
  position: relative;
  background-color: #0D518B;
  color: #fff;
  padding: 20px 0;
  max-height: 80px;
  height: 80px;
}
@media (max-width: 1249.98px) {
  .navbar-platform {
    padding: 15px 0;
    max-height: 70px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
}
.navbar-platform__inner {
  margin: 0 auto;
  padding: 0 60px;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 1249.98px) {
  .navbar-platform__inner {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-platform__inner {
    padding: 0 30px;
  }
}
@media (max-width: 479.98px) {
  .navbar-platform__inner {
    padding: 0 20px;
  }
}
.navbar-platform__logo {
  display: block;
  max-height: 24px;
  width: auto;
}
.navbar-platform__logo-container {
  min-width: fit-content;
  margin-inline-end: auto;
}
@media (max-width: 1249.98px) {
  .navbar-platform__logo-container {
    margin-inline-end: 0;
  }
}
.navbar-platform__avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.navbar-platform__avatar--default {
  padding: 10px;
}
.navbar-platform__avatar-container {
  position: relative;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: #fff;
  margin: 0 5px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.navbar-platform__avatar-container:hover, .navbar-platform__avatar-container:focus {
  border-color: #00A6C3;
  border-color: #000;
}
.navbar-platform__avatar-number {
  position: absolute;
  top: -7px;
  right: -7px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.navbar-platform__links {
  gap: 30px;
}
@media (max-width: 1439.98px) {
  .navbar-platform__links {
    gap: 15px;
  }
}
.navbar-platform__links-item {
  height: 100%;
  min-width: fit-content;
}
.navbar-platform__links-item:last-of-type {
  position: relative;
  padding-right: 30px;
}
@media (max-width: 1439.98px) {
  .navbar-platform__links-item:last-of-type {
    padding-right: 15px;
  }
}
.navbar-platform__links-item:last-of-type:after {
  content: "";
  position: absolute;
  right: 0;
  top: -8px;
  height: 38px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}
.navbar-platform__login {
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid transparent;
  border-radius: 0;
}
.navbar-platform__login:hover, .navbar-platform__login:focus {
  color: #fff;
  border-color: #fff;
}
.navbar-platform__link {
  border: 1px solid #fff;
  position: relative;
}
.navbar-platform__link:hover, .navbar-platform__link:focus {
  background-color: #0D518B;
  color: #fff;
}
.navbar-platform__link::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #fff;
  opacity: 0.2;
  pointer-events: none;
}
@media (max-width: 1249.98px) {
  .navbar-platform__login, .navbar-platform__link {
    display: none;
  }
}

/*=====  End of Platform Navbar  ======*/
.quote {
  position: relative;
  gap: 120px;
}
.quote--right {
  flex-direction: row-reverse;
}
.quote__content {
  gap: 30px;
  flex: 0.8;
}
.quote__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.quote__image-container {
  flex: 1;
  width: 100%;
  height: 480px;
  max-height: 480px;
  border-radius: 18px;
  overflow: hidden;
}
.quote__title {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.quote__subtitle {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.quote__quote {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  font-size: 22px;
  width: 70%;
}
.quote__quote-container {
  gap: 30px;
}
.quote__quote-icon {
  width: 46px;
  height: 29px;
}
.quote__caption {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  opacity: 0.5;
}
.quote__caption-container {
  gap: 10px;
}

@media (max-width: 1249.98px) {
  .quote {
    gap: 60px;
  }
}
@media (max-width: 991.98px) {
  .quote {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767.98px) {
  .quote__image-container {
    height: 380px;
    max-height: 380px;
  }
  .quote__title {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
  .quote__quote {
    width: 100%;
  }
}
@media (max-width: 479.98px) {
  .quote__image-container {
    height: 240px;
    max-height: 240px;
  }
}
.recipient-detail {
  width: 540px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-block: 40px;
  padding-inline: 40px 80px;
  margin-inline-start: auto;
  background-color: #fff;
  gap: 30px;
  transform: translateX(540px);
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 767.98px) {
  .recipient-detail {
    gap: 20px;
    padding: 20px 0;
    height: auto;
    width: 100%;
    margin-inline-start: 0;
    margin-block-start: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    transform: translateX(0) translateY(100%);
  }
}
.recipient-detail .link {
  width: 100%;
}
@media (max-width: 767.98px) {
  .recipient-detail .link {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}
.recipient-detail__overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999999999;
  cursor: default;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 1249.98px) {
  .recipient-detail__overlay {
    height: calc(100vh - 70px);
  }
}
.recipient-detail__overlay--active {
  visibility: visible;
  opacity: 1;
  animation: backgroundFade 0.2s ease-in-out forwards;
}
.recipient-detail__overlay--active .recipient-detail {
  transform: translateX(0px);
}
@media (max-width: 767.98px) {
  .recipient-detail__overlay--active .recipient-detail {
    transform: translateY(0px);
  }
}
@keyframes backgroundFade {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.recipient-detail__inner {
  overflow: hidden;
  width: 100%;
  gap: 30px;
}
@media (min-width: 768px) {
  .recipient-detail__inner {
    min-height: 770px;
  }
}
.recipient-detail__header {
  width: 100%;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .recipient-detail__header {
    width: calc(100% - 40px);
    margin-inline: auto;
    padding-block-end: 20px;
  }
}
.recipient-detail__title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .recipient-detail__title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.recipient-detail__close {
  height: 40px;
  width: 40px;
}
@media (max-width: 767.98px) {
  .recipient-detail__close {
    height: 20px;
    width: 20px;
    background-color: transparent;
    color: #0D518B;
  }
  .recipient-detail__close:hover, .recipient-detail__close:focus {
    background-color: transparent;
    color: #000;
  }
}
.recipient-detail__user {
  width: 100%;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .recipient-detail__user {
    padding-inline: 20px;
  }
}
.recipient-detail__user-left {
  gap: 10px;
}
.recipient-detail__user-title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .recipient-detail__user-title {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.recipient-detail__user-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.recipient-detail__avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.recipient-detail__avatar-placeholder {
  object-fit: contain;
  padding: 16px;
}
.recipient-detail__avatar-container {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  background-color: #fff;
}
.recipient-detail__cards {
  width: 100%;
  gap: 18px;
}
@media (max-width: 767.98px) {
  .recipient-detail__cards {
    gap: 10px;
    padding-inline: 20px;
  }
}
.recipient-detail__card {
  max-width: 200px;
  max-height: 126px;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .recipient-detail__card {
    max-height: 78px;
    max-width: 123px;
    align-items: flex-start;
  }
}
.recipient-detail__card:nth-child(2) {
  max-height: none;
  background-color: rgba(0, 166, 195, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 202px;
  padding: 18px 10px;
}
@media (max-width: 767.98px) {
  .recipient-detail__card:nth-child(2) {
    max-width: none;
    width: 100%;
    padding: 11.5px 10px;
    gap: 10px;
  }
}
.recipient-detail__card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 767.98px) {
  .recipient-detail__card-top {
    flex-direction: row;
  }
}
.recipient-detail__location {
  padding-block: 5px 20px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .recipient-detail__location {
    align-items: center;
    padding-block: 0;
    margin-inline-start: 10px;
  }
}
.recipient-detail__pill {
  padding: 2px 10px;
  border-radius: 15px;
  border: 1px solid;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.recipient-detail__pill-gold {
  border-color: #B99300;
  color: #B99300;
}
.recipient-detail__pill-royal-blue {
  border-color: #0D518B;
  color: #0D518B;
}
.recipient-detail__pill-sky-blue {
  border-color: #00A6C3;
  color: #00A6C3;
}
.recipient-detail__text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .recipient-detail__text {
    display: none;
  }
}
.recipient-detail__text-mobile {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.recipient-detail__payments {
  position: relative;
  width: 100%;
  max-height: 298px;
  padding-inline-end: 30px;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 767.98px) {
  .recipient-detail__payments {
    display: none;
  }
}
.recipient-detail__payments::after {
  content: "";
  position: sticky;
  bottom: -1px;
  left: 0;
  height: 40px;
  min-height: 40px;
  width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
}
.recipient-detail__payments::-webkit-scrollbar {
  width: 4px;
}
.recipient-detail__payments::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.recipient-detail__payments::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.recipient-detail__payment {
  width: 100%;
  background-color: rgba(0, 166, 195, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 13.5px 15px;
}
.recipient-detail__amount {
  overflow-wrap: anywhere;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.recipient-detail__status {
  height: 100%;
  gap: 5px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .recipient-detail__status {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.recipient-detail__status .zmdi {
  font-size: 16px;
}
.recipient-detail__status-verified {
  color: #3B952D;
}
.recipient-detail__status-pending {
  color: #00A6C3;
}
.recipient-detail__status-unverified {
  color: #CD2A2A;
}
.recipient-detail__date {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.recipient-detail__date-mobile {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.recipient-detail__mobile {
  padding-inline: 20px;
  width: 100%;
  gap: 10px;
}
@media (min-width: 768px) {
  .recipient-detail__mobile {
    display: none;
  }
}
.recipient-detail__carousel {
  width: 100%;
  margin-inline-start: 20px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .recipient-detail__carousel {
    display: none;
  }
}
.recipient-detail__carousel-range {
  height: 2px;
  width: 94px !important;
  max-width: 94px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.recipient-detail__carousel-range:focus {
  border-color: #fff;
}
.recipient-detail__item {
  background-color: rgba(0, 166, 195, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 15px;
  gap: 5px;
  overflow: hidden;
}
.recipient-detail__item .recipient-detail__status {
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

/*=============================================
=            Register Component            =
=============================================*/
.register {
  display: flex;
  gap: 80px;
  position: relative;
}
@media (max-width: 1249.98px) {
  .register {
    gap: 60px;
  }
}
.register__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .register__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.register__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.register__left, .register__right {
  width: 100%;
}
.register__left {
  flex: 0.78;
  gap: 20px;
  padding: 80px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  width: calc(100% + 80px);
  max-width: 640px;
}
@media (min-width: 1921px) {
  .register__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .register__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .register__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .register__left {
    padding: 30px 30px 120px;
  }
}
.register__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  min-height: 820px;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .register__right {
    display: none;
  }
}
.register__cards {
  gap: 20px;
}
.register__card {
  flex: 1;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  min-height: 229px;
  min-width: 150px;
  max-width: 270px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .register__card {
    max-width: none;
  }
}
.register__card:hover, .register__card:focus {
  border-color: #00A6C3;
}
.register__card--active {
  border-color: #00A6C3;
}
.register__card--active .register__tick {
  background-color: #00A6C3;
  border-color: #00A6C3;
}
.register__card--active .register__tick .zmdi {
  display: block;
}
.register__card-icon {
  height: 48px;
  width: 48px;
}
.register__card-title {
  text-align: center;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 479.98px) {
  .register__card-title {
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.register__card-text {
  text-align: center;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 479.98px) {
  .register__card-text {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}
.register__tick {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.register__tick .zmdi {
  color: #fff;
  display: none;
}
.register__button {
  width: 100%;
}
.register__bottom {
  width: 100%;
  gap: 20px;
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.register__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.register__pattern {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  translate: 0 0 50px;
}

/*=====  End of Register Component  ======*/
.rte--white {
  color: #fff;
}
.rte__title {
  margin-bottom: 10px;
}
.rte__subtitle {
  margin-bottom: 56px;
}
.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5 {
  display: block;
  margin-bottom: 20px;
}
.rte p,
.rte ol,
.rte ul {
  display: block;
  margin-bottom: 5px;
}
.rte ul,
.rte ol,
.rte li {
  margin-left: 16px;
}
.rte ul,
.rte ol {
  margin-top: 20px;
}
.rte ul {
  list-style: disc;
}
.rte ol {
  list-style: decimal;
}
.rte strong {
  font-weight: 700;
}
.rte a.underline {
  text-decoration: underline;
}
.rte h1 {
  font-size: 50px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .rte h1 {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.rte h2 {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .rte h2 {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.rte h3 {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .rte h3 {
    font-size: 23px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.23px;
  }
}
.rte h4 {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 991.98px) {
  .rte h4 {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.rte h5 {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 991.98px) {
  .rte h5 {
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.rte h6 {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 991.98px) {
  .rte h6 {
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.rte p, .rte li {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.rte a {
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.rte p.p--small {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.rte p strong {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.rte div > :only-child {
  margin-bottom: 0;
}

.rte div > :last-child {
  margin-bottom: 0;
}

/*=====  End of RTE  ======*/
/*=============================================
=            Send Funds            =
=============================================*/
.send-funds__overlay {
  position: fixed;
  top: 70px;
  right: 0;
  width: 100%;
  height: calc(100% - 71px);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
@media (min-width: 1250px) {
  .send-funds__overlay {
    top: 80px;
    height: calc(100% - 80px);
  }
}
@media (max-width: 479.98px) {
  .send-funds__overlay {
    transition: none;
  }
}
.send-funds__overlay--active {
  opacity: 1;
  visibility: visible;
  transition: all cubic-bezier(0.2, 0, 0.2, 1) 0.5s;
}
.send-funds__inner {
  z-index: 101;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0D518B;
  height: calc(100% + 1px);
  width: 600px;
  padding: 30px 70px 30px 60px;
  border-top: 1px solid #236EAE;
}
.send-funds__inner--large-padding {
  padding: 30px 80px 30px 60px;
}
@media (max-width: 767.98px) {
  .send-funds__inner {
    width: 100%;
  }
}
@media (max-width: 479.98px) {
  .send-funds__inner {
    padding: 30px;
  }
}
@media (max-height: 650px) {
  .send-funds__inner .send-funds__main {
    justify-content: flex-start;
  }
}
.send-funds__main, .send-funds__nested {
  height: 100%;
}
.send-funds__buttons {
  padding-bottom: 30px;
  padding-inline-end: 10px;
  width: 100%;
}
.send-funds__buttons--border {
  padding-inline-end: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.send-funds__close, .send-funds__close-mobile {
  height: 40px;
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-left: auto;
}
.send-funds__close .zmdi, .send-funds__close-mobile .zmdi {
  font-size: 18px;
}
@media (max-width: 479.98px) {
  .send-funds__close {
    display: none;
  }
}
.send-funds__close-mobile {
  margin-right: auto;
  margin-left: 0;
}
@media (min-width: 480px) {
  .send-funds__close-mobile {
    display: none;
  }
}
.send-funds__main {
  overflow-y: auto;
}
.send-funds__main--hidden {
  display: none;
}
.send-funds__nested {
  display: none;
  gap: 30px;
  overflow: auto;
  margin: auto;
  height: fit-content;
  padding-top: 30px;
}
.send-funds__nested-link {
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin: 10px 0;
}
.send-funds__nested--active {
  display: flex;
}
.send-funds__columns {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  min-height: 280px;
  width: 100%;
}
@media (max-width: 479.98px) {
  .send-funds__columns {
    min-height: 169px;
  }
}
.send-funds__column {
  text-align: center;
  gap: 20px;
  padding: 20px 15px;
  min-height: 169px;
  flex: 1;
}
.send-funds__column:first-child {
  position: relative;
}
.send-funds__column:first-child::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.send-funds__column .link {
  padding: 10px 20px;
}
.send-funds__column-title {
  max-width: 160px;
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
@media (max-width: 479.98px) {
  .send-funds__column-title {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.send-funds__title {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 479.98px) {
  .send-funds__title {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.send-funds__card {
  position: relative;
  cursor: pointer;
  padding-right: 40px;
  gap: 5px;
}
.send-funds__card:hover .send-funds__card-button, .send-funds__card:focus .send-funds__card-button {
  background-color: #000;
  color: #fff;
}
.send-funds__card:first-child {
  margin-block-end: 30px;
  padding-block-end: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.send-funds__card-subtitle {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.send-funds__card-title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 479.98px) {
  .send-funds__card-title {
    font-size: 20px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}
.send-funds__card-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.send-funds__card-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}
.send-funds__back {
  color: #fff;
  display: none;
}
.send-funds__back--active {
  display: flex;
}
.send-funds__share {
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  display: none;
}
.send-funds__share--active {
  display: flex;
}
.send-funds__share .zmdi {
  height: 28px;
  width: 28px;
  font-size: 28px;
}
.send-funds__bottom {
  width: 100%;
  padding-top: 30px;
}

@media (max-width: 479.98px) {
  .send-funds__inner {
    background-color: transparent;
    transition: none;
    overflow: hidden;
  }
  .send-funds__inner::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: #0D518B;
    height: 0;
    width: 0;
    bottom: -70%;
    left: -120%;
    z-index: -1;
    transition: none;
  }
  .send-funds__overlay--active .send-funds__inner::before {
    content: "";
    position: absolute;
    border-radius: 0;
    border-radius: 50%;
    background-color: #0D518B;
    height: 1500px;
    width: 1500px;
    aspect-ratio: 1/1;
    bottom: -70%;
    left: -120%;
    z-index: -1;
    transition: all cubic-bezier(0.2, 0, 0.2, 1) 0.5s;
  }
}
/*=====  End of Send Funds  ======*/
/*===================================================================
=            Social Share            =
=====================================================================*/
.social-share {
  background-color: #0D518B;
  color: #fff;
  padding: 30px 240px;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .social-share {
    flex-direction: column;
    gap: 10px;
    padding: 30px;
  }
}
.social-share__socials {
  gap: 30px;
}
.social-share__social {
  font-size: 20px;
}
.social-share__social .zmdi {
  height: 20px;
  width: 20px;
}
.social-share__social:hover, .social-share__social:focus {
  color: #000;
}
.social-share__title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}

/*=====  End of Social Share  ======*/
.stepper {
  width: 100%;
  position: relative;
  gap: 30px;
}
.stepper__item {
  gap: 5px;
}
.stepper__item-name {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.stepper__item-icon {
  padding: 7px;
  border-radius: 50%;
  border: 1px solid #0D518B;
  width: 30px;
  height: 30px;
}
.stepper__item--inactive .stepper__item-icon {
  opacity: 0.1;
}
.stepper__item--inactive .stepper__item-name {
  opacity: 0.5;
}
.stepper--breaker {
  height: 1px;
  flex: 1 1 50px;
  background-color: #0D518B;
  transform: translateY(-11px);
}
.stepper--breaker-inactive {
  opacity: 0.1;
}
.stepper--breaker:last-child {
  display: none;
}

@media (max-width: 479.98px) {
  .stepper {
    gap: 1px;
    align-items: flex-start;
  }
  .stepper__item {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .stepper__item-icon {
    width: 20px;
    height: 20px;
  }
  .stepper--breaker {
    height: 1px;
    flex: 1 1 20px;
    background-color: #0D518B;
    transform: translateY(10px);
  }
}
/*=============================================
=            Team Grid            =
=============================================*/
.team-grid {
  position: relative;
  overflow: hidden;
}
.team-grid + .team-grid {
  margin-top: -30px;
}
.team-grid + .team-grid .team-grid__inner {
  padding-top: 0;
}
.team-grid__inner {
  gap: 30px;
  overflow: hidden;
}
.team-grid__header {
  width: 100%;
  gap: 20px;
}
.team-grid__heading {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991.98px) {
  .team-grid__heading {
    font-size: 26px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.52px;
  }
}
.team-grid__text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.team-grid__grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 12.5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .team-grid__grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}
@media (max-width: 479.98px) {
  .team-grid__grid {
    gap: 30px;
    display: none;
  }
}
.team-grid__carousel {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
}
@media (min-width: 480px) {
  .team-grid__carousel {
    display: none;
  }
}
.team-grid__carousel-range {
  height: 2px;
  width: 300px !important;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.team-grid__carousel-range-container {
  position: absolute;
  top: auto;
  top: 100%;
  transform: translateY(15px);
  left: 0;
  width: 100%;
}
.team-grid__card {
  gap: 10px;
  cursor: pointer;
  overflow: hidden;
}
.team-grid__card:hover .team-grid__card-image, .team-grid__card:focus .team-grid__card-image {
  transform: scale(1.02);
}
.team-grid__card:hover .team-grid__card-button, .team-grid__card:focus .team-grid__card-button {
  color: #000;
}
.team-grid__card-top {
  border-radius: 10px;
  height: 230px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .team-grid__card-top {
    height: 145px;
  }
}
.team-grid__card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.team-grid__card-title {
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 479.98px) {
  .team-grid__card-title {
    font-size: 15px;
    line-height: 150%;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.team-grid__card-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 479.98px) {
  .team-grid__card-text {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.team-grid__card-button {
  padding-block-start: 10px;
  padding-block-end: 10px;
  border-radius: 0;
}
.team-grid__banner {
  max-width: 621px;
  gap: 30px;
}
.team-grid__banner-image {
  width: 231px;
  object-fit: contain;
}
.team-grid__banner-right {
  max-width: 360px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.team-grid__modal {
  height: 100vh !important;
  width: 100vw !important;
  margin: 0 !important;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
}
.team-grid__modal:modal {
  max-width: 100vw;
  max-height: 100vh;
}
.team-grid__modal-inner {
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% - 8px), -50%);
  border-radius: 10px;
  height: 500px;
  width: 80%;
  max-width: 1200px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .team-grid__modal-inner {
    flex-direction: column;
    height: 80%;
    max-height: 80%;
  }
}
.team-grid__modal-left, .team-grid__modal-right {
  height: auto;
}
.team-grid__modal-right {
  flex: 1;
  padding: 20px;
  gap: 20px;
  overflow: hidden;
}
.team-grid__modal-left {
  flex: 0.5;
  min-width: 300px;
}
@media (max-width: 767.98px) {
  .team-grid__modal-left {
    flex: none;
    height: 100%;
    width: 100%;
    height: 300px;
  }
}
@media (max-width: 479.98px) {
  .team-grid__modal-left {
    height: 250px;
  }
}
.team-grid__modal-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.team-grid__modal-top {
  gap: 20px;
}
.team-grid__modal-main {
  width: 100%;
}
.team-grid__modal-title {
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
.team-grid__modal-text {
  font-size: 20px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.4px;
}
.team-grid__modal-buttons {
  gap: 10px;
  margin-top: auto;
}
@media (max-width: 767.98px) {
  .team-grid__modal-close {
    position: absolute;
    bottom: 25px;
    right: 20px;
  }
}
.team-grid__modal .rte {
  overflow: auto;
  height: 100%;
}

/*=====  End of Team Grid  ======*/
.cards {
  gap: 30px;
  position: relative;
}
.cards__title .rte {
  text-align: center;
}
.cards__container {
  gap: 20px;
}
.cards__card {
  flex: 1;
  padding: 40px;
  background-color: #0D518B;
  color: #fff;
  gap: 20px;
  border-radius: 10px;
  min-width: 280px;
  width: 100%;
}
.cards__card-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.cards__card-icon {
  width: 80px;
  height: 80px;
}
.cards__card-content {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  text-align: center;
}
.cards__card .link {
  margin-top: auto;
}

@media (max-width: 767.98px) {
  .cards__title .rte {
    text-align: left;
  }
  .cards__container {
    flex-direction: column;
  }
  .cards__card {
    gap: 10px;
    padding: 20px 10px;
  }
  .cards__card-icon {
    width: 64px;
    height: 64px;
  }
}
/*=============================================
=            Transfer Summary Component            =
=============================================*/
.transfer-summary {
  display: flex;
  padding: 0;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .transfer-summary {
    flex-direction: column;
  }
}
.transfer-summary__heading {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
  color: #0D518B;
}
.transfer-summary__wrapper {
  display: none;
  background-image: linear-gradient(to right, rgb(255, 255, 255) 50%, rgba(0, 166, 195, 0.05) 50%);
}
@media (max-width: 767.98px) {
  .transfer-summary__wrapper {
    background-image: none;
  }
}
.transfer-summary__wrapper--active {
  display: block;
}
.transfer-summary__divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
.transfer-summary__back {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.transfer-summary__title {
  margin: auto;
  font-size: 25px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .transfer-summary__title {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.15px;
  }
}
.transfer-summary__top {
  position: relative;
  padding: 30px 80px;
  background-color: #fff;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .transfer-summary__top {
    padding: 20px;
  }
}
.transfer-summary__left, .transfer-summary__right {
  width: 100%;
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
}
.transfer-summary__left {
  display: none;
  flex: 1;
  gap: 20px;
  margin-block: 40px 0;
  padding-block-end: 40px;
  padding-inline: 80px 36px;
  margin-inline-end: 40px;
  min-height: 670px;
  max-height: 670px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  .transfer-summary__left {
    padding-inline: 20px 20px;
    margin-inline-end: 0;
  }
}
@media (max-width: 767.98px) {
  .transfer-summary__left {
    margin-block: 20px 40px;
    padding-block-end: 0;
    padding-inline: 0;
    margin-block-end: 0;
    min-height: auto;
    max-height: none;
  }
}
.transfer-summary__left--active {
  display: flex;
}
.transfer-summary__left::-webkit-scrollbar {
  width: 4px;
}
.transfer-summary__left::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.transfer-summary__left::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
}
.transfer-summary__left::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.transfer-summary__left-top {
  gap: 20px;
}
.transfer-summary__left hr {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  min-height: 1px;
}
.transfer-summary__left .link {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .transfer-summary__left .link {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}
.transfer-summary__right {
  flex: 1;
  gap: 20px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-inline: 80px;
  padding-block: 40px;
  z-index: 1;
  display: none;
}
.transfer-summary__right--active {
  display: flex;
}
@media (max-width: 991.98px) {
  .transfer-summary__right {
    padding-inline: 20px;
  }
}
@media (max-width: 767.98px) {
  .transfer-summary__right {
    border-left: none;
    padding-block: 20px 40px;
    background-color: rgba(0, 166, 195, 0.05);
  }
}
.transfer-summary__right-title {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-block-end: 20px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.transfer-summary__subtitle {
  padding-block-end: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .transfer-summary__subtitle {
    padding-inline: 20px;
  }
}
@media (max-width: 767.98px) {
  .transfer-summary__subtitle--bank {
    display: none;
  }
}
.transfer-summary__list {
  gap: 20px;
}
@media (max-width: 767.98px) {
  .transfer-summary__list {
    padding-inline: 20px;
  }
}
.transfer-summary__item {
  gap: 20px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.transfer-summary__bottom {
  padding-block-start: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .transfer-summary__bottom {
    padding-inline: 20px;
  }
}
.transfer-summary__bottom-title {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.transfer-summary__bottom-text {
  font-size: 15px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.transfer-summary__button {
  display: none;
  width: 100%;
}
@media (max-width: 767.98px) {
  .transfer-summary__button {
    display: flex;
  }
  .transfer-summary__button-container {
    background-color: rgba(0, 166, 195, 0.05);
    padding: 20px 20px 40px;
  }
}
.transfer-summary__form {
  width: 100%;
  gap: 20px;
}
.transfer-summary__form input[type=text] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline-color: #000;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  height: 40px;
  font-size: 15px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.15px;
}
.transfer-summary__form input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.transfer-summary__form .button {
  width: 100%;
}
.transfer-summary__field {
  gap: 5px;
  width: 100%;
}
.transfer-summary__label {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.13px;
}

/*=====  End of Transfer Summary Component  ======*/
.individual-upload {
  display: flex;
  gap: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow: visible;
}
@media (max-width: 1249.98px) {
  .individual-upload {
    gap: 60px;
  }
}
.individual-upload__wrapper {
  display: none;
  visibility: hidden;
}
.individual-upload__wrapper--active {
  display: block;
  visibility: visible;
}
.individual-upload__left, .individual-upload__right {
  width: 100%;
}
.individual-upload__left {
  flex: 0.78;
  gap: 20px;
  padding: 40px 0 80px 80px;
  margin-left: calc((100vw - 1440px) / 2);
  max-width: 640px;
  width: calc(100% + 80px);
  height: fit-content;
  min-height: 820px;
  max-width: 640px;
}
@media (min-width: 1921px) {
  .individual-upload__left {
    flex: 1;
  }
}
@media (max-width: 1439.98px) {
  .individual-upload__left {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .individual-upload__left {
    flex: 1;
    padding-right: 80px;
    margin: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .individual-upload__left {
    padding: 30px;
  }
}
.individual-upload__right {
  flex: 1;
  position: relative;
  background-color: #00A6C3;
  transform-style: preserve-3d;
  perspective: 1000px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .individual-upload__right {
    display: none;
  }
}
.individual-upload__back {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.individual-upload__title {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  .individual-upload__title {
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: -0.6px;
  }
}
.individual-upload__text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.15px;
}
.individual-upload__validations {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  gap: 10px;
  width: 100%;
}
.individual-upload__images {
  gap: 20px;
}
.individual-upload__image {
  border: 1px solid #CD2A2A;
}
.individual-upload__image-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
}
.individual-upload__image-container .zmdi {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  color: #CD2A2A;
}
.individual-upload__image-container .zmdi::after {
  content: "";
  background-color: #fff;
  height: 11px;
  width: 11px;
  position: absolute;
  top: 2.5px;
  left: 1.5px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  z-index: -1;
}
.individual-upload__image-container:first-child .zmdi {
  color: #3B952D;
}
.individual-upload__image-container:first-child .individual-upload__image {
  border-color: #3B952D;
}
.individual-upload__points {
  gap: 10px;
}
.individual-upload__point {
  gap: 5px;
}
.individual-upload__point-icon {
  height: 20px;
  width: 20px;
}
.individual-upload__item {
  width: 100%;
  gap: 20px;
}
.individual-upload__item-title {
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.individual-upload__upload {
  border: 1px dashed #00A6C3;
  background-color: rgba(0, 166, 195, 0.05);
  padding: 20px 50px;
  border-radius: 5px;
  width: 100%;
  position: relative;
  min-height: 106.5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-upload__upload--active {
  background-color: rgba(0, 166, 195, 0.2);
}
.individual-upload__upload-main {
  gap: 10px;
  display: none;
}
.individual-upload__upload-main--active {
  display: flex;
}
.individual-upload__upload-buttons {
  gap: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .individual-upload__upload-buttons {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .individual-upload__upload-buttons {
    flex-direction: column;
  }
}
.individual-upload__upload-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media (min-width: 768px) {
  .individual-upload__upload-text {
    display: none;
  }
}
.individual-upload__upload-info {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-upload__upload-camera {
  min-width: 200px;
}
.individual-upload__upload-button {
  position: relative;
  display: inline-block;
}
@media (max-width: 389.98px) {
  .individual-upload__upload-button {
    width: 100%;
  }
}
.individual-upload__upload-label {
  cursor: pointer;
  background-color: #0D518B;
  color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  width: inherit;
  min-width: 200px;
  gap: 10px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 389.98px) {
  .individual-upload__upload-label {
    min-width: auto;
    width: 100%;
  }
}
.individual-upload__upload-label:hover {
  background-color: #000;
}
.individual-upload__upload-placeholder {
  font-size: 15px;
  line-height: 130%;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.individual-upload__upload-input:focus + .individual-upload__upload-label {
  outline: 1px solid #000;
  background-color: #000;
}
.individual-upload__upload-form {
  width: 100%;
  gap: 20px;
}
@media (min-width: 768px) {
  .individual-upload__capture-button {
    display: none;
  }
}
.individual-upload__button {
  min-width: 200px;
}
.individual-upload__buttons {
  width: 100%;
}
.individual-upload input[type=file] {
  color: transparent;
  position: absolute;
  z-index: -1;
}
.individual-upload__submit {
  width: 100%;
}
.individual-upload__submit:disabled {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.individual-upload__preview {
  display: none;
  gap: 10px;
}
.individual-upload__preview--active {
  display: flex;
}
.individual-upload__preview-image {
  height: 60px;
  width: 60px;
  min-width: 60px;
  object-fit: cover;
  display: none;
}
.individual-upload__preview-image--active {
  display: block;
}
.individual-upload__preview-pdf {
  height: 60px;
  width: 60px;
  min-width: 60px;
  display: none;
}
.individual-upload__preview-pdf--active {
  display: block;
}
.individual-upload__preview-name, .individual-upload__preview-size {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.individual-upload__preview-size {
  color: rgba(0, 0, 0, 0.5);
}
.individual-upload__preview-file {
  width: 100%;
}
.individual-upload__preview-left {
  flex: 1;
  gap: 5px;
  margin-bottom: 5px;
}
.individual-upload__preview-right {
  gap: 20px;
  margin-left: auto;
}
.individual-upload__preview-right .zmdi {
  font-size: 16px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.individual-upload__preview-delete {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}
.individual-upload__preview-delete:hover .zmdi, .individual-upload__preview-delete:focus .zmdi {
  color: #000;
}
.individual-upload__progress {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 4px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.individual-upload__progress-inner {
  width: 0;
  height: 100%;
  background-color: #3a6df0;
  transition: width 0.3s ease;
}
.individual-upload__progress-inner--complete {
  background-color: #3B952D;
}
.individual-upload__status-invalid {
  color: rgba(0, 0, 0, 0.5);
  display: none;
}
.individual-upload__status-invalid--active {
  display: inline-block;
}
.individual-upload__status-valid {
  color: #3B952D;
  display: none;
}
.individual-upload__status-valid--active {
  display: inline-block;
}