/*===================================================================
=            Feature Media With Text            =
=====================================================================*/

.feature-media-with-text {
  padding: 80px 0;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding: 30px 0;
  }

  &__left,
  &__right {
    flex: 1;
  }

  &__inner {
    gap: 120px;

    &.flex--row-reverse {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }
 
    @include media-breakpoint-down(desktop) {
      gap: 60px;
    }

    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }

    @include media-breakpoint-down(sm) {
      gap: 30px;
    }
  }

  &__left {
    box-sizing: border-box;
    gap: 30px;
    min-width: 400px;

    @include media-breakpoint-down(lg) {
      min-width: auto;
    }
  }

  &__right {
    position: relative;
    height: 480px;
    border-radius: 10px;
    overflow: hidden;
    min-width: 600px;

    @include media-breakpoint-down(desktop) {
      min-width: 400px;
      flex: auto;
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      min-width: $container-width--wide;
      display: none;
      flex: auto;
    }

    &-mobile {
      display: none;
      
      @include media-breakpoint-down(sm) {
        display: grid;
        min-height: 300px;
        max-height: 300px;
        overflow: hidden;
      }
    }
  }

  &__video {
    width: $container-width--wide;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      height: $container-height--wide;
    }
  }

  &__image {
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    vertical-align: middle;

    @include media-breakpoint-between(md,xxl) {
      width: auto;
      object-fit: contain;
      margin: 0 auto;
      border-radius: 10px
    }
  }

  &__title {

    @include HeadingH1Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH2Mobile;
    }
  }

  &__text {
    @include BodyText;

    opacity: 0.8;
  }

  &__bullets {
    width: $container-width--wide;
    gap: 10px;
    padding: 10px 0;
  }

  &__bullet {
    gap: 10px;

    @include HeadingH5Desktop;

    @include media-breakpoint-down(sm) {
      @include BodyTextBold;
    }

    &-icon {
      height: 24px;
      width: 24px;
    }
  }

  .link {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

/*=====  End of Feature Media With Text  ======*/