/*=============================================
=            New Payment Contacts Component            =
=============================================*/

.new-pay {
  display: flex;
  padding-inline-start: 80px;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding-inline-start: 40px;
  }

  @include media-breakpoint-down(md) {
    padding-inline-start: 0;
    flex-direction: column-reverse;
  }

  &__divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.10);
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none; 
    }
  }

  &__title {
    margin: auto;

    @include HeadingH4Desktop; 

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      min-height: 40px;
      text-align: center;

      @include BodyText; 
    }
  }

  &__top {
    position: relative;
    padding: 30px 80px;

    @include media-breakpoint-down(lg) {
      padding-inline: 20px;
    }
    
    @include media-breakpoint-down(md) {
      padding-block: 20px;
      margin-block-end: 20px;
      gap: 20px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__info {
    padding-inline-end: 80px;

    @include media-breakpoint-down(md) {
      padding-inline: 20px;
    }
  }

  &__subtitle {
    @include BodyText;

    @include media-breakpoint-down(md) {
      @include BodyTextSm;
    }
  }

  &__number {
    @include BodyTextTiny;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__left, &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.89;
    gap: 30px;
    padding-block-start: 40px;
    padding-block-end: 30px;
    min-height: 750px;

    @include media-breakpoint-down(lg) {
      flex: 0.80;
    }

    @include media-breakpoint-down(md) {
      flex: 1;
      padding-block-start: 0;
      padding-block-end: 20px;
      min-height: auto;
      gap: 20px;
    }
  }
  
  &__right {
    flex: 1;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.10);
    padding-block: 40px;
    background-color: $bg--white;

    @include media-breakpoint-down(md) {
      padding-block: 0 20px;
      margin-block-end: 20px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.10);
        height: 1px;
        width: 1000%;
      }
      
      &::-webkit-scrollbar {
        width: 4px;
      }
  
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.10);
        border-radius: 2px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $black;
        border-radius: 2px;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.50);
      }
    }
  }

  &__skeleton {
    width: 100%;
    background-color: rgba(0, 166, 195, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-block: 17px;
    padding-inline: 10px 15px;
    max-height: 90px;

    @include transition;

    &:hover {
      border-color: $black;

      .new-pay__button {
        background-color: $bg--black;
      }
    }

    &-left {
      width: 100%;
      gap: 10px;
    }
  }

  &__bars {
    width: 100%;
    height: 100%;
    gap: 10px;
  }

  &__bar {
    border-radius: 3px;
    opacity: 0.1;

    &:nth-child(1) {
      width: 75.398%;
      background-color: $bg--dark-blue;
      height: 12px;
    }

    &:nth-child(2) {
      width: 58.745%;
      background-color: $bg--dark-blue;
      height: 12px;
    }

    &:nth-child(3) {
      width: 27.645%;
      background-color: $bg--dark-blue;
      height: 12px;
    }
  }

  &__button {  
    cursor: pointer;
    padding: 10px 15px;
    gap: 10px;
    min-width: max-content;
    border-radius: 20px;
    background-color: $bg--dark-blue;
    color: $text--white;

    @include transition; 
    @include Button;

    &:hover, &:focus {
      background-color: $bg--black;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-container {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      height: 50px;
      width: 50px;
      min-height: 50px;
      min-width: 50px;
      background-color: $bg--white;
      object-fit: contain;
      padding: 16px;
    }
  }

  &__form, &__inputs {
    width: $container-width--wide;
    gap: 20px;
  }

  &__inputs {
    .new-pay__label {
      @include media-breakpoint-down(md) {
        width: 50%;
        min-width: 300px;
      }
  
      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: auto;
      }
    }
  }

  &__form {
    padding-inline-end: 80px;

    @include media-breakpoint-down(lg) {
      padding-inline-end: 60px;
    }

    &-desktop {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-mobile {
      padding-inline-end: 0;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    
    input[type="text"] {
      border-radius: 25px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      outline-color: $black;
      width: $container-width--wide;
      padding-block: 10px;
      padding-inline: 20px 41px;
      background-color: $bg--white;
      background-image: url("/assets/img/img/search-icon.svg");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position-x: calc(100% - 16px);
      background-position-y: 13px;
      height: 40px;
      
      @include Input;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    [type="date"] {
      position: relative;
      background:#fff url(/assets/img/img/date-icon.svg) no-repeat;
      background-position-x: calc($container-width--wide - 15px);
      background-position-y: 50%;
      border-radius: 25px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      padding: 10px 20px;
      height: 40px;
      width: 100%;
      margin-block-start: 5px;
      cursor: pointer;

      @include Input;

      @include media-breakpoint-down(md) {
        width: auto;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    [type="date"]::-webkit-inner-spin-button {
      display: none;
    }

    [type="date"]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;
    } 
  }

  &__contacts {
    position: relative;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding-inline: 0; 
    }

    &-header {
      position: relative;
      width: 100%;
      gap: 10px;
      padding-inline-end: 80px;
      padding-block: 40px 30px;
      max-width: 640px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10000%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.10);
        transform: translateX(-30px);
  
        @include media-breakpoint-down(md) {
          display: none; 
        }
      }

      @include media-breakpoint-down(lg) {
        padding-inline-end: 59px;
      }

      @include media-breakpoint-down(md) {
        padding-inline: 20px; 
        padding-block: 20px;

        .link {
          display: none;
        }
      }
    }

    &-title {
      @include BodyText;

      @include media-breakpoint-down(md) {
        max-width: 70%;
      }
    }

    &-list {
      position: relative;
      gap: 10px;
      padding-inline-end: 40px;
      margin-inline-end: 40px;
      max-height: 533px;
      overflow-y: auto;
      overflow-x: hidden;

      &::after {
        content: '';
        position: sticky;
        bottom: -3px;
        left: 0;
        height: 100px;
        min-height: 100px;
        width: 100%;
        pointer-events: none;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
      }

      @include media-breakpoint-down(lg) {
        padding-inline-end: 20px;
        margin-inline-end: 20px;
      }

      @include media-breakpoint-down(md) {
        padding-inline-start: 20px;
        max-height: none;
        margin-inline-end: 0;

        &::after {
          content: none;
        }
      }
  
      &::-webkit-scrollbar {
        width: 4px;
      }
  
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.10);
        border-radius: 2px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $black;
        border-radius: 2px;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.50);
      }
    }
  }

  &__recipients {
    width: 100%;
    padding-inline-start: 40px;

    @include media-breakpoint-down(md) {
      overflow: hidden;
      padding-inline-start: 20px;
    }
    
    &-carousel {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &-list {
      position: relative;
      gap: 10px;
      padding-inline-end: 80px;
      max-height: 512px;
      overflow-y: auto;
  
      @include media-breakpoint-down(lg) {
        padding-inline-end: 30px; 
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-info {
      padding-block: 40px 30px;
      padding-inline-end: 80px;
      
      @include media-breakpoint-down(lg) {
        padding-inline-end: 30px; 
      }

      @include media-breakpoint-down(md) {
        padding-inline-end: 30px; 
        padding-block: 0 20px;
      }
    }

    &-subtitle {      
      @include BodyText;

      @include media-breakpoint-down(md) {
          @include BodyTextSm;        
      }
    }

    &-header {
      position: relative;
      width: 100%;
      gap: 30px;
      padding-inline-end: 80px;
      padding-block: 0 40px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: -40px;
        width: 1000%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.10);

        @include media-breakpoint-down(lg) {
          padding-inline-end: 30px; 
        }
  
        @include media-breakpoint-down(md) {
          display: none; 
        }
      }

      @include media-breakpoint-down(lg) {
        padding-inline-end: 30px; 
      }
      
    @include media-breakpoint-down(md) {
      display: none;
    }
    }
  }

  &__field {
    width: 100%;
    gap: 5px;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  &__label {
    width: 100%;

    @include Label;
  }

  &__list {
    position: relative;
    max-height: 567px;
    overflow-y: auto;
    padding-inline-end: 40px;
    margin-inline-end: 40px;

    @include media-breakpoint-down(lg) {
      padding-inline-end: 30px;
      margin-inline-end: 30px;
    }

    @include media-breakpoint-down(md) {
      padding-inline-end: 0px;
      margin-inline-end: 0px;
    }

    &::after {
      content: '';
      position: sticky;
      bottom: 0;
      left: 0;
      height: 100px;
      min-height: 100px;
      width: 100%;
      pointer-events: none;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.10);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $black;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.50);
    }
  }

  &__item {
    width: 100%;
    padding: 15px 20px;
    background-color: rgba(0, 166, 195, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    gap: 10px;
    cursor: pointer;

    @include transition;

    &:hover, &:focus {
      border-color: rgba(0, 166, 195, 0.05);
      background-color: $bg--light-blue;
      color: $text--white;

      .new-pay__item-date {
        color: $text--white;
      }
    }

    &--active {
      border-color: rgba(0, 166, 195, 0.05);
      background-color: $bg--light-blue;
      color: $text--white;

      .new-pay__item-date {
        color: $text--white;
      }
    }

    &--hidden {
      display: none;
    }

    &-caption {
      margin-block-start: 20px;
      width: 100%;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      padding-block-end: 20px;

      @include BodyTextSm;
    }

    &-title, &-amount {
      @include BodyText;
    }

    &-info {
      margin: 0 auto;
      gap: 10px;
    }

    &-status {
      .zmdi {
        color: $dark-blue;
        height: 16px;
        width: 16px;
      }
    }

    &-dash {
      height: 4px;
      width: 30px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.3);

      @include media-breakpoint-down(md) {
        width: 16px;
      }
    }

    &-date {
      min-width: max-content;
      color: rgba(0, 0, 0, 0.5);

      @include BodyTextSm;
    }
  }

  &__header {
    padding-inline: 30px 80px;
    padding-block-end: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    width: 100%;
    gap: 10px;

    @include media-breakpoint-down(lg) {
      padding-inline-end: 30px;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  &__mobile {
    &-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $bg--white;
      padding: 20px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &-link {
      width: 50%;
      min-width: 300px;
      margin: auto;

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: auto;
      }
    }
  }

  &__load {
    width: 100%;
    padding-block-start: 20px;
    padding-block-end: 0;

    @include media-breakpoint-down(md) {
      padding-block-end: 120px;
    }
  }

  &__range {
    height: 2px;
    width: 94px !important;
    background-color: rgba(0, 0, 0, 0.10);
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &-container {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

/*=====  End of New Payment Contacts Component  ======*/