/*=============================================
=            Create Payment Component            =
=============================================*/

.create-pay {
  display: flex;
  padding: 0;
  position: relative;
  max-width: $container-width--default;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__wrapper {
    display: none;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 166, 195, 0.05) 50%
    );

    @include media-breakpoint-down(md) {
      background-image: none;
    }

    &--active {
      display: block;
    }
  }

  &__divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  &__title {
    margin: auto;

    @include HeadingH4Desktop;

    @include media-breakpoint-down(md) {
      @include BodyText;
    }
  }

  &__top {
    position: relative;
    padding: 30px 80px;
    background-color: $bg--white;

    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }

  &__subtitle,
  &__error {
    @include BodyText;

    @include media-breakpoint-down(md) {
      @include BodyTextSm;
    }
  }

  &__error {
    display: none;
    color: $text--red;

    &--active {
      display: flex;
    }
  }

  &__min {
    &-container {
      gap: 5px;
    }

    &-text {
      @include BodyTextBold;
    }

    &-amount {
      @include BodyText;
    }
  }

  &__threshold {
    @include BodyTextBold;

    &-container {
      gap: 5px;
    }

    &-amount {
      @include BodyText;
    }

    &-error {
      display: none;
      color: $text--red;

      &--active {
        display: flex;
      }
    }
  }

  &__left,
  &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 1;
    gap: 20px;
    margin-block: 40px 0;
    padding-inline: 80px 36px;
    margin-inline-end: 40px;
    min-height: 670px;
    max-height: 670px;
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-down(lg) {
      padding-inline: 20px 20px;
      margin-inline-end: 0;
    }

    @include media-breakpoint-down(md) {
      margin-block: 20px 40px;
      min-height: auto;
      max-height: none;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $black;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }

    &-top {
      gap: 20px;
    }

    hr {
      background-color: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 1px;
      min-height: 1px;
    }

    .link {
      @include BodyText;

      @include media-breakpoint-down(md) {
        @include BodyTextSm;
      }
    }
  }

  &__right {
    flex: 1;
    gap: 20px;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-inline: 80px;
    padding-block: 40px;

    @include media-breakpoint-down(lg) {
      padding-inline: 20px;
    }

    @include media-breakpoint-down(md) {
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-block: 20px 40px;
      background-color: rgba(0, 166, 195, 0.05);
    }

    &-title {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-block-end: 20px;

      @include BodyTextBold;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 17px 1fr;
    background-color: $bg--dark-blue;
    color: $text--white;
    padding: 20px;
    border-radius: 10px;
    gap: 30px;

    @include media-breakpoint-down(xl) {
      gap: 11.5px;
    }

    &-icon {
      font-size: 25.5px;
      height: 48px;
      margin-top: auto;
    }

    select,
    input {
      background-color: $bg--white;
      color: $text--black;
      width: 100%;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      text-align: center;
      height: 50px;

      @include HeadingH3Mobile;
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      background-image: url("/assets/img/img/caret-down-black-icon.svg");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 20px);
      background-position-y: 22px;
      margin-top: auto;
      cursor: pointer;

      option {
        color: $text--black;

        @include BodyText;

        &:first-child {
          color: $text--black;

          @include BodyText;
        }
      }
    }

    &--disabled {
      input {
        border: 2px solid $red;
      }
    }
  }

  &__column {
    flex: 1;
    gap: 10px;
    height: auto;
    width: 100%;

    &-small {
      gap: 10px;

      &-space {
        height: 22.5px;
      }
    }

    &-title,
    &-subtitle {
      text-align: center;

      @include BodyText;
    }

    &-title {
      @include media-breakpoint-down(md) {
        @include BodyTextSm;
      }
    }
  }

  &__dropdown {
    width: 100%;
    gap: 5px;

    @include media-breakpoint-down(md) {
      order: 2;
    }

    &-title {
      @include Label;
    }

    &-select {
      border: 1px solid rgba(0, 0, 0, 0.5);
      padding-inline: 20px 37px;
      padding-block: 10px;
      border-radius: 5px;
      width: 100%;
      appearance: none;
      -webkit-appearance: none;
      background-image: url("/assets/img/img/caret-down-black-icon.svg");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 20px);
      background-position-y: 18px;
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;

      @include Input;

      option {
        color: $text--black;
      }

      option:first-child {
        color: $text--black;
      }
    }
  }

  &__text {
    @include BodyTextSm;
  }

  &__caption {
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);

    @include BodyTextSm;
  }

  &__content {
    width: 100%;
    gap: 20px;
  }

  &__icon {
    position: relative;
    margin-left: 10px;
    color: $text--dark-blue;
    cursor: pointer;
  }

  &__tooltip {
    visibility: hidden;
    width: 200px;
    background-color: $bg--dark-blue;
    color: $text--white;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    margin-left: 10px;
    z-index: $above;
    opacity: 0;
    @include transition;

    &--active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__icon:hover + &__tooltip,
  &__icon:active + &__tooltip {
    opacity: 1;
  }

  &__row {
    width: 100%;
    gap: 20px;

    @include BodyText;

    &-title {
      @include media-breakpoint-down(md) {
        @include BodyTextSm;
      }
    }

    &:last-child {
      padding-block-start: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      .create-pay__row-text {
        font-weight: $font-weight--bold;
      }
    }
  }

  &__button {
    width: 100%;
  }

  &__items {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 166, 195, 0.05);
    margin-block-end: 111px;

    @include media-breakpoint-down(md) {
      margin-block-end: 0;
    }
  }

  &__item {
    &-left {
      gap: 10px;
    }

    &-label {
      position: relative;
      width: 100%;
      gap: 10px;
      padding: 20px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        width: calc(100% - 40px);
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    input {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      appearance: none;
      -webkit-appearance: none;
      cursor: pointer;

      &:checked + .create-pay__item-icon {
        background-image: url("/assets/img/img/tick-black-icon.svg");
      }

      &:hover + .create-pay__item-icon {
        border-color: $light-blue;
      }
    }

    &-icon {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.5);
      background-color: $bg--white;
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: center center;

      @include transition;
    }

    &-title {
      flex: 1;

      @include BodyTextSm;
    }

    &-text {
      color: $text--green;

      @include BodyTextSm;
    }
  }

  &__cards {
    gap: 10px;
  }

  &__card {
    border-radius: 10px;
    background-color: #00a6c30d;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;

    &-title {
      color: $text--black;
      opacity: 0.8;
      margin-bottom: 5px;

      @include BodyTextSm;
    }

    &--lg {
      flex: 0 1 70%;
    }

    &--sm {
      flex: 0 0 30%;
    }
  }

  &__change-currency {
    @include BodyTextSm;
  }

  &__currencies {
    margin-top: auto;
    &-save {
      width: 100%;
    }

    option:first-child {
      color: $text--black;
    }
  }
}
/*=====  End of Create Payment Component  ======*/
