.stepper {
    width: 100%;
    position: relative;
    gap: 30px;

    &__item {
        gap: 5px;

        &-name {
            @include BodyTextSm;
        }

        &-icon {
            padding: 7px;
            border-radius: 50%;
            border: 1px solid $dark-blue;
            width: 30px;
            height: 30px;
        }

        &--inactive {
            .stepper__item {
                &-icon {
                    opacity: .1;
                }

                &-name {
                    opacity: .5;
                }
            }
        }
    }

    &--breaker {
        height: 1px;
        flex: 1 1 50px;
        background-color: $dark-blue;
        transform: translateY(-11px);

        &-inactive {
            opacity: .1;
        }
    }

    &--breaker:last-child {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .stepper {
        gap: 1px;
        align-items: flex-start;

        &__item {
            flex-direction: column;
            align-items: center;
            gap: 5px;

            &-icon {
                width: 20px;
                height: 20px;
            }
        }

        &--breaker {
            height: 1px;
            flex: 1 1 20px;
            background-color: $dark-blue;
            transform: translateY(10px);
        }
    }
}