/*=============================================
=            Button            =
=============================================*/

.button {
  cursor: pointer;
  padding: 10px 15px;
  gap: 10px;
  min-width: max-content;
  border-radius: 20px;

  @include transition; 
  @include Button;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--primary {
    background-color: $bg--dark-blue;
    color: $text--white;

    &:hover, &:focus {
      background-color: $bg--black;
    }

    &-white {
      background-color: $bg--white;
      color: $text--dark-blue;
      border: 1px solid transparent;

      &:hover, &:focus {
        background-color: $bg--dark-blue;
        color: $text--white;
        border-color: $white;
      }
    }
  }
  
  &--simple {
    padding: 0;
    color: $text--dark-blue;

    &:hover, &:focus {
      color: $text--black;
    }
    
    &-white {
      padding: 0;

      &:hover, &:focus {
        color: $text--black;
      }
    }
  }

  &--round {
    &-blue {
      background-color: $bg--dark-blue;
      color: $text--white;
      border-color: $white; 
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      border: 1px solid $white;

      &:hover, &:focus {
        background-color: $bg--white;
        color: $bg--dark-blue;
      }

      .zmdi {
        font-size: 20px;
      }
    }

    &-white {
      background-color: $bg--white;
      color: $text--dark-blue;
      border-color: $white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid transparent;

      &:hover {
        background-color: $bg--black;
        color: $text--white;
      }

      .zmdi {
        font-size: 20px;
      }

      &-alt {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border: 1px solid rgba(0,0,0,0.3);

        &:hover {
          background-color: $bg--dark-blue;
          color: $text--white;
          border-color: $dark-blue;
        }
      }
    }
  }
}

/*=====  End of Button  ======*/
