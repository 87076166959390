/*=============================================
=            CTA Cards            =
=============================================*/

.cta-cards {
  position: relative;
  overflow: hidden;

  &__inner {
    gap: 30px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding: 90px 30px 122px;
    }
  }

  &__header {
    width: $container-width--wide;
  }

  &__heading {
    @include HeadingH3Desktop;

    @include media-breakpoint-down(lg) {
      @include HeadingH3Mobile;
    }
  }

  &__grid {
    display: grid;
    width: $container-width--wide;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: 13px;

    @include transition;
    
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    @include media-breakpoint-down(md) {
      gap: 30px;
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__card {
    position: relative;
    overflow: hidden;
    white-space: normal;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    height: 290px;
    z-index: $above;
    color: $text--white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-down(lg) {
      height: 260px;
    }

    &:hover, &:focus {
      color: $text--white;

      .cta-cards__button {
        background-color: $bg--black;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: $container-height--wide;
      width: $container-width--wide;
      background-color: rgb(13, 81, 139, 0.8);
      z-index:$below;
    }

    &-inner {
      height: $container-height--wide;
    }

    .link {
      align-self: flex-end;
      margin: auto;
    }
  }

  &__title {
    flex: 1;
    align-items: center;
    margin-block-start: 30px;

    @include HeadingH2Mobile;
  }

  &__button {
    border-radius: 50%;
    background-color: $bg--dark-blue;
    color: $text--white;
    height: 30px;
    width: 30px;

    @include transition;
  }

  &__carousel {
    width: $container-width--wide;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &-range {
      height: 2px;
      width: 300px !important;
      max-width: 300px;
      background: rgba(0, 0, 0, 0.10);
      border-radius: 8px;
      height: 2px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      @include transition;

      &:focus {
        border-color: $white;
      }

      &-container {
        position: absolute;
        top: auto;
        bottom: 83px;
        left: 120px;
        width: 100%;

        @include media-breakpoint-down(md) {
          left: 30px;
        }
      }
    }
  }
}

/*=====  End of CTA Cards  ======*/
