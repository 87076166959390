/*=============================================
=            Organisation Profile           =
=============================================*/

.org-profile {
  display: flex;
  gap: 80px;
  position: relative;

  @include media-breakpoint-down(xl) {
    gap: 60px;
  }

  &__title {
    @include HeadingH2Desktop; 

    @include media-breakpoint-down(md) {
      @include HeadingH1Mobile; 
    }
  }

  &__left, &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.78;
    gap: 20px;
    padding: 80px 0 80px 80px;
    margin-left: calc((100vw - $container-width--default) / 2);
    max-width: calc(560px + 80px);
    width: calc(100% + 80px);
    max-width: 640px;

    @include media-breakpoint-up(wide) {
      flex: 1;
    }

    @include media-breakpoint-down(desktop) {
      margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
      flex: 1;
      padding-right: 80px;
      margin: auto;
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 30px 30px 120px;
    }
  }
  
  &__right {
    flex: 1;
    position: relative;
    background-color: $bg--light-blue;
    min-height: 820px;
    transform-style: preserve-3d;
    perspective: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      display: none
    }
  }

  &__cards {
    gap: 20px;
  }

  &__card {
    flex: 1;
    gap: 10px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    padding: 10px;
    min-height: 161px;
    cursor: pointer;

    @include transition;

    &:hover, &:focus {
      border-color: $light-blue;
      box-shadow: 0px 4px 15px 0px #0000001A;
    }

    &--active {
      border-color: $light-blue;    
      box-shadow: 0px 4px 15px 0px #0000001A;
      
      .org-profile__tick {
        background-color: $bg--light-blue;
        border-color: $light-blue;

        .zmdi {
          display: block;
        }
      }
    }

    &-icon {
      height: 48px;
      width: 48px;
    }

    &-title {
      @include HeadingH5Desktop;

      @include media-breakpoint-down(sm) {
        @include HeadingH5Mobile;
      }
    }

    &-text {
      text-align: center;

      @include BodyTextSm;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.12px;
      }
    }
  }

  &__tick {
    height: 100%;
    width: 100%;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    overflow: hidden;

    @include transition;

    .zmdi {
      color: $text--white;
      display: none;
    }
  }

  &__button {
    width: 100%;
  }

  &__pattern {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    translate: 0 0 50px;
  } 

  &__form {
    gap: 20px;
    display: none;

    &--active {
      display: flex;
    }
  }

  &__field {
    gap: 5px;
  }

  &__label {
    @include Label;

    &-required {
      color: $text--red;
    }
  }

  &__validation {
    color: $text--red;
    display: none;

    @include BodyTextSm;

    &--active {
      display: block;
    }
  }

  &__next {
    &:disabled {
      filter: grayscale(1);
      pointer-events: none;
    }
  }
}

/*=====  End of Organisation Profile ======*/