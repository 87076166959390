/*=============================================
=            Found Recipient Component            =
=============================================*/

.found-recipient {
    gap: 30px;
    padding-block: 60px;
    background-color: rgba(0, 166, 195, 0.05);

    @include media-breakpoint-down(md) {
        padding-block: 20px 239px;
        gap: 20px;
    }

    &__wrapper {
        hr {
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    &__header {
        position: relative;
        width: $container-width--wide;
        max-width: $container-width--default;
        margin: 0 auto;
        padding: 30px 80px;
        opacity: 0.8;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }
    }

    &__heading {
        @include HeadingH4Desktop;

        @include media-breakpoint-down(md) {
            @include BodyText;
        }
    }

    &__top {
        gap: 30px;
        padding-inline: 120px;
        width: $container-width--wide;
        max-width: $container-width--default;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding-inline: 20px;
            align-items: flex-start;
            gap: 20px;
        }
    }

    &__middle,
    &__bottom {
        gap: 30px;
        padding-inline: 120px;
        width: $container-width--wide;
        max-width: $container-width--default;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding-inline: 20px;
            gap: 20px;
        }
    }

    &__bottom {
        @include media-breakpoint-down(md) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-block: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            background-color: $bg--white;
            gap: 20px;
        }
    }

    &__status {
        display: none;
        gap: 5px;

        @include BodyTextSm;

        &--active {
            display: flex;
        }

        &--verified {
            color: $text--green;
        }

        &--pending {
            color: $text--light-blue;
        }

        &--unverifed {
            color: $text--red;
        }
    }

    &__button {
        min-width: 320px;

        @include media-breakpoint-down(sm) {
            min-width: auto;
            width: 100%;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &-placeholder {
            object-fit: contain;
            padding: 16px;
        }

        &-container {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            height: 60px;
            width: 60px;
            min-height: 60px;
            min-width: 60px;
            background-color: $bg--white;
        }
    }

    &__user {
        gap: 10px;
    }

    &__card {
        width: 100%;
        max-width: 600px;
        padding: 11px 10px;
        background-color: $bg--white;
        border-radius: 5px;
        gap: 20px;
        border: 1px solid rgba(0, 0, 0, 0.1);

        &-left {
            flex: 1;
        }

        &-right {
            max-width: 200px;
            max-height: 126px;
            border-radius: 5px;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                max-height: 78px;
                max-width: 123px;
                align-items: flex-start;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__info {
        gap: 5px;

        &-title {
            @include HeadingH4Mobile;
        }

        &-text {
            color: rgba(0, 0, 0, 0.5);

            @include BodyText;
        }
    }

    &__location {
        text-align: center;
        margin-block: 5px 10px;

        @include BodyText;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }
    }

    &__pill {
        padding: 2px 10px;
        border-radius: 15px;
        border: 1px solid;

        @include BodyTextSm;

        @include media-breakpoint-down(md) {
            border-radius: 10px;

            @include BodyTextTiny;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
            width: 100%;
        }

        &-gold {
            border-color: $gold;
            color: $text--gold;
        }

        &-royal {
            border-color: $dark-blue;
            color: $text--dark-blue;
        }

        &-sky {
            border-color: $light-blue;
            color: $text--light-blue;
        }
    }

    &__row {
        width: 100%;
        max-width: 600px;
        gap: 10px;

        @include media-breakpoint-down(md) {
            max-width: none;
            justify-content: space-between;
        }

        &-title {
            @include BodyText;

            @include media-breakpoint-down(md) {
                @include BodyTextSm;
            }
        }

        &-text {
            @include BodyText;
        }

        &-value {
            &--right {
                text-align: right;
            }

            .found-recipient__input {
                width: 200px;
    
                @include media-breakpoint-down(sm) {
                    width: 176px;
                }
            }
        }
    }

    &__dropdown {
        width: 200px;
    }
}

/*=====  End of Found Recipient Component  ======*/
