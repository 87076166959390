/*=============================================
=            Payment Confirmed Component            =
=============================================*/

.pay-confirmed {
  gap: 30px;
  padding-block: 60px;
  max-width: 600px;
  margin: auto;

  @include media-breakpoint-down(md) {
    padding-block: 40px 142px; 
    gap: 20px;
  }

  &__bank {
    &-left,
    &-right {
      gap: 20px;
      font-size: $font-size--xxs;
    }

    &-left {
      opacity: 0.8;
      @include BodyTextSm;
    }

    &-right {
      font-weight: $font-weight--bold;
    }

    &-wrapper {
      background-color: $bg--dark-blue;
      color: $text--white;
      padding: 18px 15px;
      border-radius: 12px;
      gap: 20px;
      width: 100%;
    }

    &-description {
      text-align: center;
      opacity: 0.8;

      @include BodyText;
    }
  }

  &__wrapper {
    hr {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__header {
    position: relative;
    width: $container-width--wide;
    max-width: $container-width--default;
    margin: 0 auto;
    padding: 30px 80px;
    opacity: 0.8;
  
    @include media-breakpoint-down(md) {
      padding: 30px 20px;
    }
  }

  &__heading {
    @include HeadingH4Desktop;

    @include media-breakpoint-down(md) {
      @include BodyText;
    }
  }

  &__title {
    @include HeadingH4Desktop;

    @include media-breakpoint-down(md) {
      @include HeadingH4Mobile;
    }
  }

  &__amount {
    @include HeadingH1Mobile;
  }

  &__icon {
    border: 1px solid $white;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    color: $text--white;

    &-container {
      height: 84px;
      width: 84px;
      border-radius: 50%;
      
      &--green {
        background-color: $bg--green;
      }

      &--red {
        background-color: $bg--red;
      }
    }

    .zmdi {
      font-size: 28px;
    }
  }

  &__top {
    gap: 30px;
    padding-inline: 120px;
    width: $container-width--wide;
    max-width: $container-width--default;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      padding-inline: 20px;
      gap: 20px;
    }
  }

  &__middle, &__bottom {
    gap: 30px;
    padding-inline: 120px;
    width: $container-width--wide;
    max-width: $container-width--default;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      padding-inline: 20px;
      gap: 20px;
    }
  }

  &__middle {
    .button {
      margin-inline-start: 5px;

      @include BodyText;
    }
  }

  &__bottom {
    @include media-breakpoint-down(md) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-block: 20px 30px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background-color: $bg--white;
      gap: 20px;
    }
  }

  &__button {
    min-width: 320px;

    @include media-breakpoint-down(sm) {
      min-width: auto;
      width: 100%;
    }
  }

  &__user {
    width: 100%;
    background-color: rgba(0, 166, 195, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    gap: 10px;
    padding: 15px;

    &-right {
      gap: 5px;
    }

    &-title {
      @include HeadingH4Mobile;
    }

    &-text {
      color: rgba(0, 0, 0, 0.5);

      @include BodyText;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-placeholder {
      object-fit: contain;
      padding: 16px;
    }

    &-container {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      height: 60px;
      width: 60px;
      min-height: 60px;
      min-width: 60px;
      background-color: $bg--white;
    }
  }

  &__pill {
    width: fit-content;
    padding: 2px 10px;
    border-radius: 10px;
    border: 1px solid;

    @include BodyTextTiny;

    &-gold {
      border-color: $gold;
      color: $text--gold;
    } 

    &-royal {
      border-color: $dark-blue;
      color: $text--dark-blue;
    } 

    &-sky {
      border-color: $light-blue;
      color: $text--light-blue;
    } 
  }

  &__row {
    width: 100%;
    max-width: 600px;

    @include media-breakpoint-down(md) {
      max-width: none;
      justify-content: space-between;
    }

    &-title {
      text-align: center;

      @include BodyText;
    }

    &-caption {
      text-align: center;
      color: rgba(0, 0, 0, 0.5);

      @include BodyText;

      @include media-breakpoint-down(md) {
        @include BodyTextSm;
      }
    }
  }
}

/*=====  End of Payment Confirmed Component  ======*/