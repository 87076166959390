/*=============================================
=            Dashboard Contact Card            =
=============================================*/

.dashboard-contact {
    width: 100%;
    background-color: $bg--white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;

    @include transition;

    &:hover {
        border-color: $black;
    }

    &--hidden {
        display: none;
    }

    &__left {
        gap: 10px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }

    &__info {
        gap: 5px;

        &-title,
        &-text {
            @include BodyText;
        }

        &-text {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    &__status {
        gap: 5px;

        @include BodyTextTiny;

        .zmdi {
            font-size: 11px;
        }

        &-verified {
            color: $text--green;
        }

        &-pending {
            color: $text--light-blue;
        }

        &-unverified {
            color: $text--red;
        }
    }

    &__card {
        position: relative;
        justify-content: space-between;
        padding-block: 10px;
        padding-inline: 10px 15px;
        border-radius: 10px;
        width: 100%;
        gap: 10px;

        .dashboard-contact__pill {
            margin-inline-end: 20px;
            border-radius: 15px;

            @include BodyTextSm;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;

        &-placeholder {
            object-fit: contain;
            padding: 16px;
        }

        &-container {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            height: 50px;
            width: 50px;
            min-height: 50px;
            min-width: 50px;
            background-color: $bg--white;
        }
    }

    &__pill {
        padding: 2px 10px;
        border-radius: 10px;
        border: 1px solid;

        @include BodyTextTiny;

        &-gold {
            border-color: $gold;
            color: $text--gold;
        }

        &-royal-blue {
            border-color: $dark-blue;
            color: $text--dark-blue;
        }

        &-sky-blue {
            border-color: $light-blue;
            color: $text--light-blue;
        }
    }
}

/*=====  End of Dashboard Contact Card  ======*/
