.change-currency {
    width: 540px;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-block: 40px;
    padding-inline: 40px 80px;
    margin-inline-start: auto;
    background-color: $bg--white;
    gap: 30px;
    transform: translateX(540px);
    overflow-y: auto;

    @include transitionSlow;

    @include media-breakpoint-down(md) {
        gap: 20px;
        padding: 20px 0;
        height: auto;
        width: 100%;
        margin-inline-start: 0;
        margin-block-start: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        transform: translateX(0) translateY(100%);
    }

    &__item {
        width: 100%;
        gap: 10px;
    }

    .link {
        width: 100%;

        @include media-breakpoint-down(md) {
            width: calc(100% - 40px);
            margin: 0 auto;
        }
    }

    &__overlay {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 9999999999;
        cursor: default;
        visibility: hidden;
        opacity: 0;
        overflow-y: auto;

        @include transitionSlow;

        @include media-breakpoint-down(xl) {
            height: calc(100vh - $navbar-height--mobile);
        }

        &--active {
            visibility: visible;
            opacity: 1;
            animation: backgroundFade 0.2s ease-in-out forwards;

            .change-currency {
                transform: translateX(0px);

                @include media-breakpoint-down(md) {
                    transform: translateY(0px);
                }
            }
        }

        @keyframes backgroundFade {
            0% {
                background-color: rgba(0, 0, 0, 0);
            }
            100% {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__inner {
        overflow: hidden;
        width: 100%;
        gap: 30px;

        @include media-breakpoint-up(md) {
            min-height: 770px;
        }
    }

    &__header {
        width: 100%;
        padding-block-end: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(md) {
            width: calc(100% - 40px);
            margin-inline: auto;
            padding-block-end: 20px;
        }
    }

    &__title {
        @include HeadingH4Desktop;

        @include media-breakpoint-down(md) {
            @include BodyTextSm;
        }
    }

    &__close {
        height: 40px;
        width: 40px;

        @include media-breakpoint-down(md) {
            height: 20px;
            width: 20px;
            background-color: transparent;
            color: $text--dark-blue;

            &:hover,
            &:focus {
                background-color: transparent;
                color: $text--black;
            }
        }
    }
}