.contact-form {
  display: flex;
  gap: 120px;
  position: relative;

  @include media-breakpoint-down(xl) {
    gap: 60px;
  }

  &__left,
  &__right {
    width: $container-width--wide;
  }

  &__left {
    flex: 0.668;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px 0 60px 120px;
    margin-left: calc((100vw - $container-width--default) / 2);
    max-width: calc(480px + 120px);
    width: calc(100% + 120px);

    @include media-breakpoint-up(wide) {
      max-width: none;
      flex: 1;
    }

    @include media-breakpoint-down(desktop) {
      margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
      flex: 1;
      padding-right: 120px;
      margin: auto;
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 60px 30px;
    }

    .umbraco-forms-form {
      padding: 0;
    }

    .umbraco-forms-page {
      .umbraco-forms-caption {
        @include HeadingH2Mobile;
      }
    }

    .umbraco-forms-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .umbraco-forms-indicator {
      color: $text--red;
    }

    .umbraco-forms-field {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 0 !important;

      &.singlechoice {
        padding: 5px 0 0 0;

        label {
          color: $text--black;
        }
      }

      &.checkbox {
        .umbraco-forms-field-wrapper {
          flex-direction: row !important;
          align-items: center;
          gap: 10px;
        }
      }

      &.dataconsent {
        .umbraco-forms-field-wrapper {
          flex-direction: row !important;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          min-height: 30px;
        }
      }
    }

    .umbraco-forms-field-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column !important;
      align-items: flex-start;
    }

    .umbraco-forms-field-wrapper span.field-validation-error {
      color: $text--red !important;
      padding: 0 !important;
      margin-top: 5px;

      @include BodyTextSm;
    }

    .umbraco-forms-container {
      label {
        @include Label;
      }

      legend {
        @include Label;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      select {
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        width: $container-width--wide;
        padding: 10px 20px;
        background-color: $bg--white;
        max-width: none !important;
        margin: 0 !important;

        @include Input;

        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .radiobuttonlist {
        display: grid;
        grid-template-columns: 30px 1fr;
        gap: 10px;

        br {
          display: none;
        }

        label {
          display: inline-block;
          margin-top: 6px;
          color: $text--black;
        }
      }

      select {
        -webkit-appearance: none;
        background-image: url("/assets/img/img/caret-down-black-icon.svg");
        background-position-x: calc($container-width--wide - 15px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        background-size: 8px;
        z-index: $above;
        color: $text--black;
        max-width: none !important;
        margin: 0 !important;
      }

      input[type="text"].datepickerfield {
        -webkit-appearance: none;
        background-image: url("/assets/img/img/date-icon.svg");
        background-position-x: calc($container-width--wide - 15px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        background-size: 8px;
        z-index: $above;
        color: $text--black !important;
        max-width: none !important;
        margin: 0 !important;
      }

      option {
        color: rgba(0, 0, 0, 0.5);
      }

      textarea {
        resize: none;
        height: auto !important;
      }

      p {
        @include BodyTextSm;
      }

      a {
        color: $text--dark-blue;
      }

      .contact-form__content {
        p {
          @include BodyText;
        }
      }

      input[type="checkbox"],
      input[type="radio"] {
        padding: 10px;
        height: 28px;
        width: 28px;
        min-height: 28px;
        min-width: 28px;
        margin-right: 10px;
        cursor: pointer;
        margin: 0 !important;
        color: $text--white;
        position: relative;
        -webkit-appearance: none;

        &:before {
          position: relative;
          display: block;
          width: 11px;
          height: 11px;
          content: "";
          background: $bg--white;
          overflow: hidden;
        }

        &:after {
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          height: 28px;
          width: 28px;
          content: "";
          background-color: $bg--white;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          background-position: 10% 10%;
          background-repeat: no-repeat;

          @include transition;
        }

        &:checked:after {
          background-image: url("/assets/img/img/white-tick.svg");
          background-color: $bg--dark-blue;
          border-color: $bg--dark-blue;
        }
      }

      input[type="radio"] {
        padding: 0;
        -webkit-appearance: none;
        height: 30px !important;
        margin: auto !important;
      }
    }

    .umbraco-forms-navigation {
      padding: 0 !important;

      .col-md-12 {
        width: fit-content;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url("/assets/img/img/long-arrow-right-white.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          height: 20px;
          width: 12px;
          pointer-events: none;
        }
      }

      input[type="submit"] {
        padding: 10px 37px 10px 15px;
        gap: 10px;
        min-width: max-content;
        border-radius: 20px;
        background-color: $bg--dark-blue;
        color: $text--white;
        cursor: pointer;
        margin: 0 !important;

        @include transition;
        @include Button;

        &:hover,
        &:focus {
          color: $text--white;
          background-color: $bg--black;
        }
      }
    }

    .umbraco-forms-fieldset {
      padding: 0;
      margin-bottom: 20px !important;
    }

    .umbraco-forms-fieldset {
      .row-fluid {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;

        .umbraco-forms-container {
          width: 100%;
        }
      }
    }
  }

  &__right {
    flex: 1;
    position: relative;
    background-color: $bg--light-blue;
    min-height: 947px;
    transform-style: preserve-3d;
    perspective: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__title {
    h2 {
      @include HeadingH2Mobile;
    }
  }

  &__content {
    @include BodyText;
  }

  &__pattern {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    object-fit: cover;
    translate: 0 0 50px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    height: $container-height--wide;
    width: $container-width--wide;
    z-index: 1;
    perspective: 10px;
    object-fit: cover;
    translate: 0 0 50px;

    @include transition;
  }
}
