/*=============================================
=           Start of Organisation Director Authentication            =
=============================================*/

.org-auth {
    display: flex;
    gap: 80px;
    position: relative;

    @include media-breakpoint-down(xl) {
        gap: 60px;
    }

    &__title {
        @include HeadingH2Desktop;

        @include media-breakpoint-down(md) {
            @include HeadingH1Mobile;
        }
    }

    &__content {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__left {
        flex: 0.78;
        gap: 30px;
        margin-block: 80px 0;
        padding-block-end: 80px;
        padding-inline: 80px 36px;
        margin-left: calc((100vw - $container-width--default) / 2);
        max-width: calc(560px + 80px);
        width: calc(100% + 80px);
        max-width: 640px;
        max-height: 820px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.5);
        }

        @include media-breakpoint-up(wide) {
            flex: 1;
        }

        @include media-breakpoint-down(desktop) {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
            padding-right: 80px;
            max-width: none;
            width: 100%;
            max-height: none;
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
            margin: 0;
            gap: 20px;
        }
    }

    &__right {
        flex: 1;
        position: relative;
        background-color: $bg--light-blue;
        min-height: 820px;
        transform-style: preserve-3d;
        perspective: 1000px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__top,
    &__middle,
    &__bottom {
        gap: 30px;
        padding-block-end: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(md) {
            gap: 20px;
            border: none;
            padding-block-end: 0;
        }
    }

    &__bottom {
        border: none;
        padding-block-end: 0;
    }

    &__cards {
        gap: 10px;
    }

    &__card {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px;
        gap: 10px;
        width: 100%;
        cursor: pointer;

        @include transition;

        &:hover,
        &:focus {
            border-color: rgba(0, 0, 0, 0.8);

            .org-auth__card-icon {
                background-color: $bg--black;
            }
        }

        &-title {
            @include HeadingH5Mobile;
            text-align: left;
        }

        &-icon {
            background-color: $bg--dark-blue;
            height: 40px;
            width: 40px;
            color: $text--white;
            border-radius: 50%;

            @include transition;

            .zmdi {
                font-size: 18px;
            }
        }

        &-left {
            width: 100%;
            gap: 10px;
        }
    }

    &__progress {
        gap: 20px;

        &-text {
            min-width: fit-content;
            color: rgba(0, 0, 0, 0.3);

            @include BodyTextSm;

            &--active {
                color: $text--light-blue;
            }
        }

        &-bar {
            width: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            height: 6px;
            border-radius: 3px;
            background: linear-gradient(to right, $bg--light-blue var(--progress), rgba(0, 0, 0, 0.1) var(--progress));
        }
    }

    &__caption {
        color: rgba(0, 0, 0, 0.5);

        @include BodyTextSm;

        @include media-breakpoint-down(md) {
            @include BodyTextTiny;
        }
    }

    &__content {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;
    }

    &__pattern {
        position: absolute;
        top: 0;
        left: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        object-fit: cover;
        translate: 0 0 50px;
    }

    &__link {
        margin: 10px 0;

        &-top {
            align-self: end;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
}

/*=====  End of Organisation Director Authentication  ======*/
