$font-family--base: 'Poppins', sans-serif;

// font sizes
$font-size--xxxl: 50px;
$font-size--xxl: 40px;
$font-size--xl: 30px;
$font-size--sxl: 26px;
$font-size--lg: 25px;
$font-size--slg: 23px;
$font-size--mdlg: 22px;
$font-size--md: 20px;
$font-size--smd: 16px;
$font-size--sm: 15px;
$font-size--xs: 14px;
$font-size--xxs: 13px;
$font-size--xxxs: 10px;

// font line heights
$line-height--xl: 150%;
$line-height--lg: 140%;
$line-height--md: 130%;
$line-height--smd: 120%;
$line-height--sm: 90%;

// font weights
$font-weight--bold: 700;
$font-weight--medium: 500;
$font-weight--regular: 400;

// font styles
@mixin HeadingH1Desktop {
  font-size: $font-size--xxxl;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
}

@mixin HeadingH1Mobile {
  font-size: $font-size--xl;
  line-height: $line-height--md;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: -0.6px;
}

@mixin HeadingH2Desktop {
  font-size: $font-size--xxl;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
}

@mixin HeadingH2Mobile {
  font-size: $font-size--sxl;
  line-height: $line-height--md;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: -0.52px;
}

@mixin HeadingH3Desktop {
  font-size: $font-size--xl;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
}

@mixin HeadingH3Mobile {
  font-size: $font-size--slg;
  line-height: $line-height--md;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: -0.23px;
}

@mixin HeadingH4Desktop {
  font-size: $font-size--lg;
  line-height: $line-height--lg;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: -0.5px;
}

@mixin HeadingH4Mobile {
  font-size: $font-size--md;
  line-height: $line-height--md;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
}

@mixin HeadingH5Desktop {
  font-size: $font-size--md;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: -0.4px;
}

@mixin HeadingH5Mobile {
  font-size: $font-size--smd;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
}

@mixin HeadingH6Desktop {
  font-size: $font-size--md;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: -0.4px;
}

@mixin HeadingH6Mobile {
  font-size: $font-size--smd;
  line-height: $line-height--smd;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
}

@mixin BodyText {
  font-size: $font-size--sm;
  line-height: $line-height--xl;
  font-weight: $font-weight--regular;
  font-family: $font-family--base;
  letter-spacing: -0.15px;
}

@mixin BodyTextBold {
  font-size: $font-size--sm;
  line-height: $line-height--xl;
  font-weight: $font-weight--bold;
  font-family: $font-family--base;
  letter-spacing: -0.15px;
}

@mixin BodyTextSm {
  font-size: $font-size--xxs;
  line-height: $line-height--xl;
  font-weight: $font-weight--regular;
  font-family: $font-family--base;
}

@mixin BodyTextTiny {
  font-size: $font-size--xxxs;
  line-height: $line-height--xl;
  font-weight: $font-weight--regular;
  font-family: $font-family--base;
}

@mixin Button {
  font-size: $font-size--sm;
  line-height: $line-height--md;
  font-weight: $font-weight--bold;
  font-family: $font-family--base;
}

@mixin Input {
  font-size: $font-size--sm;
  line-height: $line-height--md;
  font-weight: $font-weight--regular;
  font-family: $font-family--base;
  letter-spacing: 0.15px;
}

@mixin Label {
  font-size: $font-size--xxs;
  line-height: $line-height--xl;
  font-weight: $font-weight--medium;
  font-family: $font-family--base;
  letter-spacing: 0.13px;
}