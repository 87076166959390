/*=============================================
=            Individual Profile            =
=============================================*/

.individual-profile {
    display: flex;
    gap: 80px;
    position: relative;

    @include media-breakpoint-down(xl) {
        gap: 60px;
    }

    &__title {
        @include HeadingH2Desktop;

        @include media-breakpoint-down(md) {
            @include HeadingH1Mobile;
        }
    }

    &__content {
        color: rgba(0, 0, 0, 0.8);

        @include BodyText;
    }

    &__left,
    &__right {
        width: $container-width--wide;
    }

    &__left {
        flex: 0.78;
        gap: 20px;
        padding: 40px 0 80px 80px;
        margin-left: calc((100vw - $container-width--default) / 2);
        max-width: calc(560px + 80px);
        width: calc(100% + 80px);
        max-width: 640px;

        @include media-breakpoint-up(wide) {
            flex: 1;
        }

        @include media-breakpoint-down(desktop) {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            flex: 1;
            padding-right: 80px;
            margin: auto;
            max-width: none;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
        }
    }

    &__right {
        flex: 1;
        position: relative;
        background-color: $bg--light-blue;
        min-height: 820px;
        transform-style: preserve-3d;
        perspective: 1000px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__form,
    &__inputs,
    &__bottom {
        width: $container-width--wide;
        gap: 20px;
    }

    &__form {
        input[type='text'],
        input[type='password'],
        select {
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            outline-color: $black;
            width: $container-width--wide;
            padding: 10px 20px;
            background-color: $bg--white;
            height: 40px;

            @include Input;

            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }

        select {
            -webkit-appearance: none;
            background-image: url('/assets/img/img/caret-down-black-icon.svg');
            background-position-x: calc($container-width--wide - 15px);
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: 8px;
            z-index: $above;
            color: rgba(0, 0, 0, 0.5);
            height: auto;
            cursor: pointer;
        }

        .individual-profile__country--active {
            color: $text--black;
        }

        option {
            color: $text--black;
        }

        option:first-child {
            color: rgba(0, 0, 0, 0.5);
        }

        input[type='date'] {
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            padding: 10px 20px;
            padding-inline-end: 15px;
            height: 40px;
            cursor: default;
            width: 100%;

            @include Input;
        }

        input[type='date']::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
        }
    }

    &__dob {
        &-container {
            position: relative;
            width: 100%;
            overflow: hidden;
        }

        &-icon {
            height: 16px;
            width: 20px;
            object-fit: contain;

            &-container {
                position: absolute;
                top: 50%;
                right: 3px;
                transform: translateY(-50%);
                height: 30px;
                width: 50px;
                background-color: $bg--white;
                border-radius: 5px;
                pointer-events: none;
            }
        }

        &-overlay {
            position: absolute;
            display: flex;
            align-items: center;
            top: 50%;
            left: 3px;
            transform: translateY(-50%);
            padding-inline-start: 20px;
            background-color: $bg--white;
            color: rgba(0, 0, 0, 0.5);
            height: 30px;
            width: calc(100% - 50px);
            pointer-events: none;

            &--hidden {
                display: none;
            }
        }
    }

    &__optional {
        color: rgba(0, 56, 101, 0.8);

        @include BodyTextSm;
    }

    &__button {
        width: 100%;
    }

    &__field {
        gap: 5px;
    }

    &__label {
        @include Label;

        &-required {
            color: $text--red;
        }
    }

    &__validation {
        color: $text--red;
        display: none;

        @include BodyTextSm;

        &--active {
            display: block;
        }
    }

    &__bottom {
        padding-block-start: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__caption {
        color: rgba(0, 0, 0, 0.5);

        @include BodyText;
    }

    &__pattern {
        position: absolute;
        top: 0;
        left: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        object-fit: cover;
        translate: 0 0 50px;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        height: $container-height--wide;
        width: $container-width--wide;
        z-index: 1;
        perspective: 10px;
        object-fit: cover;
        translate: 0 0 50px;

        @include transition;
    }

    &-button {
        padding: 10px;
        background-color: $bg--dark-blue;
        color: $text--white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        width: fit-content;
        border: 1px solid transparent;
        width: 38px;
        @include transition;

        &:hover {
            background-color: $bg--white;
            color: $dark-blue;
            border: 1px solid $dark-blue;
        }
    }
}

/*=====  End of Individual Profile  ======*/
